/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import {
	TableCell,
	TableRow,
	Typography,
	Button,
	Stack,
	Checkbox,
	Avatar,
	Chip,
	Tooltip,
} from '@mui/material';
import { formatDate, formatDateString } from '@/utils/constants';

export const PolicyRow = ({ policy, handleCheckboxChange, hideCheckbox }) => {
	
	const showDisabled = policy?.associated || policy?.linkedToCurrentDeal || (policy?.policyTransactions === null && (policy?.policyTransactions ?? []).length > 0);



	return (
		<Tooltip
			title={
				showDisabled
					? policy?.linkedToCurrentDeal
						? 'This policy transaction has already been associated with this deal'
						: 'This policy transaction has already been associated with an existing deal'
					: ''
			}
			placement="top"
		>
			<TableRow
				key={`table-row-${policy.Id}`}
				sx={{
					...(showDisabled && { backgroundColor: '#E0E0E0' }),
				}}
			>
				{!hideCheckbox && (
					<TableCell size='small' padding='checkbox'>
						{showDisabled ? (
							<Checkbox
								checked={policy?.selected || policy?.linkedToCurrentDeal}
								onChange={(event) => handleCheckboxChange(event, policy.Id)}
								color='primary'
								disabled
								inputProps={{
									'aria-label': 'select all desserts',
								}}
							/>
						) : (
							<Checkbox
								checked={policy?.selected}
								onChange={(event) => handleCheckboxChange(event, policy.Id)}
								color='primary'
								inputProps={{
									'aria-label': 'select all desserts',
								}}
							/>
						)}
					</TableCell>
				)}
				<TableCell width={'15%'} sx={{ whiteSpace: 'nowrap' }}>
					{policy.PolicyNumber}
				</TableCell>
				<TableCell width={'20%'} sx={{ whiteSpace: 'nowrap' }}>
					<Stack direction='column' spacing={1} width='100%' display={'flex'}>
						<Typography variant='subtitle2'>
						From: {formatDateString(policy?.FromDate)}</Typography>
						<Typography variant='subtitle2'>
						To: {formatDateString(policy?.ToDate)}
						</Typography>
						{policy?.TransactionTypeName === 'Endorsement' && (
							<Typography variant='subtitle2'>
						Effective: {formatDateString(policy?.EffectiveDate)}
							</Typography>
						)}
					</Stack>
				
				</TableCell>
				<TableCell>{policy.Description ?? policy.BriefDescription}</TableCell>
				<TableCell>{policy.InsurerName}</TableCell>
			</TableRow>
		</Tooltip>
	);
};
