import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	FormLabel,
	Box,
	Typography,
	Stack,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { engagementsApi, useCreateNoteMutation } from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { ref, uploadBytes } from 'firebase/storage';
import { useSharepoint } from '@/hooks/useSharepoint';
import { firestore, storage } from '@/utils/firebase';
import {
	formatDate,
	attachmentsFolderPath,
	attachmentsClientFolderPath,
	folderStructure,
	replaceFolderName,
} from '@/utils/constants';
import { jsPDF } from 'jspdf';
import FileUpload from '@/components/FileUpload';
import { dealsApi } from '@/features/deals/dealsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { factFindApi, useCreateDealProposalMutation } from '@/features/factFind/factFindApi';
import quoteJson from '@/assets/json/quote.json';
import riskInfoJson from '@/assets/json/riskInfo.json';

const validationSchema = Yup.object().shape({
	documents: Yup.array().min(1, 'Please upload at least one document'),
});

export const AddProposalDocumentDialog = ({ documentType }) => {
	const isQuote = documentType.toUpperCase() === 'QUOTE';
	const lowercaseType = documentType.toLowerCase();

	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);

	const open = useSelector((state) => state.dialog.open[lowercaseType]);

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	// const [createNote, { isLoading }] = useCreateNoteMutation();
	const { deal, client, contact, objectType, dealRow, sharepoint } = determineContext();

	const textColour = '#7B83FD';

	const validSharepoint = !deal && !client ? true : sharepoint?.isValid;

	const sharepointSite =
		!deal && !client ? userDetails?.sharepointSite : sharepoint?.sharepointSite;
	const clientSiteSuffix =
		!deal && !client ? userDetails?.clientSiteSuffix : sharepoint?.clientSiteSuffix;
	const clientFolder = !deal && !client ? userDetails?.clientFolder : sharepoint?.clientFolder;
	const clientSite = !deal && !client ? userDetails?.clientSite : sharepoint?.clientSite;

	const { addFileToSharepoint } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const [createDealProposal] = useCreateDealProposalMutation();
    
	const handleClose = () => {
		dispatch(closeDialog(lowercaseType));
	};


	const helpfulHint = isQuote ? 
		'Upload insurer quotes to quickly prepare them for your Summary and SOA!'
		:
		'Upload your fact find records and let Folio AI organise it, ready to get for quotes';

	const label = isQuote ? documentType : 'Risk Info';


	const formik = useFormik({
		initialValues: {
			documents: [],
			errorMessage: '',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			try {
				await uploadFilesToStorage();
				console.log(`🚀 ~ Successfully added ${documentType}`);
				resetForm();
				handleClose();
			} catch (err) {
				console.log(`🙅 ~ Error creating ${documentType}`, err);
				setFieldValue('errorMessage', `Error uploading ${label} document. Please try again.`);
			}
			setSubmitting(false);
		},
	});

	const createFirebaseDocument = async () => {
		const docId = await createDealProposal({
			dealId: deal.hs_object_id,
			type: lowercaseType,
			generating: true,
			jsonData: isQuote ? quoteJson : {},
		}).unwrap()
			.catch(err => console.log('Error creating Firebase document', err));
		console.log('DOC', docId);
		return docId;
	};

	const uploadFile = async (file) => {
		const filePath = `/${attachmentsFolderPath}/${deal.hs_object_id}/${file.name}`;
		const storageRef = ref(storage, filePath);

		const docId = await createFirebaseDocument();
        
		const metadata = {
			customMetadata: {
				'Deal ID': deal.hs_object_id.toString(),
				'AI Document Type': isQuote ? 'Quote' : 'WYTU',
				...(docId && { 'Firebase Document ID': docId })
			},
		};

		await uploadBytes(storageRef, file, metadata);
		
		if (validSharepoint) {
			await addFileToSharepoint(dealRow.dealFolderPath, file);
		}
	};

	const uploadFilesToStorage = async () => {
		const id = deal.hs_object_id;

		for (const file of formik.values.documents) {
			await uploadFile(file);
		}
		
		setTimeout(async () => {
			try {
				dispatch(factFindApi.util.invalidateTags([
					{type: 'DEAL_NOTE_SUMMARY', id }
				]));
				dispatch(triggerRefresh());
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 2000);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			PaperProps={{
				sx: {
					minWidth: { xs: '100%', sm: '70%', md: '50%' },
					padding: '1em',
				},
			}}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>{`Upload ${label}`}</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Box
						sx={{
							width: '100%',
							backgroundColor: '#D9DCFE',
							borderRadius: '4px',
							alignItems: 'center',
							border: '2px solid #7B83FD',
							padding: '1em',
							mb: '2em'
						}}
					>
						<Typography sx={{ color: textColour }}>
							<span style={{ fontWeight: 'bold', paddingRight: '0.5em' }}>
								{'Helpful tip: '}
							</span>
							{helpfulHint}
						</Typography>
					</Box>
					<Grid container spacing={2}>
						<Grid size={12}>
							<FormLabel>{`Upload ${label} document`}</FormLabel>
							<FileUpload
								files={formik.values.documents}
								setFiles={(files) => formik.setFieldValue('documents', files)}
								types={['pdf']}
								multiple={false}
								loading={formik.isSubmitting}
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <pre>{JSON.stringify(formik, undefined, 2)}</pre> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={(e) => {
							e.preventDefault();
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
