import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';
import { ContactTabView } from './ContactTabView';
import { ContactAboutCard } from './ContactAboutCard';

const ContactViewWrapper = () => {
	const navigate = useNavigate();

	// Navigate to the previous page (e.g. contacts)
	const location = window.location.pathname.split('/').slice(0, -1).join('/');

	return (
		<div className='contact-view-wrapper'>
			<Grid container>
				<Grid size={{ xs: 8, sm: 9 }} sx={{ p: 1 }}>
					<Stack>
						<Box display='flex' px='1em' sx={{ alignItems: 'center' }}>
							<Button
								variant='text'
								onClick={() => navigate(location)}
								sx={{ color: 'black', fontWeight: 'bold' }}
								startIcon={<KeyboardBackspaceOutlinedIcon />}
							>
								{'Prospects'}
							</Button>
						</Box>

						<ContactTabView />
					</Stack>
				</Grid>
				<Grid
					size={{ xs: 4, sm: 3 }}
					sx={{
						position: 'sticky',
						top: 0,
						alignSelf: 'flex-start',
					}}
				>
					<ContactAboutCard />
				</Grid>
			</Grid>
		</div>
	);
};
export default ContactViewWrapper;
