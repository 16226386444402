import React, { useEffect, useMemo } from 'react';
import { TasksTab } from '@/components/objectTabs/TasksTab';
import { determineContext } from '@/hooks/determineContext';
import ObjectViewFileUpload from '@/components/ObjectViewFileUpload';
import { CreateNoteButton } from '@/components/buttons/viewHeaderButtons/CreateNoteButton';
import { CreateTaskButton } from '@/components/buttons/viewHeaderButtons/CreateTaskButton';
import { ActivityTab } from '@/components/objectTabs/ActivityTab';
import { NotesTab } from '@/components/objectTabs/NotesTab';
import { AttachmentsTab } from '@/components/objectTabs/AttachmentsTab';
import { FormsTab } from '@/components/objectTabs/FormsTab';
import { PoliciesTab } from './DealViewTabs/PoliciesTab';
import { FactFindTab } from './DealViewTabs/FactFindTab';
import { TabView } from '@/components/layouts/TabView';
import { ClaimDetailsTab } from './DealViewTabs/ClaimDetailsTab';
import { useLocation } from 'react-router-dom';
import { paths } from 'app/routes';
import { GenerateRiskButton } from '@/components/buttons/AddRiskInfoButton';
import { RiskTab } from './DealViewTabs/RiskTab';
import { QuotesTab } from './DealViewTabs/QuotesTab';
import { AddQuoteButton } from '@/components/buttons/AddQuoteButton';
import { ScopeOfAdviceTab } from './DealViewTabs/ScopeOfAdviceTab';
import { features } from '@/utils/features';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { SendScopeOfAdviceButton } from '@/components/buttons/viewHeaderButtons/SendScopeOfAdviceButton';
import { getPipelineIcon } from '@/utils/constants';
import { EditPolicyTransactionButton } from '@/components/buttons/viewHeaderButtons/EditPolicyTransactionsButton';

export const DealTabView = () => {
	const location = useLocation();

	const { deal, objectType, client, pipeline } = determineContext();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'statementOfAdvice'});
	const showSOA = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
    
    const pipelineId = deal?.hs_pipeline ?? deal?.pipeline;

	const isDealOrClaims = objectType === 'deal' || objectType === 'ticket';
	const isClaims =
		location.pathname.startsWith(paths.claims) ||
		deal?.hs_pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;

	const tabs = useMemo(
		() => [
			{
				label: 'Scope Of Advice',
				value: 'scope',
				showComponent: objectType === 'deal',
				ContentComponent: ScopeOfAdviceTab,
				HeaderComponent: SendScopeOfAdviceButton
			},
			{
				label: 'Claim details',
				value: 'details',
				showComponent: isClaims,
				ContentComponent: ClaimDetailsTab,
			},
			{
				label: 'Activity',
				value: 'activity',
				showComponent: objectType === 'deal' || objectType === 'ticket',
				ContentComponent: ActivityTab,
			},
			{
				label: 'Notes',
				value: 'notes',
				showComponent: objectType === 'deal' || objectType === 'ticket',
				ContentComponent: NotesTab,
				HeaderComponent: CreateNoteButton,
			},
			{
				label: 'Tasks',
				value: 'tasks',
				showComponent: objectType === 'deal' || objectType === 'ticket',
				ContentComponent: TasksTab,
				HeaderComponent: CreateTaskButton,
			},
			{
				label: 'Attachments',
				value: 'attachments',
				showComponent: objectType === 'deal' || objectType === 'ticket',
				ContentComponent: AttachmentsTab,
				HeaderComponent: ObjectViewFileUpload,
			},
			{
				label: 'Forms',
				value: 'forms',
				showComponent: objectType === 'deal' || objectType === 'ticket',
				ContentComponent: FormsTab,
			},
			{
				label: 'Policies',
				value: 'policies',
				//Makes it so policy and fact find tab showup for endorsements endorsements objecttype is ticket using pipeline
				showComponent:
					objectType === 'deal' ||
					pipelineId === process.env.REACT_APP_PIPELINE_ENDORSEMENTS,
				ContentComponent: PoliciesTab,
				HeaderComponent: EditPolicyTransactionButton
			},
			...(showSOA
				? [
					{
						label: 'Risk Info',
						value: 'risk',
						showComponent: objectType === 'deal',
						ContentComponent: RiskTab,
						HeaderComponent: GenerateRiskButton,
					},
					{
						label: 'Quotes',
						value: 'quotes',
						showComponent: objectType === 'deal',
						ContentComponent: QuotesTab,
						HeaderComponent: AddQuoteButton,
					},
				]
				: [
					{
						label: 'Fact Find',
						value: 'factfind',
						showComponent: objectType === 'deal' || pipelineId === process.env.REACT_APP_PIPELINE_ENDORSEMENTS,
						ContentComponent: FactFindTab,
					},
				]),
		],
		[objectType, deal, isClaims]
	);

	const activeTabs = useMemo(() => tabs.filter((tab) => tab.showComponent), [tabs]);

	return <TabView tabs={activeTabs} title={deal?.dealname ?? deal?.subject} icon={getPipelineIcon(pipelineId, '55px')} />;
};
