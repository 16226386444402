import React from 'react';
import { Typography, Card, CardMedia, Stack, Link, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { theme } from '@/app/theme';
import { SignInButton } from '@/components/buttons/SignInButton';
import AIRyan from '@/assets/images/ai-ryan.png';

export default function LoginPage() {
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<Container>
			<Grid
				container
				spacing={2}
				style={{ height: '100vh', overflow: 'hidden', display: 'flex' }}
			>
				<Grid size={6} style={{ height: '100%' }}>
					<Typography sx={{ fontWeight: 'bold', marginBottom: 2 }} variant='h5'>
						Folio
					</Typography>
					<Card sx={{ width: '100%', height: '100%', borderRadius: 0 }}>
						<CardMedia
							component='img'
							height='100%'
							width='100%'
							src={AIRyan}
							alt='Support staff'
							sx={{ objectFit: 'cover' }}
						/>
					</Card>
				</Grid>
				<Grid size={6} container justifyContent='center' alignItems='center'>
					<Stack direction='column'>
						<Card sx={{ padding: 8 }}>
							<Typography sx={{ fontWeight: 'bold', marginBottom: 2 }} variant='h4'>
								Folio Broker Portal
							</Typography>
							<Typography sx={{ marginBottom: 4 }}>Log in below</Typography>
							<SignInButton />
						</Card>
						<Typography sx={{ ...minimalLabelTheme, marginTop: 1 }}>
							Need help? Email{' '}
							<span>
								<Link
									href={'mailto:support@folio.insure'}
									target='_blank'
									rel='noopener noreferrer'
								>
									support@folio.insure
								</Link>
							</span>{' '}
							for assistance.
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
}
