import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Tooltip, ListItemButton, ListItemText, ListItemIcon, useMediaQuery, useTheme } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';
import { LOGIN_TYPE } from '@/app/authConfig';
import { useMSALHelper } from '@/hooks/useMSALHelper';

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
	const { handleLogout } = useMSALHelper();

	const { isCollapsed } = useSelector((state) => state.sideNavSlice);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const collapsed = isCollapsed && !isMobile;

	return (
		<Tooltip title={collapsed ? 'Sign out' : ''} placement="right">
			<ListItemButton
				onClick={() => handleLogout()}
				sx={{ 
					borderRadius: '20px', 
					'&:hover': { 
						backgroundColor: 'rgba(0, 0, 0, 0.1)' 
					},
					...(collapsed && {
						paddingLeft: 0,
						paddingRight: 0,
						justifyContent: 'center',
					}),
				}}
			>
				<ListItemIcon 
					sx={{
						color: 'white',
						...(collapsed && {
							minWidth: '24px',
							display: 'flex',
							justifyContent: 'center',
						}),
					}}
				>
					<LogoutOutlinedIcon />
				</ListItemIcon>
				{!collapsed && <ListItemText
					primary="Sign out"
					sx={{ fontWeight: 'bold', marginLeft: '-17px', color: 'whitesmoke' }}
				/>}
			</ListItemButton>
		</Tooltip>
	);
};