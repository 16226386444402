import React from 'react';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Checkbox from '@mui/material/Checkbox';
import { useUpdateTaskMutation } from '@/features/engagements/engagementsApi';
import { PriorityText } from '@/components/PriorityText';
import parse from 'html-react-parser';
import { determineContext } from '@/hooks/determineContext';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { folioBlue } from '@/utils/constants';

export const TaskCard = ({ task }) => {
	const { deal, objectType, client, contact } = determineContext();
	const objectId = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)
		?.hs_object_id;

	const [updateTask, { isLoading }] = useUpdateTaskMutation();
	const dispatch = useDispatch();

	const completeTask = async () => {
		await updateTask({
			dealId: objectId,
			objectType,
			taskId: task.id,
			properties: { hs_task_status: task.completed ? 'NOT_STARTED' : 'COMPLETED' },
		}).unwrap();
	};


	const handleTaskOpen = () => {
		console.log('opening!');
		const engagement = {
			...task,
			client: client ?? null,  
		};
		dispatch(openDialog({ dialogName: 'task', data: { engagement} }));
	};


	return (
		<Stack width='100%' direction='row' alignItems='center' padding='0.5em 0'>
			{isLoading ? (
				<Box margin='1em'>
					<CircularProgress size='2em' />
				</Box>
			) : (
				<Checkbox
					sx={{ '& .MuiSvgIcon-root': { fontSize: '2.5em' } }}
					icon={
						<>
							<svg width={0} height={0}>
								<linearGradient
									id={`linearColors${task.id}-task-accordion-uncheck`}
									x1={0}
									y1={1}
									x2={1}
									y2={1}
								>
									<stop offset={0} stopColor='rgba(131,214,255,1)' />
									<stop offset={1} stopColor='rgba(229,132,255,1)' />
								</linearGradient>
							</svg>
							<CheckCircleOutlineRoundedIcon
								sx={{ fill: `url(#linearColors${task.id}-task-accordion-uncheck)` }}
							/>
						</>
					}
					checkedIcon={
						<>
							<svg width={0} height={0}>
								<linearGradient
									id={`linearColors${task.id}-task-accordion-check`}
									x1={0}
									y1={1}
									x2={1}
									y2={1}
								>
									<stop offset={0} stopColor='rgba(131,214,255,1)' />
									<stop offset={1} stopColor='rgba(229,132,255,1)' />
								</linearGradient>
							</svg>
							<CheckCircleRoundedIcon
								sx={{ fill: `url(#linearColors${task.id}-task-accordion-check)` }}
							/>
						</>
					}
					checked={task.completed}
					onChange={() => completeTask(task)}
				/>
			)}
			<Stack width='100%'>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Typography 
						sx={{ color: folioBlue, cursor: 'pointer' }} 
						onClick={handleTaskOpen}
					>
						{task.subject}
					</Typography>
					{task.priority !== 'LOW' && task.priority !== 'NONE' && (
						<PriorityText task={task} />
					)}
				</div>

				<Typography variant='avatar_subtitle' sx={{ margin: '0' }}>
					{parse(task.body ?? '')}
				</Typography>
			</Stack>
		</Stack>
	);
};
