/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import {
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { formatDate } from '@/utils/constants';

export const InsightClaimRow = ({ claim }) => {

	console.log('claim: ', claim);
	
	return (
		<TableRow>
			<TableCell>
				<Typography sx={{whiteSpace: 'nowrap'}}  size='small' color="text.primary">
					{claim.PolicyNumber}
				</Typography>
			</TableCell>
			<TableCell size='small' align="left">
				<Typography sx={{whiteSpace: 'nowrap'}} >{formatDate(claim.LossDate)}</Typography>
			</TableCell>
			<TableCell> 
				<Typography sx={{minWidth: 125}} color="text.primary">
					{claim.Description ?? claim.PolicyDescription}
				</Typography>
			</TableCell>
			<TableCell sx={{minWidth: 250}} align="left">
				<Typography>{claim.ClientTradingAs}</Typography>
			</TableCell>
		</TableRow>
	);
};
