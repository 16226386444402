import React, { useMemo } from 'react';
import {
	Card,
	Typography,
} from '@mui/material';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { formatDate } from '@/utils/constants';

export const ActivityCard = ({ activity }) => {

	const ownerQueryParams = { ownerId: activity.data.createdBy ?? activity.data.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const getFullName = () => {
		return owner ? `${owner.firstName} ${owner.lastName}` : 'Unknown';
	};

	const date = useMemo(() => new Date(activity.data.timestamp), [activity.data.timestamp]);
	const lastUpdated = useMemo(() => new Date(activity.data.lastUpdated), [activity.data.lastUpdated]);

	return (
		<Card mb='12px' pl='12px'>
			<div style={{ display: 'flex', marginTop: '12px', justifyContent: 'space-between' }}>
				<Typography variant='task_body' style={{padding: '10px'}}>
					<span style={{ fontWeight: 'bold' }}>{activity.data.type}</span> by{' '}
					<span>{getFullName()}</span>
					<span style={{ margin: '0 0.5em' }}>{' | '}</span>
					<span style={{ color: '#505AFC' }}> {formatDate(date, true)} </span>
				</Typography>
				
			</div>
			<div style={{padding: '10px'}}>
				<Typography variant='task_body'>{activity.data.bodyPreview}</Typography>
			</div>
			<div style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em' }}>
				<Typography variant='task_updated'>
					{`Last updated: ${formatDate(lastUpdated, true)}`}
				</Typography>
			</div>
		</Card>
	);
};
