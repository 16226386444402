/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { Link, Typography, Box, Skeleton, Stack, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const AssociatedClient = ({ hideHeader = false }) => {
	const { client, loading, contact } = determineContext();
	const isLoading = loading?.client;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects'});
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const isProspect = client?.clientRow?.isProspect;

	return (
		<Box>
			{!hideHeader && <Typography sx={minimalLabelTheme}>Associated Client</Typography>}
			{isLoading ? (
				<Skeleton width='100%' />
			) : (
				(() => {
					const data =
						Object.keys(client || {}).length > 0
							? client
							: Object.keys(contact || {}).length > 0
								? contact
								: null;

					if (data) {
						return (
							<Stack
								direction='row'
								spacing={1}
								sx={{
									...(!hideHeader && { justifyContent: 'space-between' }),
									alignItems: 'center',
								}}
							>
								{data?.name ? (
									<Link
										to={`/clients/${data.hs_object_id}`}
										component={RouterLink}
										sx={{ textDecorationLine: 'none', paddingTop: '3px' }}
									>
										{data.name ?? data.company}
									</Link>
								) : (
									<Typography variant='body1'>
										{data.name ?? data.company}
									</Typography>
								)}
								{isProspect && showProspects && (
									<Chip
										size='small'
										label='PROSPECT'
										variant='outlined'
										sx={{ color: 'darkgray' }}
									/>
								)}
							</Stack>
						);
					} else {
						return <Typography variant='body1'>No client found</Typography>;
					}
				})()
			)}
		</Box>
	);
};
