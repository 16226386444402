import React, { useMemo } from 'react';
import { Box, Button, Card, Divider } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { KanbanToggle } from '@/components/buttons/KanbanToggle';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { getCreateUrlFromPipeline } from '@/utils/constants';
import { KanbanContextProvider } from '@/context/kanbanContext';
import { KanbanContent } from './KanbanContent';
import { RefinementListSelect } from '@/components/selects/RefinementListSelect';
import { InstantSearch } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import FilterMenu from '@/components/table/FilterMenu';

export const KanbanView = ({ pipelineId }) => {
	const navigate = useNavigate();

	const { data: pipelineData, isLoading } = useGetPipelinePropertiesQuery(pipelineId, {
		skip: !pipelineId,
	});
	const pipelineName = pipelineData?.pipelines?.label;

	const isClaims = pipelineId == process.env.REACT_APP_PIPELINE_CLAIMS;

	const createPipelineName = useMemo(() => {
		if (pipelineId == process.env.REACT_APP_PIPELINE_NEW_BUSINESS) {
			return pipelineName;
		} else {
			return `New ${pipelineName.substring(0, pipelineName.length - 1)}`;
		}
	}, [pipelineName]);

	const handleNavigation = () => {
		const createUrl = getCreateUrlFromPipeline(pipelineId);
		if (createUrl) {
			navigate(createUrl);
		}
	};

	return (
		<Card>
			<InstantSearch indexName='deals' searchClient={dealClient}>
				<Grid container>
					<Grid size={'grow'} />
					<Grid size={3} container justifyContent={'flex-end'} mr={2} py={1}>
						<Button variant='contained' startIcon={<Add />} onClick={handleNavigation}>
							{createPipelineName}
						</Button>
					</Grid>
				</Grid>
				<Grid container sx={{ alignItems: 'flex-start' }} p={1} px={2} spacing={1}>
					<Grid size={{ xs: 12, sm: 5 }}>
						<InstantSearchFieldAdaptor />
					</Grid>
					<Grid size={{ xs: 0, sm: 'grow' }} />
					{isClaims && (
						<Grid>
							<FilterMenu
								title={pipelineName ?? ''}
								filters={[
									{ attribute: 'insurer', multiple: true, title: 'Insurer' },
									{ attribute: 'claimType', multiple: true, title: 'Claim Type' },
									// { attribute: 'dealStage', multiple: true, title: 'Stage', pipeline },
								]}
							/>
						</Grid>
					)}
				</Grid>
				<Box p={1} px={2}>
					<KanbanToggle />
				</Box>
				<Divider />
				<KanbanContextProvider pipelineId={pipelineId}>
					<KanbanContent />
				</KanbanContextProvider>
			</InstantSearch>
		</Card>
	);
};
