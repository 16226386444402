import React, { useCallback, useState } from 'react';
import { GridCellModes } from '@mui/x-data-grid';

/** useCellModes hook allows DataGrid cells to be edited with a single click */
const useCellModes = () => {
	const [cellModesModel, setCellModesModel] = useState({});

	const handleCellClick = useCallback((params, event) => {
		if (!params.editable) return;

		// Ignore portal
		if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
			return;
		}

		if (params.colDef.singleClickCell) {
			setCellModesModel((prevModel) => {
				return {
					// Revert the mode of the other cells from other rows
					...Object.keys(prevModel).reduce(
						(acc, id) => ({
							...acc,
							[id]: Object.keys(prevModel[id]).reduce(
								(acc2, field) => ({
									...acc2,
									[field]: { mode: GridCellModes.View },
								}),
								{}
							),
						}),
						{}
					),
					[params.id]: {
						// Revert the mode of other cells in the same row
						...Object.keys(prevModel[params.id] || {}).reduce(
							(acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
							{}
						),
						[params.field]: { mode: GridCellModes.Edit },
					},
				};
			});
		}
		
	}, []);

	const handleCellModesModelChange = useCallback((newModel) => {
		setCellModesModel(newModel);
	}, []);

	return {
		cellModesModel,
		handleCellClick,
		handleCellModesModelChange,
	};
};

export default useCellModes;
