import { Droppable } from '@hello-pangea/dnd';
import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Configure, Index } from 'react-instantsearch';
import { useSelector } from 'react-redux';
import KanbanContext from '@/context/kanbanContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { IS_DEV } from '@/utils/environment';
import { HitsKanbanColumn } from '@/components/table/HitsTableBody';
import { KanbanCard } from '../KanbanCard';
import { LoadingCard } from '../KanbanCard/LoadingCard';

export const KanbanColumn = ({ stage }) => {

	const [refreshKey, setRefreshKey] = useState(0);

	const { 
		pipelineId, 
		isClaims, 
		isEndorsement,
		refreshStatus,
	} = useContext(KanbanContext);

	const isDeal = !(isClaims || (IS_DEV && isEndorsement));

	const { 
		groupView,
		hubspotId: groupViewHubspotId
	} = useSelector((state) => state.userPrefs);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	
	const resetAndRefresh = () => {
		setRefreshKey(prev => prev + 1);
	};

	useEffect(() => {
		if (refreshStatus[stage.id]) {
			console.log('Triggering reset and refresh from KanbanColumn');
			setTimeout(async () => resetAndRefresh(), 8000);
		}
	}, [stage.id, refreshStatus[stage.id]]);

	const hubspotId = groupViewHubspotId ?? userDetails?.hubspotId;
	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		const ownerFilter = groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `ownerId := ${hubspotId}`;
		const schema = {
			filters: `dealStage := ${stage.id} && ${ownerFilter}`
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, groupView, hubspotId]);

	return <Stack width={'250px'}>
		<Box sx={{ 
			height: '72px', 
			bgcolor: '#ffffff',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}}>
			<Typography align="center" variant="subtitle1">
				{stage.label}
			</Typography>
		</Box>
		<Box>
			<Index indexName="deals" indexId={stage.id}>
				<Configure {...filterSchema} hitsPerPage={25} />
				<Droppable droppableId={stage.id}>
					{(droppableProvided, snapshot) => (
						<Box
							ref={droppableProvided.innerRef}
							{...droppableProvided.droppableProps}
							className={snapshot.isDraggingOver ? ' isDraggingOver' : ''}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								flexGrow: 1,
								width: '250px',
								minHeight: '100%',
								borderRadius: '3px',
								bgcolor: '#f5f8fa',
								padding: '5px',
								'&.isDraggingOver': {
									bgcolor: 'rgba(224, 224, 224, 1)',
								},
							}}
						>
							<HitsKanbanColumn
								key={`kanban-column-${stage.id}-${refreshKey}`}
								CardComponent={KanbanCard}
								LoadingComponent={LoadingCard}
								objectType={isDeal ? 'deal' : 'ticket'}
								includeTasks={true}
								stage={stage}
							/>
							{droppableProvided.placeholder}
						</Box>
					)}
				</Droppable>
			</Index>
		</Box>
	</Stack>;
};