/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { determineContext } from '@/hooks/determineContext';
import { FactFindForm } from '@/components/forms/FactFindForm';

export const RiskTab = () => {
	const { riskInfo } = determineContext();

	const latestRiskInfo = useMemo(() => riskInfo[0] ?? {}, [riskInfo]);

	return <FactFindForm title={'Risk Info'} factFindType={'risk'} latestDoc={latestRiskInfo} />;
};
