import React, { useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableFooter,
	TableRow,
    Box,
	Typography,
	Paper,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Add } from '@mui/icons-material';
import { stableSort, getComparator } from '@/components/table/functions';
import { TablePaginationActions } from '@/components/table/TablePaginationActions';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { rowLimits } from '@/components/table/data';
import { 
	changeRowsPerPage, 
	resetSearchFields, 
	setSearchText, 
	setSearchValue,
	requestSort,
	changePage,
	resetAllPages,
	onOrderChange,
	setOrderBy,
	setOrder,
	setRows,
} from '@/features/table/contactsTableSlice';
import SearchField from '@/components/SearchField';
import { useGetContactsQuery } from '@/features/contacts/contactsApi';
import { AddContactDialog } from '@/components/dialogs/AddContactDialog';
import { ContactRow } from './ContactRow';

export const contactHeadCells = [
	// { id: 'id', numeric: false, label: null, sortable: false },
	{ id: 'lastname', numeric: false, label: 'Name', align: 'left', sortable: false },
	// { id: 'contact', numeric: false, label: 'Contact', align: 'left' },
	{ id: 'email', numeric: false, label: 'Email', align: 'left' },
	{ id: 'phone', numeric: true, label: 'Phone', align: 'right' },
	{ id: 'createdAt', numeric: true, label: 'Created date', align: 'right' },
	// { id: 'id', numeric: false, label: '', sortable: false, align: 'center' },
];

export default function ContactsTable({ tab }) {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { groupView } = useSelector((state) => state.userPrefs);

	const filters = [
		{
			propertyName: 'lifecyclestage',
			value: tab,
		}
	];

	const {
		pageAfter,
		rowsPerPage,
		searchValue,
		searchText,
		orderBy,
		order,
		page,
		rows,
	} = useSelector((state) => state.contactsTable);

	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userDetails?.hubspotId;

	const contactsQuery = useGetContactsQuery(
		{ 
			hubspotId, 
			searchValue, 
			pageAfter, 
			limit: rowsPerPage, 
			orderBy, 
			order, 
			groupView,
			filters,
		},
		{ skip: !hubspotId }
	);

	useEffect(() => {
		dispatch(resetSearchFields());
		dispatch(setOrderBy('createdAt'));
		dispatch(setOrder('desc'));
	}, []);

	useEffect(() => {
		dispatch(onOrderChange());
	}, [orderBy, order]);

	const handleRequestSort = (event, property) => {
		dispatch(requestSort(property));
	};

	const handleChangePage = (event, newPage, lastPageAfter) => {
		dispatch(changePage({ newPage, lastPageAfter }));
	};


	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const handleReset = () => {
		dispatch(resetSearchFields());
	};

	const handleSearch = () => {
		if (searchText != searchValue) {
			dispatch(resetAllPages());
			dispatch(setRows([]));
			dispatch(setSearchValue(searchText));
		}
	};

	useEffect(() => {
		if (contactsQuery?.data) {
			console.log('here is the contact data before: ', contactsQuery?.data);
			const transformedData = (contactsQuery?.data?.data ?? []).map((contact) => {
				const properties = contact?.properties;
				return {
					firstname: properties?.firstname ?? 'Unkown',
					lastname: properties?.lastname ?? '',
					email: properties?.email || '-',
					phone: properties?.phone || '-',
					clientInformation: contact.companyInformation,
					createdAt: contact.createdAt,
					id: contact.id ?? contact.hs_object_id,
				};
			});
			dispatch(setRows(transformedData));
		}
	}, [contactsQuery?.data]);
  

	const loading = contactsQuery.isLoading || contactsQuery.isUninitialized;
	const fetching = contactsQuery.isFetching;
	const error = contactsQuery.isError;

	return (
		<TableContainer component={Paper}>
			<Grid container alignItems="center" sx={{alignItems: 'flex-end'}} p={1} px={2} spacing={1}>
				<Grid size={{ xs: 12, sm: 5 }}> 
					<SearchField
						value={searchText}
						disabled={loading}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleSearch();
							}
						}}
						onChange={(e) => dispatch(setSearchText(e.target.value))}
						onClick={handleSearch}
						onReset={handleReset}
					/>
				</Grid>
				<Grid size={{ xs: 0, sm: 'grow' }}></Grid>
			</Grid>
			<Table
				aria-labelledby="tableTitle"
				size="medium"
				aria-label="enhanced table"
				sx={{ minWidth: 700 }}
			>
				<FolioTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={contactHeadCells}
				/>
				<TableBody>
					{loading || fetching ? (
						<TableProgressIndicator rowCount={contactHeadCells.length} />
					) : error ? (
						<TableRow>
							<TableCell colSpan={contactHeadCells.length} align="center">
								<Typography variant="subtitle1">
                  There was an error. Please try again.
								</Typography>
							</TableCell>
						</TableRow>
					) : rows.length > 0 ? (
						stableSort(rows, getComparator(order, orderBy))
							.map((contact, index) => (
								<ContactRow
									key={`contact-row-index-${index}-${contact.id}`}
									contact={contact}
								/>
							))
					) : (
						<TableRow>
							<TableCell colSpan={contactHeadCells.length} align="center">
								<Typography variant="subtitle1">
									{`No ${tab === 'lead' ? 'prospect' : 'contact'}s found`}
								</Typography>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Box width="100%" justifyItems="end">
				<TablePagination
					rowsPerPageOptions={rowLimits}
					count={contactsQuery?.data?.total ?? 0}
					rowsPerPage={rowsPerPage}
					page={page}
					component={'div'}
					SelectProps={{
						inputProps: {
							'aria-label': 'Rows per page',
						},
						native: true,
					}}
					sx={{
						display: 'block',
						width: '100%'
					}}
					showLastButton
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Box>
			<AddContactDialog />
		</TableContainer>
	);
}
