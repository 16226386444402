import React from 'react';
import { TextField, FormHelperText, FormLabel, FormControl } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FIRST_NAME, LAST_NAME, EMAIL, PHONE } from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { NumericFormat } from 'react-number-format';

export const ContactFields = ({ formik, loading, existingContact }) => {
	const { getError, getErrorMessage } = useFormikHelper(formik);

	return (
		<>
			<Grid size={{ xs: 12, md: 6 }}>
				<FormLabel required>{'First Name'}</FormLabel>
				<TextField
					fullWidth={true}
					name={FIRST_NAME}
					{...formik.getFieldProps(FIRST_NAME)}
					error={getError(FIRST_NAME)}
					helperText={getErrorMessage(FIRST_NAME)}
					disabled={loading || existingContact?.firstname != null}
					required
				/>
			</Grid>
			<Grid size={{ xs: 12, md: 6 }}>
				<FormLabel required>{'Last Name'}</FormLabel>
				<TextField
					fullWidth
					required
					name={LAST_NAME}
					{...formik.getFieldProps(LAST_NAME)}
					error={getError(LAST_NAME)}
					helperText={getErrorMessage(LAST_NAME)}
					disabled={loading || existingContact?.lastname != null}
				/>
			</Grid>
			<Grid size={{ xs: 12, md: 6 }}>
				<FormLabel required>{'Email Address'}</FormLabel>
				<TextField
					fullWidth
					name={EMAIL}
					{...formik.getFieldProps(EMAIL)}
					error={getError(EMAIL)}
					helperText={getErrorMessage(EMAIL)}
					required
					disabled={loading || existingContact?.email != null}
					type='email'
				/>
				<FormHelperText>We will use this email to contact you</FormHelperText>
			</Grid>
			<Grid size={{ xs: 12, md: 6 }}>
				<FormControl fullWidth>
					<FormLabel required>{'Phone Number'}</FormLabel>
					<NumericFormat
						customInput={TextField}
						decimalScale={0}
						fixedDecimalScale
						allowNegative={false}
						allowLeadingZeros
						onValueChange={({ value }) => {
							formik.setFieldValue(PHONE, value);
						}}
						{...formik.getFieldProps(PHONE)}
						required
						disabled={loading || existingContact?.phone != null}
						name={PHONE}
						error={getError(PHONE)}
						helperText={getErrorMessage(PHONE)}
					/>
				</FormControl>
			</Grid>
		</>
	);
};
