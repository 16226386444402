/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import {
	Table,
	TableContainer,
	TableRow,
	Paper,
	TableCell,
	Typography,
	Skeleton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import 'pdfjs-dist/build/pdf.worker.mjs';
import { invoiceHeadCells } from './data';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { rowLimits } from '@/components/table/data';
import { 
	changeRowsPerPage, 
	requestSort,
} from '@/features/table/invoicesTableSlice';
import { InstantSearch, Configure } from 'react-instantsearch';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { invoiceClient } from '@/utils/typesense';
import { InvoiceHitRow } from './InvoiceHitRow';

export const InvoiceTable = ({ tab }) => {
	const dispatch = useDispatch();
	// const navigate = useNavigate();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
    
	const { 
		groupView, 
		hubspotId: groupViewHubspotId 
	} = useSelector((state) => state.userPrefs);

	const {
		// pageAfter,
		rowsPerPage,
		// searchValue,
		// searchText,
		orderBy,
		order,
		// page,
		// rows,
	} = useSelector((state) => state.invoicesTable);


	const { data: userDetails, isLoading, isUninitialized } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const salesTeamEmail = userDetails?.email;
	// const userDetailsHubspotId = userDetails?.hubspotId;
	// const hubspotId = groupViewHubspotId || userDetailsHubspotId;

	const brokerGroupId = userDetails?.brokerId;
	const brokerGroupIds = userDetails?.brokerGroupArray ?? [];

	const handleRequestSort = (event, property) => {
		// console.log('here is the property value: ', property);
		dispatch(requestSort(property));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(changeRowsPerPage(event.target.value));
	};

	const sortItems = invoiceHeadCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'invoices', label: cell.label });
		}
		acc.push({ value: `invoices/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
		acc.push({ value: `invoices/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		return acc;
	}, []);

	const filterSchema = useMemo(() => {
		const active = `archived := ${tab !== 'active'}`;
		const ownerFilter = groupView ? `brokerGroupId: [${brokerGroupIds.join(',')}]` : `salesTeamEmail := ${salesTeamEmail}`;
		const schema = {
			filters: `${ownerFilter} && ${active}`
		};
		console.log('Filter schema', schema);
		return schema;
	}, [brokerGroupIds, groupView, salesTeamEmail, tab]);

	return (
		<InstantSearch
			indexName="invoices" 
			searchClient={invoiceClient}
		>
			<TableContainer component={Paper}>
				{isLoading || isUninitialized ? <Skeleton /> : 
					<>
						<Configure {...filterSchema} />
						<Grid container alignItems={'center'} p={1}>
							<Grid size={{ xs: 12, sm: 5 }}>
								<InstantSearchFieldAdaptor />
							</Grid>
							<Grid size={{ xs: 12, sm: 'grow' }}></Grid>
							<Grid size={{ xs: 12, sm: 3 }} container justifyContent={'flex-end'}>
								{/* <Button 
									component="label" 
									variant="contained" 
									startIcon={<Add />}
									onClick={() => navigate(paths.createNewBusiness)}>
				New Client
								</Button> */}
							</Grid>
						</Grid>
			
						<Table
							aria-labelledby="tableTitle"
							size="medium"
							aria-label="enhanced table"
							sx={{ minWidth: 700 }}
						>
							<SearchFolioHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headCells={invoiceHeadCells}
								items={sortItems}
							/>
							<HitsTableBody 
								TableRowComponent={InvoiceHitRow}
								ErrorRowComponent={() => <TableRow>
									<TableCell colSpan={invoiceHeadCells.length} align="center">
										<Typography variant="subtitle1">
                                    There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>}
								EmptyRowComponent={() => <TableRow>
									<TableCell colSpan={invoiceHeadCells.length} align="center">
										<Typography variant="subtitle1">No invoices found</Typography>
									</TableCell>
								</TableRow>}
							/>
						</Table>
						<HitsTablePagination
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							items={rowLimits.map(item => ({
								label: `${item} rows per page`,
								value: item,
								default: item == rowsPerPage
							}))}
						/>
					</>}
			</TableContainer>
		</InstantSearch>
	);
};
