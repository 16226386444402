import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography } from '@mui/material';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { determineContext } from '@/hooks/determineContext';
import { CompleteDealPolicySelection } from '@/components/views/DealView/CompletePipeline/CompleteDealPolicySelection';
import { InsightClaimTable } from '@/components/tables/InsightClaimTable';
import { useAddPoliciesToDealMutation } from '@/features/deals/dealsApi';
import { showToast } from '@/features/toast/toastSlice';
import LoadingButton from '@mui/lab/LoadingButton';

export const EditPolicyTransactionDialog = () => {
	const dispatch = useDispatch();
	
	const open = useSelector((state) => state.dialog.open['editPolicyTransaction']);

	const { deal, client, policies } = determineContext();

	const [selectedPolicies, setSelectedPolicies] = useState([]);
	const [loading, setLoading] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	useEffect(() => console.log('here are the policies: ', policies), [policies]);
	useEffect(() => console.log('Selected policies: ', selectedPolicies), [selectedPolicies]);
	
	const [addPoliciesToDeal] = useAddPoliciesToDealMutation();

	const handleClose = () => {
		dispatch(closeDialog('editPolicyTransaction'));
		setSelectedPolicies([]);
	};

	const policySelectionContent = useMemo(() => {
		switch (deal?.hs_pipeline ?? deal?.pipeline) {
		case process.env.REACT_APP_PIPELINE_CLAIMS:
			return <InsightClaimTable setLoading={setLoading} />;
		default:
			return (
				<CompleteDealPolicySelection
					setSelectedPolicies={setSelectedPolicies}
					setLoading={setLoading}
					editable={true}
				/>
			);
		}
	}, [deal?.hs_pipeline, deal?.pipeline, selectedPolicies, policies, setSelectedPolicies]);

	const completeLinkTransaction = async () => {
		console.log('here are the selected policies: ', selectedPolicies);
		
		setLoading(true);
		try {

			//Fitler out already exisitng policies juse in case
			const policiesToAdd = selectedPolicies
				.filter((policy) => !policies.some((linkedPolicy) => linkedPolicy.Id === policy.Id))
				.map((policy) => ({
					policyId: deal?.pipeline === process.env.REACT_APP_PIPELINE_NEW_BUSINESS ? policy.Id : policies?.[0]?.Id,
					policyTransId: policy.policyTransactions?.[0]?.Id || policy.Id,
				}));

			console.log('here are the policies to add: ', policiesToAdd);
			await addPoliciesToDeal({
				dealId: deal?.id ?? deal?.hs_object_id,
				policies: policiesToAdd,
			});

			handleClose();
			dispatch(
				showToast({
					message: `Successfully linked ${policiesToAdd.length} policy transaction to deal: ${deal?.dealname}!`,
				})
			);
		} catch (err) {
			console.error('Error adding policies', err);
			dispatch(showToast({ message: 'Error adding policies', error: true }));
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				hideBackdrop={true}
				PaperProps={{
					sx: { minWidth: { xs: '100%', sm: '70%', md: '50%' }, padding: '1em' },
				}}
			>
				<DialogTitle sx={{ fontWeight: 'bold' }}>Add policy transaction</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					{policySelectionContent}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button onClick={handleClose} color="primary">Cancel</Button>
					<LoadingButton
						color="primary"
						variant="contained"
						loading={loading}
						disabled={loading || selectedPolicies.length === 0}
						onClick={() => setConfirmationOpen(true)}
					>
                        Add
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{confirmationOpen && (
				<Dialog
					open={confirmationOpen}
					onClose={() => setConfirmationOpen(false)}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>Confirmation</DialogTitle>
					<DialogContent>
						<Typography>
							{`This will link ${selectedPolicies.length} additional policy transaction to a deal. Please note all record keeping will be contained in the same folder.
If this is not desired, please create a new deal.`}
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setConfirmationOpen(false)} color="primary">Cancel</Button>
						<Button
							onClick={() => {
								setConfirmationOpen(false);
								completeLinkTransaction();
							}}
							color="primary"
							variant="contained"
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
