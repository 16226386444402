import React from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { determineContext } from '@/hooks/determineContext';

export const CreateTaskButton = () => {
	const dispatch = useDispatch();
	const { deal, client, contact, objectType } =
	determineContext();

	const handleOpenDialog = () => {
		dispatch(openDialog({ dialogName: 'task', data: { client } }));
	};
    
	return <div style={{display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
		<Button variant="tab_header" startIcon={<Add />}
			onClick={handleOpenDialog}>
			{'Create task'}
		</Button>
	</div>;
};