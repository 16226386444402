/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import {
	FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BUSINESS_INTERRUPTION, DOMESTIC, EMPLOYERS_LIABILITY, GENERAL_LIABILITY, MANAGEMENT_LIABILITY, MATERIAL_DAMAGE, SCOPE_OF_ADVICE, SCOPE_OF_ADVICE_LABELS_DOMESTIC, STATUTORY_LIABILITY, SCOPE_OF_ADVICE_LABELS_COMMERCIAL, COMMERCIAL, NEEDS_ANALYSIS, CLIENT_QUOTED } from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { ClientCheckbox } from '@/components/buttons/ClientCheckbox';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

export const RiskClasses = () => {
	//LABELS FOR SCOPE HAVE BEEN CHANGED NEED TO ADJUST WHEN COMES TO DEAL VIEW
	const { dealRow } = determineContext();
	
	const scopeOfAdvice = useMemo(
		() => dealRow[SCOPE_OF_ADVICE] ?? [],
		[dealRow]
	);

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();
	
	const getScopeOfAdviceLabels = () => {
		if (dealRow?.clientType === COMMERCIAL) {
			return SCOPE_OF_ADVICE_LABELS_COMMERCIAL;
		}
		if (dealRow?.clientType === DOMESTIC) {
			return SCOPE_OF_ADVICE_LABELS_DOMESTIC;
		}
		return {};
	};

	const scopeOfAdviceLabels = getScopeOfAdviceLabels();

	const onChange = async (newValues) => {
		const needsAnalysis = (dealRow?.[NEEDS_ANALYSIS] ?? []).slice();
		const entries = Array.from(new Set(Object.keys(needsAnalysis).concat(newValues)));

		const vals = entries.reduce((acc, key) => {
			if (Object.keys(needsAnalysis).includes(key)) {
				const newVal = needsAnalysis.find(k => k.key === key);
				if (newVal) {
					acc[key] = newVal;
				}
			} else {
				acc[key] = CLIENT_QUOTED;
			}
			return acc;
		}, {});

		const needsAnalysisArray = Object.entries(vals).map(([key, value]) => ({ key, value }));

		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			properties: { 
				[SCOPE_OF_ADVICE]: newValues, 
				[NEEDS_ANALYSIS]: needsAnalysisArray
			}
		}).unwrap();
	};

	return (
		<Grid container>
			{Object.entries(scopeOfAdviceLabels)
				.filter(([value, label]) => {
					// Include EL and SL if Management Liability is in scopeOfAdvice
					if (scopeOfAdvice.includes(MANAGEMENT_LIABILITY)) {
						if (value === EMPLOYERS_LIABILITY || value === STATUTORY_LIABILITY) {
							return true;
						}
					}
	
					// Exclude Business Interruption if Material Damage is not included
					if (value === BUSINESS_INTERRUPTION && !scopeOfAdvice.includes(MATERIAL_DAMAGE)) {
						return false;
					}
	
					// Exclude Statutory Liability and Employers Liability if neither
					// General Liability nor Management Liability is included
					if (
						(value === STATUTORY_LIABILITY || value === EMPLOYERS_LIABILITY) &&
						!scopeOfAdvice.includes(GENERAL_LIABILITY) &&
						!scopeOfAdvice.includes(MANAGEMENT_LIABILITY)
					) {
						return false;
					}
	
	
					return true;
				})
				.map((entry) => {
					const [value, label] = entry;
					return (
						<Grid size={{ xs: 12, sm: 6 }} key={`risk-classes-${value}`}>
							<FormControlLabel
								size="small"
								control={
									<ClientCheckbox
										value={value}
										valuesArray={scopeOfAdvice}
										setValues={onChange}
										disabled={isLoading}
									/>
								}
								label={label}
								sx={{
									'& .MuiTypography-root': {
										fontSize: '12px',
									},
									'& .MuiButtonBase-root': {
										fontSize: '18px',
									},
								}}
							/>
						</Grid>
					);
				})}
		</Grid>
	);
	
};
