/* eslint-disable no-mixed-spaces-and-tabs */
import React, {  } from 'react';
import {
	Box,
	Button,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { Add } from '@mui/icons-material';
import { AddEditButton } from '../EditButton';
import { determineContext } from '@/hooks/determineContext';
import { setEdit } from '@/features/editButton/editButtonSlice';

export const GenerateRiskButton = () => {
	const dispatch = useDispatch();

	const { riskInfo } = determineContext();

	return (
		<Box display="flex" justifyContent="flex-end" width="100%">
			<Stack spacing={1} direction="row" alignItems="center">
				{riskInfo.length > 0 && <AddEditButton type='risk'/>}
				<Button 
					variant="tab_header" 
					startIcon={<Add />}
					onClick={() => {
						dispatch(setEdit({ key: 'risk', value: false }));
						dispatch(openDialog('wytu'));
					}}
				>
					{'Add Risk'}
				</Button>
			</Stack>
		</Box>
	);
};