import React from 'react';
import { AuthenticatedPageLayout } from '@/components/layouts/AuthenticatedPageLayout';
import { CreateRenewalForm } from '@/components/forms/CreateRenewalForm';


export const CreateRenewalPage = () => {
	return (
		<AuthenticatedPageLayout>
			<CreateRenewalForm />
		</AuthenticatedPageLayout>
	);
};