/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useMemo } from 'react';
import {
	Typography,
	List,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useDeleteNoteMutation } from '@/features/engagements/engagementsApi';
import { formatDate } from '@/utils/constants';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { useLocation } from 'react-router-dom';
import EngagementsAccordion from '../EngagementsAccordion';
import AttachmentListItem from '@/components/AttachmentListItem';

export const NoteAccordion = ({ note }) => {
	const { deal, objectType, client } = determineContext();
	const ownerQueryParams = { ownerId: note.createdBy ?? note.ownerId };
	const { data: owner } = useGetOwnerQuery(ownerQueryParams, {
		skip: !ownerQueryParams.ownerId,
	});

	const [deleteNote, { isLoading }] = useDeleteNoteMutation();
	const [dialogOpen, setDialogOpen] = useState(false);

	const handleOpenDialog = () => setDialogOpen(true);
	const handleCloseDialog = () => setDialogOpen(false);
	const handleConfirmDialog = async () => {
		handleCloseDialog();
		await archiveNote();
	};
	const archiveNote = async () => {
		await deleteNote({
			dealId: objectType === 'client' ? client.hs_object_id : deal.hs_object_id,
			noteId: note.id,
			objectType: objectType,
		});
	};

	const getFullName = () => {
		return owner ? `${owner?.firstName} ${owner?.lastName}` : 'Unknown';
	};

	const date = useMemo(() => new Date(note.timestamp), [note.timestamp]);
	const lastUpdated = useMemo(() => new Date(note.lastUpdated), [note.lastUpdated]);

	const location = useLocation();

	const noteOpened = useMemo(() => {
		if (location.state?.note) {
			return location.state.note === note?.id;
		}
		return false;
	}, [location?.state?.note]);

	return (
		<>
			<EngagementsAccordion
				title={
					<>
						<span style={{ fontWeight: 'bold' }}>Note</span> by{' '}
						<span>{getFullName()}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ fontWeight: '500' }}>{formatDate(date, true)} </span>
					</>
				}
				expandedByDefault={noteOpened}
				engagement={note}
				lastUpdated={lastUpdated}
				loading={isLoading}
				handleDelete={handleOpenDialog}
				content={note.content}
				{...(note.files.length > 0 && {
					expandedContent: (
						<>
							<List
								dense
								sx={{
									width: '100%',
									'& .MuiListItemIcon-root': {
										minWidth: 0,
									},
									'& .MuiSvgIcon-root': {
										fontSize: 18,
									},
								}}
							>
								{note.files.map((file, i) => (
									<AttachmentListItem
										key={`attachment-${file.id}-${i}`}
										fileId={file.id}
									/>
								))}
							</List>
						</>
					),
					footerContent: (
						<Typography variant='task_updated'>{`${note.files.length} attachments`}</Typography>
					)
				})}
			/>
			<ConfirmDialog
				openDialog={dialogOpen}
				handleClose={handleCloseDialog}
				handleConfirm={handleConfirmDialog}
				actionName={'delete'}
			/>
		</>
	);
};
