import React from 'react';
import { Card, Skeleton, Box } from '@mui/material';

export const LoadingActivityCard = () => {
	return (
		<Box mb='12px'>
			<Card sx={{ p: '1em' }}>
				<Box py='1em'>
					<Skeleton variant='text' animation='wave' width='60%' />
				</Box>

				<Skeleton animation='wave' height='18px' width='100%' />
				<Skeleton animation='wave' height='18px' width='70%' />
				<div style={{ display: 'flex', justifyContent: 'right', paddingTop: '1em' }}>
					<Skeleton animation='wave' height='16px' width='20%' />
				</div>
			</Card>
		</Box>
	);
};
