/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Card,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	TablePagination,
	Paper,
	Grid,
	Stack,
} from '@mui/material';
import { closedPipelineStages } from '@/utils/constants';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { PolicyTransactionRow } from '@/components/tables/PolicyTransactionRow';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { determineContext } from '@/hooks/determineContext';
import { IS_DEV } from '@/utils/environment';
import { AddNewBusinessButton } from '@/components/buttons/AddNewBusinessButton';
import { useGetClientPoliciesQuery } from '@/features/clients/clientsApi';

const policyHeadCells = [
	{
		id: 'dealname',
		numeric: false,
		label: 'Deal name',
		align: 'left',
		sortable: false,
	},
	{
		id: 'description',
		numeric: false,
		label: 'Policy No / Description',
		align: 'left',
		sortable: false,
	},
	{ id: 'fromToDate', numeric: false, label: 'From / To', align: 'left', sortable: false },
	{ id: 'transAmount', numeric: false, label: 'Trans / Amount', align: 'left', sortable: false },
	{ id: 'status', numeric: false, label: 'Status', align: 'left', sortable: false },
	{ id: 'button', numeric: false, label: '', align: 'left', sortable: false },
];

export const PolicyTransactionTab = () => {
	const { deals, claims, loading, client } = determineContext();

	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('CreatedWhen');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);

	// const { data: policyData, isLoading: loadingPolicies } = useGetClientPoliciesQuery(
	// 	{ insightId: client?.clientRow?.insightId },
	// 	{ skip: !client?.clientRow?.insightId }
	// );

	const isLoading = loading?.client || loading?.deals;// || loadingPolicies;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const progressTickets = useMemo(() => {
		const tickets = claims.filter(
			(d) => d?.properties?.hs_pipeline !== process.env.REACT_APP_PIPELINE_CLAIMS
		);
		return tickets.map(t => ({
			...t,
			policyResults: (t.policyResults ?? []).filter(r => !r?.policyRow?.isClaim),
		}));
	}, [claims]);

	const reducedMergedDeals = useMemo(() => {
		const mergedDeals = IS_DEV ? [...deals.slice(), ...progressTickets.slice()] : deals.slice();
		// return mergedDeals;
		console.log('MERGED DEALS', mergedDeals.map(d => d.id));
		const results = mergedDeals.flatMap((deal) => {
			const policyResults = deal?.policyResults ?? [];
			if (policyResults.length > 0) {
				const res = policyResults.reduce((acc, { policy, transaction }) => {
					acc.push({
						deal,
						policy: Object.keys(policy ?? {}).length > 0 ? policy : null,
						transaction: Object.keys(transaction ?? {}).length > 0 ? transaction : null,
					});
					return acc;
				}, []);
				console.log('RES', res);
				return res;
			} else {
				return { deal };
			}
		});
			// console.log('Deal policies', deal?.policyResults.map(({ policy, transaction }) => [deal?.id, policy?.Id, transaction?.Id]));
			// if (policyResults.length > 0) {
			// 	policyResults.forEach((res) => {
			// 		const resIndex = acc.findIndex((d) => d.deal.id === deal.id);
			// 		const trans =
			// 			Object.keys(res?.transaction ?? {}).length > 0 ? res.transaction : null;
			// 		if (resIndex > -1) {
			// 			const existingRow = acc[resIndex];
			// 			if (existingRow.transaction == null && trans != null) {
			// 				acc[resIndex] = {
			// 					deal,
			// 					policy: res?.policy,
			// 					transaction: trans,
			// 				};
			// 			}
			// 		} else {
			// 			acc.push({
			// 				deal,
			// 				policy: res?.policy,
			// 				transaction: trans,
			// 			});
			// 		}
			// 	});
			// } else {
			// 	acc.push({ deal });
			// }
			// return acc;
		// }, []);
		console.log('RESULTS', results);
		return results;
	}, [deals, progressTickets]);

	const paginatedDeals = useMemo(() => {
		// const sorted = reducedMergedDeals.sort(
		// 	(a, b) =>
		// 		new Date(b?.deal?.createdAt).getTime() - new Date(a?.deal?.createdAt).getTime()
		// );
		const sorted = reducedMergedDeals.sort((a, b) => a?.deal?.id - b?.deal.id);
		console.log('POLICY TRANSACTION TABLE ROWS', sorted);
		return sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
	}, [reducedMergedDeals, page, rowsPerPage]);

	console.log('here are the paginated deals: ', paginatedDeals);
	

	return (
		<TableContainer component={Paper}>
			<Stack direction={'row'} justifyContent={'flex-end'} p={1}>
				<AddNewBusinessButton />
			</Stack>
			<Table
				aria-labelledby='tableTitle'
				size='medium'
				aria-label='enhanced table'
				sx={{ minWidth: 600 }}
			>
				<FolioTableHead order={order} orderBy={orderBy} headCells={policyHeadCells} />
				<TableBody>
					{isLoading ? (
						<TableProgressIndicator rowCount={policyHeadCells.length} />
					) : paginatedDeals.length > 0 ? (
						paginatedDeals.map((deal, dealIndex) => (
							<PolicyTransactionRow
								key={`deal-${deal?.deal?.id}-policy-${dealIndex}`}
								transaction={deal?.transaction}
								deal={deal?.deal}
								policy={deal?.policy}
							/>
						))
					) : (
						<TableRow>
							<TableCell colSpan={policyHeadCells.length} align='center'>
								No deals found.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[20, 50, 100]}
				component="div"
				count={reducedMergedDeals.length} 
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				SelectProps={{
					inputProps: {
						'aria-label': 'Rows per page',
					},
					native: true,
				}}
				sx={{
					display: 'block',
					width: '100%',
				}}
				showLastButton
			/>
		</TableContainer>
	);
};

