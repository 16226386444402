/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Box,
	CircularProgress,
	IconButton,
	useTheme,
	Stack,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { folioBlue, formatDate } from '@/utils/constants';
import '@/styles/index.scss';
import { BlinkingCircle } from '@/components/icons/BlinkingCircle';
import AttachFile from '@mui/icons-material/AttachFile';
import parse, { domToReact } from 'html-react-parser';
import EngagementsAccordion from '../EngagementsAccordion';

export const EmailActivityAccordion = ({ email }) => {

	const date = useMemo(() => {
		const timestamp = email?.created;
		return timestamp
			? new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000)
			: new Date();
	}, [email?.created]);

	const lastUpdated = useMemo(() => {
		const timestamp = email?.lastUpdated;
		return timestamp
			? new Date(timestamp.toString().length > 10 ? timestamp : timestamp * 1000)
			: new Date();
	}, [email?.lastUpdated]);

	return (
		<EngagementsAccordion
			title={
                <Stack>
					<Typography>
						<span style={{ fontWeight: 'bold' }}>Email - {email?.subject}</span>
						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
						<span style={{ fontWeight: '500' }}> {formatDate(date, true)} </span>
					</Typography>
					<Typography variant='task_updated'>
						<span style={{ fontWeight: 'bold', marginRight: '0.25em' }}>{'From: '}</span>
						<span>{`${email?.from}`}</span>
						<span style={{ fontWeight: 'bold', marginRight: '0.25em', marginLeft: '0.25em' }}>{' to: '}</span>
						<span>{`${email?.to}`}</span>
					</Typography>
				</Stack>
				// <>
				// 	<span style={{ fontWeight: 'bold' }}>Email - {email?.subject}</span> from{' '}
				// 	<span>{`${email.from}`}</span>
				// 	<span style={{ margin: '0 0.5em' }}>{' | '}</span>
				// 	<span style={{ fontWeight: '500' }}>{formatDate(date, true)}</span>
				// </>
			}
			engagement={email}
			lastUpdated={lastUpdated}
			content={<Typography>to {email?.to}</Typography>}
			footerContent={<Stack direction="row">
				<span style={{ paddingRight: '5px' }}>
					<BlinkingCircle fontSize='6px' color={folioBlue} />
				</span>
				{email?.status}
			</Stack>}
			expandedContent={<Stack alignContent={'center'}>
				{parse((email?.html ?? '').replace(/(\\n)/g, '\n').replace(/<br\/>/g, '\n'),
					{ replace({ name, children }) {
						if (name == 'html') {
							const body = children.find(n => n.name == 'body');
							if (body) {
								return <Box width='100%'>{domToReact(body.children)}</Box>;
							} else {
								return <></>;
							}
                            
						}
					}})}
				{email?.attachments.length > 0 && (
					<List
						dense
						sx={{
							width: '100%',
							'& .MuiListItemIcon-root': {
								minWidth: 0,
								marginRight: '12px',
							},
							'& .MuiSvgIcon-root': {
								fontSize: 18,
							},
						}}
					>
						{email?.attachments.map((file, i) => (
							<ListItem disablePadding key={`${file.name}-${i}`}>
								<ListItemButton
									target='_blank'
									rel='noopener noreferrer'
									href={file.url}
								>
									<ListItemIcon>
										<AttachFile />
									</ListItemIcon>
									<ListItemText
										sx={{ color: '#505AFC' }}
										primary={`${file.name}.${file.extension}`}
									/>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				)}
			</Stack>
			}

		/>
	);
    
	// return (
	// 	<Accordion
	// 		key={`activity-tab-task-${index}`}
	// 		expanded={expanded}
	// 		onChange={handleChange}
	// 		sx={{
	// 			marginBottom: '12px',
	// 			borderRadius: '4px',
	// 			boxShadow: baseTheme.shadows[1],
	// 		}}
	// 	>
	// 		<Stack>
	// 			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
	// 				<AccordionSummary
	// 					expandIcon={
	// 						<ArrowForwardIosSharpIcon
	// 							sx={{ fontSize: '0.9rem', color: folioBlue }}
	// 						/>
	// 					}
	// 					aria-controls='panel1bh-content'
	// 					id='panel1bh-header'
	// 					sx={{
	// 						flexDirection: 'row-reverse',
	// 						width: '100%',
	// 						marginBottom: '-1rem',
	// 						'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
	// 							transform: 'rotate(90deg)',
	// 						},
	// 					}}
	// 				>
	// 					<Typography variant='task_body' sx={{ marginLeft: '0.5em' }}>
	// 						<span style={{ fontWeight: 'bold' }}>Email - {email?.subject}</span>{' '}
	// 						from <span>{`${email.from}`}</span>
	// 						<span style={{ margin: '0 0.5em' }}>{' | '}</span>
	// 						<span style={{ color: '#505AFC' }}>{formatDate(date, true)}</span>
	// 					</Typography>
	// 				</AccordionSummary>
	// 			</div>
	// 			<Stack direction='column' spacing={2} sx={{ marginLeft: '1.5em' }}>
	// 				<Typography>to {email?.to}</Typography>
	// 				<span style={{ alignItems: 'center' }}>
	// 					<span style={{ paddingRight: '5px' }}>
	// 						<BlinkingCircle fontSize='6px' color={folioBlue} />
	// 					</span>
	// 					{email?.status}
	// 				</span>
	// 			</Stack>
	// 			<div style={{ display: 'flex', justifyContent: 'right', padding: '10px' }}>
	// 				<Typography variant='task_updated'>
	// 					{`Last updated: ${formatDate(lastUpdated, true)}`}
	// 				</Typography>
	// 			</div>
	// 		</Stack>
	// 		<AccordionDetails
	// 			sx={{ borderTop: '1px solid rgba(0, 0, 0, .125)', margin: '0 1.5em 1.5em' }}
	// 		>
	// 			<Stack width='100%'>
	// 				{parse((email?.html ?? '').replace(/(\\n)/g, '\n').replace(/<br\/>/g, '\n'))}
	// 				{email?.attachments.length > 0 && (
	// 					<List
	// 						dense
	// 						sx={{
	// 							width: '100%',
	// 							'& .MuiListItemIcon-root': {
	// 								minWidth: 0,
	// 								marginRight: '12px',
	// 							},
	// 							'& .MuiSvgIcon-root': {
	// 								fontSize: 18,
	// 							},
	// 						}}
	// 					>
	// 						{email?.attachments.map((file, i) => (
	// 							<ListItem disablePadding key={`${file.name}-${i}`}>
	// 								<ListItemButton
	// 									target='_blank'
	// 									rel='noopener noreferrer'
	// 									href={file.url}
	// 								>
	// 									<ListItemIcon>
	// 										<AttachFile />
	// 									</ListItemIcon>
	// 									<ListItemText
	// 										sx={{ color: '#505AFC' }}
	// 										primary={`${file.name}.${file.extension}`}
	// 									/>
	// 								</ListItemButton>
	// 							</ListItem>
	// 						))}
	// 					</List>
	// 				)}
	// 			</Stack>
	// 		</AccordionDetails>
	// 	</Accordion>
	// );
};
