/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useSelector } from 'react-redux';
import { useMsGraph } from '@/hooks/useMsGraph';
import { useSharepoint } from '@/hooks/useSharepoint';

const initialState = {
	deal: {},
	client: {},
	sharepoint: {},
	isLoading: true,
	isError: false,
	dealRow: {},
	files: [],
};

const AttachmentsContext = createContext(initialState);

export const useAttachmentsContext = () => {
	const context = useContext(AttachmentsContext);
	if (!context) {
		throw new Error('useAttachmentsContext must be used within an AttachmentsContextProvider');
	}
	return context;
};

export const AttachmentsContextProvider = ({ deal, client, children }) => {
	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery(
		{ hubspotId: ownerId },
		{ skip: !ownerId }
	);

	const clientRow = client?.clientRow;
	const [loading, setLoading] = useState(true);
	const [files, setFiles] = useState([]);

	const dealRowQuery = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, { skip: !deal?.hs_object_id && !deal?.id });

	const { getClientGraphFolders } = useClientStorageHooks(userQuery.data);
    
	const isLoading = userQuery.isLoading || userQuery.isUninitialized || (deal && dealRowQuery.isLoading) || loading;
	const isError = userQuery.isError || !ownerId;

	const { refreshIndicator } = useSelector((state) => state.attachmentsTable);

	const sharepoint = useMemo(() => {
		const isValidSharepoint = userQuery.data?.sharepointSite != null && 
        userQuery.data?.clientSiteSuffix != null && 
        userQuery.data?.clientFolder != null && 
        userQuery.data?.clientSite != null;
		return {
			sharepointSite: userQuery.data?.sharepointSite,
	        clientSiteSuffix: userQuery.data?.clientSiteSuffix,
			clientFolder: userQuery.data?.clientFolder,
			clientSite: userQuery.data?.clientSite,
			isValid: isValidSharepoint,
		};
	}, [userQuery.data]);

	const getFolderPaths = (folders) => {
		const folderMap = folders.reduce((acc, folder) => {
			acc[folder.id] = folder;
			return acc;
		}, {});
    
		const buildPath = (folder) => {
			const { name, parentReference } = folder;
    
			if (!parentReference || !parentReference.id) {
				return name;
			}
    
			const parentFolder = folderMap[parentReference.id];
			if (parentFolder) {
				return `${buildPath(parentFolder)}/${name}`;
			}
    
			return name;
		};
    
		return folders.map(folder => buildPath(folder));
	};
    
	useEffect(() => {
		const updateMsGraph = async () => {
			const row = (deal ? dealRowQuery.data : clientRow);
			if (row?.driveId) {
				const { files, folders } = await getClientGraphFolders(row);
				const folderPaths = getFolderPaths(folders);
				
				setFiles(files.map(f => {
					const suffix = f.name.split('.');
					return {
						...f,
						type: suffix[suffix.length - 1],
						Description: '',
						directory: folderPaths.find(p => p.endsWith(f.parentReference.name)),
						createdDateTimeUnix: Date.parse(f.createdDateTime)
					};
				}));
			}
			setLoading(false);
		};
		console.log('REFRESH INDICATOR', refreshIndicator);
		if (userQuery.data && (deal ? dealRowQuery.data : clientRow)) {
			setLoading(true);
			updateMsGraph();
		}
	}, [userQuery.data, clientRow, dealRowQuery.data, refreshIndicator]);

	const contextValue = {
		deal,
		client,
		dealRow: dealRowQuery.data ?? {},
		files,
		isLoading,
		isError,
		sharepoint,
	};

	useEffect(() => {
		console.log('📋 ~ ATTACHMENTS CONTEXT VALUE', contextValue);
	}, [contextValue]);
	
	return (
	// Provide the context variables to the children components.
		<AttachmentsContext.Provider value={contextValue}>
			{ children }
		</AttachmentsContext.Provider>
	);
};

export default AttachmentsContext;