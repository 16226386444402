/* eslint-disable no-mixed-spaces-and-tabs */
import React, {  } from 'react';
import {
	Box,
	Button,
	Divider,
	Stack,
	Typography,
} from '@mui/material';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { Add } from '@mui/icons-material';
import { AddEditButton } from '../EditButton';
import { determineContext } from '@/hooks/determineContext';
import { setEdit } from '@/features/editButton/editButtonSlice';

export const AddQuoteButton = () => {
	const dispatch = useDispatch();

	const { quotes } = determineContext();

	return (
		<Box display="flex" justifyContent="flex-end" width="100%">
			<Stack spacing={1} direction="row" alignItems="center" sx={{alignItems: 'center', alignContent: 'center'}}>
				{quotes.length > 0 && <AddEditButton type='quote'/>}
				<Button 
					variant="tab_header" 
					startIcon={<Add />}
					onClick={() => {
						dispatch(setEdit({ key: 'quote', value: false }));
						dispatch(openDialog('quote'));
					}}
				>
					{'Add Quote'}
				</Button>
			</Stack>
		</Box>
	);
};