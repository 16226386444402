import React, { useMemo, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Collapse,
	Divider,
	Link,
	Stack,
	Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { clamp, folioBlue, stabilisedTaskSort } from '@/utils/constants';
import { LoadingTaskCard } from '@/components/cards/LoadingTaskCard';
import { TransitionGroup } from 'react-transition-group';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { determineContext } from '@/hooks/determineContext';
import { useSelector } from 'react-redux';
import { TaskCard } from '@/components/cards/TaskCard';

export const CollapsibleTaskGroup = () => {
	const { loading, count } = useSelector((state) => state.engagementsSlice);
	const incompleteCount = count.incompleteTasks;

	const [expanded, setExpanded] = useState(true);

	const { tasks } = determineContext();

	const incompleteTasks = useMemo(
		() => stabilisedTaskSort(tasks.filter((t) => !t.completed)),
		[tasks]
	);

	const shownTasks = useMemo(() => incompleteTasks.slice(0, 2), [incompleteTasks]);
	const hiddenTasks = useMemo(() => incompleteTasks.slice(2), [incompleteTasks]);

	const [showAll, setShowAll] = useState(false);

	const renderTasks = (collection) => {
		return collection.map((task) => (
			<Collapse key={`incomplete-task-${task.id}`}>
				<TaskCard task={task} />
			</Collapse>
		));
	};

	const renderLoadingTasks = () => {
		const amount = showAll ? incompleteCount : clamp(incompleteCount, 1, 2);
		return [...Array(amount)].map((_, i) => <LoadingTaskCard key={`loading-task-${i}`} />);
	};

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			sx={{
				backgroundColor: 'transparent',
				width: '100%',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
			onChange={() => {
				if (expanded) {
					setShowAll(false);
				}
				setExpanded((prev) => !prev);
			}}
		>
			<AccordionSummary
				expandIcon={
					incompleteTasks.length > 0 ? <ExpandMore sx={{ color: folioBlue }} /> : <></>
				}
				sx={{
					padding: 0,
				}}
			>
				<Stack
					direction='row'
					justifyContent={'space-between'}
					alignItems={'center'}
					sx={{ width: '100%' }}
				>
					<Typography>
						<span style={{ marginRight: 3 }}>{incompleteTasks.length}</span>
						{`Remaining task${incompleteTasks.length === 1 ? '' : 's'}`}
					</Typography>
					<Link
						to='/my-tasks'
						component={RouterLink}
						sx={{ textDecorationLine: 'none', paddingTop: '2px', marginLeft: 3 }}
					>
						View all
					</Link>
				</Stack>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: '0px' }}>
				<Stack spacing={1}>
					{loading ? (
						renderLoadingTasks()
					) : (
						<TransitionGroup>
							{renderTasks(shownTasks)}
							{showAll && renderTasks(hiddenTasks)}
						</TransitionGroup>
					)}
					{hiddenTasks.length > 0 && (
						<Stack direction='row' justifyContent='flex-end'>
							<Button
								onClick={() => setShowAll(!showAll)}
								size='small'
								sx={{ minWidth: 'auto' }}
								endIcon={!showAll ? <MoreHorizIcon /> : <ExpandLessIcon />}
							>
								{!showAll ? 'MORE' : 'LESS'}
							</Button>
						</Stack>
					)}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};
