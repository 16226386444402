import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridYearPicker from '@/components/table/DataGridYearPicker';
import useCellModes from '@/hooks/useCellModes';

export const MotorVehicleTable = ({ rows, updateRows, removeRow, disabled }) => {
	const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes();

	const columns = [
		{
			field: 'year',
			headerName: 'Year',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			singleClickCell: true,
			renderCell: (params) =>
				params.row.year === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>{'Enter year'}</Typography>
				) : (
					params.row.year
				),
			renderEditCell: (params) => <DataGridYearPicker {...params} />,
		},
		{
			field: 'make',
			headerName: 'Make',
			flex: 0.3,
			minWidth: 100,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.make === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter make</Typography>
				) : (
					params.row.make
				),
		},
		{
			field: 'model',
			headerName: 'Model',
			flex: 0.3,
			minWidth: 100,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.model === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter model</Typography>
				) : (
					params.row.model
				),
		},
		{
			field: 'coverage',
			headerName: 'Coverage',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.coverage === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter coverage</Typography>
				) : (
					params.row.coverage
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				if (value > 0) {
					return formatCurrency(value, false);
				} else {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter sum insured</Typography>
					);
				}
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
		{
			field: 'lossOfUse',
			headerName: 'Loss of Use',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.lossOfUse === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter loss of use</Typography>
				) : (
					params.row.lossOfUse
				),
		},
		{
			// ...GRID_CHECKBOX_SELECTION_COL_DEF,
			field: 'delete',
			headerName: 'Delete',
			width: 48,
			sortable: false,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <></>,
			renderCell: (params) =>
				rows.length > 1 ? (
					<IconButton
						sx={{ fontSize: '10px', padding: 0 }}
						color='rgba(0,0,0,0)'
						onClick={() => removeRow(params.row.id)}
					>
						<Delete />
					</IconButton>
				) : (
					<></>
				),
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => !disabled}
			// getRowId={(row) => `${row.year}-${row.make}-${row.model}`}
			// checkboxSelection
			// onRowSelectionModelChange={setRowSelectionModel}
			// rowSelectionModel={rowSelectionModel}
			cellModesModel={cellModesModel}
			onCellModesModelChange={handleCellModesModelChange}
			onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
