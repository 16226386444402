import { ReactComponent as ClaimsIconSVG } from '@/assets/icons/New-Claim.svg';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const ClaimsIcon = ({ bgColor = '#F5D3D8', width = '24px', height = '24px', ...props }) => {
	return (
		<SvgIcon
			{...props}
			sx={{
				backgroundColor: bgColor,
				width: width,
				height: height,
				borderRadius: 2
			}}
		>
			<ClaimsIconSVG />
		</SvgIcon>
	);
};
