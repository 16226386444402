/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo } from 'react';
import { determineContext } from '@/hooks/determineContext';
import { FactFindForm } from '@/components/forms/FactFindForm';

export const QuotesTab = () => {
	const { quotes } = determineContext();

	const latestQuote = useMemo(() => quotes[0] ?? {}, [quotes]);

	return <FactFindForm title={'Quote'} factFindType={'quote'} latestDoc={latestQuote} />;
};
