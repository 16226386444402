import React, { useEffect, useState, useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack } from '@mui/material';
import { folioBlue } from '@/utils/constants';
import ExpandMore from '@mui/icons-material/ExpandMore';

const ComplianceAccordion = ({ title, children, tasks, expandedByDefault, truncateTotal }) => {
	const [expanded, setExpanded] = useState(() => expandedByDefault ?? false);

	const completed = useMemo(() => tasks.filter(t => t?.completed).length, [tasks]);

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			sx={{
				backgroundColor: 'transparent',
				width: '100%',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
				sx={{
					borderBottom: 'none',
					padding: 0,
				}}
			>
				<Stack spacing={1} direction='row' justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
					<Typography variant='subtitle2'>{title}</Typography>
					<Typography variant='task_updated'>{`${completed}/${tasks.length}${truncateTotal ? '' : ' complete'}`}</Typography>
				</Stack>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: '0px' }}>{children}</AccordionDetails>
		</Accordion>
	);
};

export default ComplianceAccordion;
