export const invoiceHeadCells = [
	{ id: 'clientName', numeric: false, label: 'Client Name', align: 'left' },
	{ id: 'transactionType', numeric: false, label: 'Transaction Type', align: 'left' },
    { id: 'createdWhenUnix', numeric: false, date: true, label: 'Created', align: 'left' },
	{ id: 'invoiceNumber', numeric: false, label: 'Invoice Number', align: 'left' },
	{ id: 'balance', numeric: true, label: 'Amount', align: 'right' },
	{ id: 'isPaid', numeric: false, label: 'Status', align: 'left' },
	{ id: 'downloadUrl', numeric: false, label: 'Actions', sortable: false, align: 'center' },
	{ id: 'paymentLink', numeric: false, label: 'Payment Link', sortable: false, align: 'center' },
];

export const formatInvoiceData = (invoice) => {
	// console.log('Invoice', invoice);
	var createdDate;
	if (invoice.data.createdWhen?._seconds) {
		createdDate = new Date(new Date(0).setSeconds(invoice.data.createdWhen._seconds)).toISOString();
	} else if (typeof invoice.data.createdWhen === 'string') {
		createdDate = new Date(invoice.data.createdWhen).toISOString();
	}
	
	const reformattedInvoice = {
		createdWhen: createdDate,
		docId: invoice.id,
		clientName: invoice.data.clientName,
		classCode: invoice.data.portfolio.policies[0].classCode,
		transactionType: invoice.data.transactionType,
		invoiceNumber: invoice.data.invoiceNumber,
		balance: invoice.data.balance,
		isPaid: invoice.isPaid,
		status: invoice.isPaid ? 'RECEIPTED' : 'UNRECEIPTED',
		invoice: invoice.data,
		archived: invoice.data.archived,
		downloadUrl: invoice.data.downloadUrl,
		id: invoice.data.policyId,
	};
	// console.log('reformatted invoice', reformattedInvoice);
	
	return reformattedInvoice;
};