import React, { useMemo, useState } from 'react';
import {
	Box,
	Card,
	Tab,
	CardHeader,
	Tabs,
	Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Add } from '@mui/icons-material';
import { PageHeader } from '@/components/layouts/PageHeader';
import ContactsTable from '@/components/tables/ContactsTable';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';

export const ContactsView = () => {
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState('lead');

	const tabs = [
		{
			label: 'Prospects',
			value: 'lead',
		},
		// {
		// 	label: 'Upcoming',
		// 	value: 'upcoming',
		// },
		// {
		// 	label: 'Completed',
		// 	value: 'completed',
		// },
	];

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	const handleOpenDialog = () => {
		dispatch(openDialog('contact'));
	};

	return (
		<Box
			sx={{
				backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100'),
				p: 3,
			}}
		>
			<CardHeader
				disableTypography
				title={
					<PageHeader title={'Prospects'} />
				}
			/>
			<Card>
				<Grid container>
					<Grid size={'grow'}>
						<Tabs
							indicatorColor="primary"
							scrollButtons="auto"
							textColor="primary"
							value={currentTab}
							sx={{ px: 2 }}
							variant="scrollable"
							onChange={handleTabChange}
						>
							{tabs.map((tab) => (
								<Tab
									sx={{ fontWeight: 'bold' }}
									key={tab.value}
									label={tab.label}
									value={tab.value}
								/>
							))}
						</Tabs>
					</Grid>
					<Grid size={3} container justifyContent={'flex-end'} mr={2} py={1}>
						<Button 
							variant="contained" 
							startIcon={<Add />}
							onClick={handleOpenDialog}>
				New Prospect
						</Button>
					</Grid>
				</Grid>
				<ContactsTable tab={currentTab} />
			</Card>
		</Box>
	);
};
