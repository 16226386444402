import { createSlice } from '@reduxjs/toolkit';

const loadFilters = () => {
	const storageValue = localStorage.getItem('userPrefs');
	const parsedJson = storageValue ? JSON.parse(storageValue) : { };
	return {
		groupView: false,
		kanbanView: false,
		hubspotId: null,
		...(parsedJson ?? {})
	};
};

const updateUserPrefs = (state) => {
	localStorage.setItem('userPrefs', JSON.stringify({...state}));
};

export const userPrefsSlice = createSlice({
	name: 'userPrefs',
	initialState: loadFilters(),
	reducers: {
		setGroupView: (state, action) => {
			console.log('👾 ~ setGroupView: action.payload', action.payload);
			state.groupView = action.payload;

			updateUserPrefs(state);
		},
		setHubspotId: (state, action) => {
			console.log('👾 ~ setHubspotId: action.payload', action.payload);
			state.hubspotId = action.payload;

			updateUserPrefs(state);
		},
		setKanbanView: (state, action) => {
			console.log('👾 ~ setKanbanView: action.payload', action.payload);
			state.kanbanView = action.payload;

			updateUserPrefs(state);
		}
	},
});

export const { setGroupView, setHubspotId, setKanbanView } = userPrefsSlice.actions;
export default userPrefsSlice.reducer;