import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	FormLabel,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Chip,
	Tooltip,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { useRefinementList } from 'react-instantsearch';
import { theme } from '@/app/theme';
import { folioBlue } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '@/features/table/instantSearchSlice';

export const RefinementCheckboxList = ({
	title,
	disabled,
	multiple,
	width = '150px',
	pipeline,
	...props
}) => {
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState(false);

	const { filters } = useSelector((state) => state.instantSearch);

	const currentFilters = filters[props.attribute] ?? [];

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const stages = pipelineQuery?.data?.pipelines?.stages ?? [];

	const { items, refine } = useRefinementList(props);

	const refactoredItems = useMemo(() => {
		// console.log('ITEMS', items);
		const itemsWithStages = items.map((item) => {
			const stage = stages.find((s) => s.id == item.value);
			return {
				...item,
				...(stage && { label: stage.label, order: stage.displayOrder }),
			};
		});
		return Object.keys(stages).length > 0
			? stableSort(itemsWithStages, getComparator('asc', 'order'))
			: stableSort(items, getComparator('asc', 'label'));
	}, [items, stages]);

	const all = useMemo(
		() => ({
			label: `Clear ${title} filters`,
			value: 'Clear',
			isRefined: false,//refactoredItems.every((item) => item.isRefined),
		}),
		[refactoredItems, title]
	);

	const refinedItems = items.filter((i) => i.isRefined);

	const allItems = useMemo(
		() =>
			multiple && refinedItems.length > 0 ? [all, ...refactoredItems] : [...refactoredItems],
		[refactoredItems, refinedItems]
	);

	const haveSameContents = (a, b) =>
		a.length === b.length &&
		[...new Set([...a.slice(), ...b.slice()])].every(
			(v) => a.filter((e) => e === v).length === b.filter((e) => e === v).length
		);

	useEffect(() => {
		const refined = refinedItems.map((v) => v.value);
		console.log(`CURRENT FILTERS ${props.attribute}`, currentFilters, 'refined', refined);

		const isEmpty = currentFilters.length === 0;

		if (haveSameContents(refined, currentFilters)) {
			return;
		}

		const changes = currentFilters.filter((i) => !refined.includes(i));
		const deletions = refined.filter((i) => !currentFilters.includes(i));
		console.log('CHANGES', changes, 'DELETIONS', deletions);

		if (isEmpty && refined.length > 0) {
			refined.forEach((i) => refine(i));
		} else if (changes.length > 0) {
			changes.forEach((i) => refine(i));
		} else if (deletions.length > 0) {
			deletions.forEach((i) => refine(i));
		}
	}, [currentFilters, refinedItems]);

	useEffect(() => {
		if (refinedItems.length > 0) {
			setExpanded(true);
		}
	}, [refinedItems]);

	const handleChange = (item) => {
		console.log('NEW SELECTION', item);
		// const refined = refinedItems.map((v) => v.value);
		if (multiple) {
			if (['All', 'Clear', 'None'].includes(item.value)) {
				dispatch(setFilters({ type: props.attribute, values: [] }));
			} else {
				// var multi = item.reduce((o, n) => {
				// 	n in o ? (o[n] += 1) : (o[n] = 1);
				// 	return o;
				// }, {});

				// var res = Object.keys(multi).filter((k) => multi[k] == 1);
				const multi = currentFilters.includes(item.value)
					? [...currentFilters.slice().filter((v) => v != item.value)]
					: [...currentFilters.slice(), item.value];
				console.log('MULTI', multi, 'FILTERS', currentFilters);
				dispatch(setFilters({ type: props.attribute, values: multi }));
				// refine(item.value);
			}
		} else {
			if (item.value == 'None') {
				// refined.forEach((item) => refine(item));
				dispatch(setFilters({ type: props.attribute, values: [] }));
			} else {
				// refine(item.value);
				dispatch(
					setFilters({
						type: props.attribute,
						values: currentFilters.includes(item.value) ? [] : [item.value],
					})
				);
			}
		}
	};

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			sx={{
				backgroundColor: 'transparent',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='panel1-content'
				id='panel1-header'
			>
				<Stack
					direction='row'
					justifyContent={'space-between'}
					width={'100%'}
					alignItems={'center'}
				>
					<FormLabel sx={{ minimalLabelTheme, color: folioBlue }}>{title}</FormLabel>
					{refinedItems.length > 0 && (
						<Typography
							variant='task_updated'
							sx={{ fontWeight: 'bold' }}
						>{`(${refinedItems.length})`}</Typography>
					)}
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<FormGroup>
					{allItems.map((item, index) => {
						const isClearOption = item.value.toUpperCase() === 'CLEAR';
						return (
							<FormControlLabel
								key={`${index}-${item.label}`}
								control={
									<Checkbox
										checked={item.isRefined}
										onChange={() => handleChange(item)}
										{...(isClearOption && { icon: <ClearIcon /> })}
									/>
								}
								label={
									<span {...(isClearOption && { style: { fontWeight: 500 } })}>
										{item.label}
									</span>
								}
							/>
						);
					})}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};
