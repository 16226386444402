/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	Select,
	MenuItem,
	FormLabel,
	ListItemIcon,
	Box,
	ToggleButtonGroup,
	ToggleButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import { Formik, FormikContext, useFormik } from 'formik';
import * as Yup from 'yup';
import { addDays, getUnixTime, isAfter, isValid } from 'date-fns';
import {
	taskTypes,
	getUrlFromPipeline,
	formatDate,
	getTimeFromDate,
	formatTimeToHourMinutes,
} from '@/utils/constants';
import {
	useCreateTaskMutation,
	useGetTaskDocumentByIdQuery,
	useUpdateTaskMutation,
} from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { InstantDealSelect } from '@/components/selects/DealSelect';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { showToast } from '@/features/toast/toastSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { useMsGraph } from '@/hooks/useMsGraph';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { useGetBrokerOwnersQuery } from '@/features/user/ownerApi';
import { AddressFields } from '@/components/forms/CreateNewBusinessForm/AddressFields';
import { validationSchema } from './validationSchema';

const minDate = new Date();

export const AddTaskDialog = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);

	const {
		createOnlineMeetingEvent,
		createEventTask,
		getUserIdByEmail,
		getGraphEventData,
		updateGraphEventData,
	} = useMsGraph();

	const [updateTask] = useUpdateTaskMutation();
	const open = useSelector((state) => state.dialog.open['task']);

	const dialogData = useSelector((state) => state.dialog.data ?? {});
	const [eventTaskMsData, setEventTaskData] = useState({});

	useEffect(
		() => console.log('here is the task dialog data: ', dialogData),
		[dialogData]
	);

	const taskId = dialogData?.task?.task?.id ?? dialogData?.task?.engagement?.id;

	const {
		data: taskData,
		isLoadingTask,
		error,
	} = useGetTaskDocumentByIdQuery(String(taskId), {
		skip: !taskId,
	});

	const handleFetchGraphEvent = async (taskType, graphEventId) => {
		try {
			return await getGraphEventData(taskType, graphEventId);
		} catch (error) {
			console.error('Failed to fetch graph event data:', error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			console.log('here is the task data: ', dialogData);
			console.log('here is the task firebase data: ', taskData);

			if (taskData && taskData.graphEventId) {
				try {
					const eventData = await handleFetchGraphEvent(
						taskData.taskType,
						taskData.graphEventId
					);
					setEventTaskData(eventData);
					console.log('here is the event data: ', eventData);
				} catch (error) {
					console.error('Error fetching event data:', error);
				}
			}
		};
		fetchData();
	}, [dialogData, taskData]);

	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const hubspotId = userDetails?.hubspotId;
	const brokers = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});

	const name = account?.name;
	const company = userDetails?.broker?.name;

	const initialValues = useMemo(() => {
		// Standardize the task data to handle both 'task.task' and 'task.engagement'
		const taskData =
      dialogData?.task?.task ?? dialogData?.task?.engagement ?? {};
		console.log('Here is the generic task data:', taskData);

		const date = taskData?.hs_timestamp
			? new Date(taskData.hs_timestamp)
			: taskData?.data?.timestamp
				? new Date(taskData.data.timestamp)
				: addDays(minDate, 3);

		const utcStartDate = eventTaskMsData?.start?.dateTime
			? new Date(eventTaskMsData.start.dateTime)
			: null;
		const utcEndDate = eventTaskMsData?.end?.dateTime
			? new Date(eventTaskMsData.end.dateTime)
			: null;

		const offsetInMinutes = 13 * 60;
		const nzStartDate = utcStartDate
			? new Date(utcStartDate.getTime() + offsetInMinutes * 60 * 1000)
			: null;
		const nzEndDate = utcEndDate
			? new Date(utcEndDate.getTime() + offsetInMinutes * 60 * 1000)
			: null;

		return {
			title: taskData?.hs_task_subject ?? taskData?.subject ?? ' ',
			dueDate: date,
			priority: taskData?.hs_task_priority ?? taskData?.priority ?? 'LOW',
			notes: taskData?.hs_task_body ?? taskData?.body ?? ' ',
			taskType:
        taskData?.hs_task_type ?? taskData?.metadata?.taskType ?? taskTypes[0],
			errorMessage: '',
			deal:
        taskData?.deal ??
        taskData?.data?.deal ??
        taskData?.ticket ??
        taskData?.data?.ticket,
			client:
        taskData?.client ?? taskData?.data?.client ?? dialogData?.task?.client,
			contact: null,
			objectType: null,
			ownerId:
        taskData.hubspot_owner_id ?? taskData?.ownerId ?? userDetails.hubspotId,
			attendees:
        eventTaskMsData?.attendees?.map((contact) => ({
        	emailAddress: contact?.emailAddress?.address ?? contact.email,
        	name: contact?.emailAddress?.name,
        })) ?? [],
			userId: null,
			isOnlineMeeting: false,
			startTime:
        eventTaskMsData && Object.keys(eventTaskMsData).length > 0
        	? nzStartDate
        	: null,
			address:
        eventTaskMsData?.location?.displayName ??
        eventTaskMsData?.locations?.[0]?.displayName ??
        '',
			endTime:
        eventTaskMsData && Object.keys(eventTaskMsData).length > 0
        	? nzEndDate
        	: null,
			isExistingTask:
        !!(taskData && Object.keys(taskData).length > 0) &&
        !!(eventTaskMsData && Object.keys(eventTaskMsData).length > 0),
		};
	}, [dialogData, eventTaskMsData]);

	const { deal, client, contact, objectType } =
    determineContext() ?? initialValues;

	useEffect(
		() => console.log('Task context', 'DEAL', deal, 'CLIENT', client),
		[deal, client]
	);

	const [createTask, { isLoading }] = useCreateTaskMutation();

	const getDefaultDateTime = (date) => {
		date.setHours(12, 0, 0, 0);
		return date;
	};

	const handleClose = () => {
		dispatch(closeDialog('task'));
		console.log('here is the data: ', dialogData);
	};

	const handleChange = (event, newValue) => {
		if (newValue !== null) {
			formik.setFieldValue('isOnlineMeeting', newValue);
		}
	};

	const handleTaskSubmission = async (graphId) => {
		const dealId = formik.values.deal?.hs_object_id ?? formik.values.deal?.id;
		const clientId =
      formik.values.client?.id ?? formik.values.client?.hs_object_id;
		const contactId =
      formik.values.contact?.id ?? formik.values.contact?.hs_object_id;

		const pipeline = formik.values.deal?.pipeline;
		const type = formik.values.deal?.objectType ?? objectType;
		const taskData =
      dialogData?.task?.task ?? dialogData?.task?.engagement ?? {};

		// 	const updatedTitle =
		//   formik.values.taskType === 'MEETING' || formik.values.taskType === 'CALL'
		//   	? `${formik.values.title} (${formatTimeToHourMinutes(
		//   		formik.values.startTime
		//   	)} - ${formatTimeToHourMinutes(formik.values.endTime)})`
		//   	: formik.values.title;

		const body = {
			title: formik.values.title,
			dueDate: getUnixTime(formik.values.dueDate) * 1000,
			priority: formik.values.priority,
			notes: formik.values.notes ?? '',
			hubspotId: formik.values.ownerId,
			type: formik.values.taskType,
			...(type && { objectType: type }),
			...(dealId && { dealId }),
			...(clientId && { clientId }),
			...(contactId && { contactId }),
			...(graphId && { graphId }),
		};

		console.log('📋 ~ Create task object', body);

		if (!formik.values.isExistingTask) {
			const response = await createTask(body).unwrap();
			return { response, body: { ...body, pipeline } };
		} else {
			const res = await updateTask({
				taskId: taskData?.id,
				selectedDeal: formik?.values?.deal,
				clientId: clientId,
				existingClientId: taskData?.client?.hs_object_id,
				existingDeal:
          taskData?.deal?.properties ??
          taskData?.data?.deal?.properties ??
          taskData?.ticket?.properties ??
          taskData?.data?.ticket?.properties,
				objectType: formik?.values?.deal?.objectType,
				properties: {
					hs_task_subject: formik.values.title,
					hs_task_body: formik.values.notes,
					hubspot_owner_id: formik.values.ownerId,
					hs_timestamp: getUnixTime(formik.values.dueDate) * 1000,
					hs_task_priority: formik.values.priority.toUpperCase(),
					hs_task_type: formik.values.taskType.toUpperCase(),
				},
			}).unwrap();
			return { res, body };
		}
	};

	const formik = useFormik({
		initialValues: {
			...initialValues,
			...((objectType === 'deal' || objectType === 'ticket') &&
        deal && { deal }),
			...(objectType === 'client' && client && { client }),
			...(objectType === 'contact' && contact && { contact }),
			...(objectType && { objectType }),
		},
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);

			const endTime = formik.values.endTime
				? formik.values.endTime.toISOString()
				: getDefaultDateTime(formik.values.dueDate).toISOString();
			const startTime = formik.values.startTime
				? formik.values.startTime.toISOString()
				: getDefaultDateTime(formik.values.dueDate).toISOString();
			const taskWithClient =
        formik.values?.client?.name?.length > 0 || client?.name
        	? `${client?.name ?? formik.values?.client?.name} - ${
        		formik.values.title
        	}`
        	: formik.values.title;

			const eventBody = {
				subject: taskWithClient,
				body: {
					contentType: 'HTML',
					content: formik.values.notes ?? '',
				},
				start: {
					dateTime: startTime,
					timeZone: 'New Zealand Standard Time',
				},
				end: {
					dateTime: endTime,
					timeZone: 'New Zealand Standard Time',
				},
				location: {
					displayName: formik.values.address,
				},
				// attendees: formik.values.attendees.map((contact) => ({
				// 	emailAddress: {
				// 		address: contact?.contactEmail ?? contact?.email ?? contact?.emailAddress,
				// 		name: contact?.contactEmail
				// 			? contact.contactName
				// 			: `${contact.firstName} ${
				// 				contact?.lastName ?? contact?.name ?? ''
				// 			}`,
				// 	},
				// 	type: 'required',
				// })),
				attendees: formik.values.attendees.map((contact) => ({
					emailAddress: {
						address:
              contact?.contactEmail || contact?.email || contact?.emailAddress,
						name:
              contact?.contactName ||
              `${contact?.firstName || ''} ${
              	contact?.lastName || contact?.name || ''
              }`.trim(),
					},
				})),

				isOnlineMeeting: formik.values.isOnlineMeeting || false,
			};

			try {
				if (formik.values.isExistingTask === true) {
					console.log('Editing task!');

					const updatedEventRes = await updateGraphEventData(
						eventTaskMsData?.taskType,
						eventTaskMsData?.id,
						eventBody
					);
					console.log('Updated event response: ', updatedEventRes);
					const res = await handleTaskSubmission();
					console.log('here is the update task res: ', res);
					//set deal or client id to null if no client or deal selected
					// Update the task
					if (res) {
						console.log('🚀 ~ Successfully updated task', res);
						const taskId = res.response?.id;

						// Navigate or show success message as before
						const path = window.location.pathname;
						let newPath, params;
						if (deal && client) {
							const dealPipeline = deal?.pipeline ?? deal?.properties?.hs_pipeline ?? deal?.properties?.pipeline;
							const pathName = getUrlFromPipeline(dealPipeline);
							newPath = `${pathName}/${deal?.id}`;
							params = { state: { task: taskId } };
						} else if (client && !deal) {
							newPath = `/clients/${client?.hs_object_id}`;
							params = { state: { task: taskId } };
						} 

						dispatch(
							showToast({
								message: 'Task updated successfully!',
								autohide: false,
								...(path.startsWith('/my-tasks') && {
									autohide: false,
									action: {
										path: newPath,
										params,
										label: 'GO TO TASK',
									},
								}),
							})
						);
						resetForm();
						handleClose();
					} else {
						console.log('🙅 ~ Error updating task');
						setFieldValue(
							'errorMessage',
							'Error updating task. Please try again'
						);
					}
				} else {
					console.log('Creating task!');

					let graphEventId;
					if (formik.values.taskType === 'MEETING') {
						const meetingEventRes = await createOnlineMeetingEvent(eventBody);
						console.log('Meeting event response: ', meetingEventRes);
						graphEventId = meetingEventRes?.id;
					} else {
						const toDoEventRes = await createEventTask(eventBody);
						console.log('Event to-do response: ', toDoEventRes);
						graphEventId = toDoEventRes?.id;
					}

					const updatedTaskType =
            formik.values.taskType === 'MEETING'
            	? 'TODO'
            	: formik.values.taskType;
					formik.setFieldValue('taskType', updatedTaskType);

					const res = await handleTaskSubmission(graphEventId);
					if (res) {
						console.log('🚀 ~ Successfully created task', res);
						const taskId = res.response?.id;
						const path = window.location.pathname;
						const { clientId, dealId, pipeline } = res.body;
						let newPath, params;
						if (dealId) {
							const pathName = getUrlFromPipeline(pipeline);
							newPath = `${pathName}/${dealId}`;
							params = { state: { task: taskId } };
						} else if (clientId) {
							newPath = `/clients/${clientId}`;
							params = { state: { task: taskId } };
						}

						dispatch(
							showToast({
								message: 'New task created!',
								autohide: false,
								...(path.startsWith('/my-tasks') && {
									autohide: false,
									action: {
										path: newPath,
										params,
										label: 'GO TO TASK',
									},
								}),
							})
						);
						resetForm();
						handleClose();
					} else {
						console.log('🙅 ~ Error creating task');
						setFieldValue(
							'errorMessage',
							'Error creating task. Please try again'
						);
					}
				}
			} catch (err) {
				console.log('🙅 ~ Error occurred', err);
				setFieldValue('errorMessage', 'An error occurred. Please try again');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);

	// useEffect(() => {
	// 	console.log('here is a formik value: ', formik);
	// 	console.log('event data: ', eventTaskMsData);
	// }, [formik, eventTaskMsData]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			disableEnforceFocus
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>
					{formik.values.isExistingTask ? 'Update Task' : 'Create Task'}
				</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						<Grid size={6}>
							<FormLabel>{'Task Type'}</FormLabel>
							<Select
								name="taskType"
								value={formik.values.taskType}
								disabled={formik.isSubmitting}
								onChange={(e) => {
									const selectedValue = e.target.value;
									formik.setFieldValue('taskType', selectedValue);
								}}
								fullWidth={true}
								renderValue={(value) => (
									<Box display="flex" alignItems="center">
										{value === 'CALL' ? (
											<PhoneIcon fontSize="small" />
										) : value === 'EMAIL' ? (
											<EmailIcon fontSize="small" />
										) : value === 'MEETING' ? (
											<GroupsIcon fontSize="small" />
										) : (
											<ChecklistIcon fontSize="small" />
										)}
										<Typography
											sx={{ paddingLeft: '0.5em' }}
											variant="body1"
											noWrap
										>
											{value}
										</Typography>
									</Box>
								)}
							>
								{taskTypes.map((value) => {
									return (
										<MenuItem
											value={value}
											key={`task-type-selection-${value}`}
										>
											<ListItemIcon>
												{value === 'CALL' ? (
													<PhoneIcon size="small" />
												) : value === 'EMAIL' ? (
													<EmailIcon size="small" />
												) : value === 'MEETING' ? (
													<GroupsIcon size="small" />
												) : (
													<ChecklistIcon size="small" />
												)}
											</ListItemIcon>

											<Typography variant="body1" noWrap>
												{value}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</Grid>
						<Grid size={6}>
							<PrioritySelect
								disabled={formik.isSubmitting}
								priority={formik.values.priority}
								setPriority={(value) => formik.setFieldValue('priority', value)}
							/>
						</Grid>
						<Grid size={6}>
							<FormLabel required>{'Date'}</FormLabel>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									name="dueDate"
									disablePast={true}
									disabled={formik.isSubmitting}
									format="dd/MM/yyyy"
									value={formik.values.dueDate}
									sx={{ width: '100%' }}
									onChange={(value) => {
										const newDate =
                      !isValid(value) || isAfter(minDate, value)
                      	? minDate
                      	: value;
										formik.setFieldValue('dueDate', newDate);
									}}
								/>
							</LocalizationProvider>
						</Grid>
						{(formik.values.taskType === 'MEETING' ||
              formik.values.taskType === 'CALL') && (
							<>
								<Grid size={3}>
									<FormLabel required>{'Start Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.startTime}
											onChange={(value) => {
												const dueDate = new Date(formik.values.dueDate);
												const newStartTime = new Date(dueDate);

												if (value) {
													newStartTime.setHours(
														value.getHours(),
														value.getMinutes(),
														value.getSeconds(),
														value.getMilliseconds()
													);
													formik.setFieldValue('startTime', newStartTime);
													const newEndTime = new Date(
														newStartTime.getTime() + 30 * 60 * 1000
													);
													formik.setFieldValue('endTime', newEndTime);
												}
											}}
										/>
									</LocalizationProvider>
									{formik.touched.startTime && formik.errors.startTime && (
										<FormHelperText error>
											{formik.errors.startTime}
										</FormHelperText>
									)}
								</Grid>
								<Grid size={3}>
									<FormLabel required>{'End Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.endTime}
											onChange={(value) => {
												const dueDate = new Date(formik.values.dueDate);
												const newEndTime = new Date(dueDate);
												newEndTime.setHours(
													value.getHours(),
													value.getMinutes(),
													value.getSeconds(),
													value.getMilliseconds()
												);
												formik.setFieldValue('endTime', newEndTime);
											}}
										/>
									</LocalizationProvider>
									{formik.touched.endTime && formik.errors.endTime && (
										<FormHelperText error>
											{formik.errors.endTime}
										</FormHelperText>
									)}
								</Grid>
							</>
						)}
						{formik.values.taskType === 'MEETING' && (
							<>
								{' '}
								<Grid size={12}>
									<FormLabel>{'Attendees'}</FormLabel>
									<InstantClientSelect
										// clientId={formik.values.client?.id}
										// clientName={formik.values[CLIENT_NAME]}
										showContact={true}
										attendees={formik.values.attendees}
										setClient={(contacts) => {
											if (contacts) {
												console.log('here is the selected client: ', contacts);
												formik.setFieldValue('attendees', contacts);
											}
										}}
									/>
								</Grid>
								<Grid size={12}>
									<FormLabel>{'Meeting type'}</FormLabel>
									<ToggleButtonGroup
										color="primary"
										value={formik.values.isOnlineMeeting}
										size="small"
										exclusive
										onChange={handleChange}
										sx={{
											marginLeft: 2,
											boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
											'& .MuiToggleButtonGroup-grouped': {
												border: 'none',
												margin: '4px',
												padding: '3px 12px',
												'&:not(:first-of-type)': {
													borderRadius: '5px',
												},
												'&:first-of-type': {
													borderRadius: '5px',
												},
												color: 'rgba(189, 189, 189, 1)',
												backgroundColor: 'transparent',
												'&.Mui-selected': {
													color: 'rgba(80, 90, 252, 1)',
													backgroundColor: 'rgba(80, 90, 252, 0.33)',
												},
												'&:hover': {
													backgroundColor: 'rgba(80, 90, 252, 0.1)',
												},
											},
										}}
									>
										<ToggleButton value={false}>In person</ToggleButton>
										<ToggleButton value={true}>Online</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
								{formik.values.isOnlineMeeting === false && (
									<Grid size={12}>
										<FormLabel required>{'Location'}</FormLabel>
										<AddressFields
											formik={formik}
											minimal={true}
											loading={formik.isSubmitting}
										/>
										{/* <TextField
											fullWidth={true}
											name="location"
											{...formik.getFieldProps('location')}
											disabled={formik.isSubmitting}
										/> */}
										{formik.touched.address && formik.errors.address && (
											<FormHelperText error>
												{formik.errors.address}
											</FormHelperText>
										)}
									</Grid>
								)}
							</>
						)}
						<Grid size={12}>
							<FormLabel required>{'Title'}</FormLabel>
							<TextField
								fullWidth={true}
								name="title"
								{...formik.getFieldProps('title')}
								error={formik.touched.title && Boolean(formik.errors.title)}
								helperText={formik.touched.title && formik.errors.title}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
						{((!deal && !client) ||
              Object.keys(eventTaskMsData ?? {}).length > 0 ||
              objectType === 'client') && (
							<Grid size={12}>
								<FormLabel>{'Associated client'}</FormLabel>
								<InstantClientSelect
									clientId={
										formik.values.client?.hs_object_id ??
                    formik.values.client?.id
									}
									disabled={formik.isSubmitting}
									showContact={false}
									prepopulatedClientName={formik.values?.client?.name}
									setClient={(newClient) => {
										formik.setFieldValue('client', newClient);
										console.log('NEW CLIENT', newClient);
										if (newClient) {
											formik.setFieldValue('deal', null);
										}
									}}
								/>
							</Grid>
						)}

						{((!deal && !client) ||
              objectType === 'client' ||
              Object.keys(eventTaskMsData ?? {}).length > 0) && (
							<Grid size={12}>
								<FormLabel>{'Associated deal or claim'}</FormLabel>
								<InstantDealSelect
									disabled={formik.isSubmitting}
									clientId={
										formik.values.client?.hs_object_id ??
                    formik.values.client?.id
									}
									dealId={formik.values.deal?.id}
									setDeal={(newDeal) => {
										formik.setFieldValue('deal', newDeal);
										if (newDeal) {
											formik.setFieldValue('objectType', newDeal.objectType);
											// formik.setFieldValue('client', null);
										}
									}}
									autoPopulate={true}
								/>
							</Grid>
						)}
						<Grid size={12}>
							<FormLabel>{'Assignee'}</FormLabel>
							<OwnerSelect
								initialId={formik.values.ownerId}
								disabled={formik.isSubmitting}
								onChange={(value) => {
									formik.setFieldValue('ownerId', value);
									const owner =
                    brokers?.data?.filter((b) => b.id === value)[0] ?? null;
									const updatedAttendees = [...formik.values.attendees];
									if (owner && !updatedAttendees.includes(owner)) {
										updatedAttendees.push(owner);
									}
									formik.setFieldValue('attendees', updatedAttendees);
								}}
								showEmail={true}
							/>
						</Grid>
						<Grid size={12}>
							<FormLabel>{'Task Info'}</FormLabel>
							<TextField
								fullWidth={true}
								name="notes"
								multiline
								rows={4}
								{...formik.getFieldProps('notes')}
								// error={formik.touched.notes && Boolean(formik.errors.notes)}
								// helperText={formik.touched.notes && formik.errors.notes}
								disabled={formik.isSubmitting}
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <pre>{JSON.stringify(formik.values, undefined, 2)}</pre> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={isLoading || formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : formik.values.isExistingTask ? (
							'Update'
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
