/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Card,
	Stack,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Skeleton,
} from '@mui/material';
import {
	folioBlue,
	formatDate,
} from '@/utils/constants';
import { theme } from '@/app/theme';
import { CollapsibleTaskGroup } from '@/components/CollapsibleTaskGroup';
import { DealCardAdviserFee } from '@/components/cards/DealCardValues/DealCardAdviserFee';
import { DealCardDateSelect } from '@/components/cards/DealCardValues/DealCardDateSelect';
import { DealCardStageChange } from '@/components/cards/DealCardValues/DealCardStageChange';
import { ClaimNumberField } from '@/components/ClaimNumberField';
import { AssociatedClient } from '@/components/cards/DealCardValues/AssociatedClient';
import { determineContext } from '@/hooks/determineContext';
import { NavigateInsightButton } from '@/components/buttons/NavigateInsightButton';
import { ClaimTypeSelectWrapper } from '@/components/selects/ClaimTypeSelect';
import { ExpandMore } from '@mui/icons-material';
import { ComplianceTasksDisplay } from '@/components/ComplianceTasksDisplay';
import { openDialog } from '@/features/dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { ChangeAssignee } from '@/components/cards/DealCardValues/ChangeAssigneeField';
import { StatementOfAdviceButton } from '@/components/buttons/StatementOfAdviceButton';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

const DealAboutAccordion = ({ title, children }) => {
	const [expanded, setExpanded] = useState(true);

	return (
		<Accordion
			elevation={0}
			disableGutters
			square
			expanded={expanded}
			onChange={() => setExpanded((prev) => !prev)}
			sx={{
				backgroundColor: 'transparent',
				width: '100%',
				'&:not(:last-child)': {
					borderBottom: 0,
				},
				'&::before': {
					display: 'none',
				},
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMore sx={{ color: folioBlue }} />}
				sx={{
					borderBottom: expanded ? '1px solid #d3d3d3' : 'none',
					padding: 0,
					marginBottom: '1em',
				}}
			>
				<Typography variant='form_label'>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: '0px' }}>{children}</AccordionDetails>
		</Accordion>
	);
};

export const DealAboutCard = () => {
	const dispatch = useDispatch();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'statementOfAdvice' });
	const showSOA = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const { deal, objectType, pipelines, isProspect, client, policies, loading } =
		determineContext();

	const isDeal = objectType === 'deal';

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const pipelineName =
		pipelines?.label === 'New Business'
			? 'New Business'
			: (pipelines?.label ?? '').slice(0, -1);

	const dealStage = useMemo(() => {
		const stageId = deal?.dealstage ?? deal?.hs_pipeline_stage;
		const stage = pipelines?.stages?.find((stage) => stage.id === stageId);
		return stage;
	}, [pipelines, deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const isDisabled = useMemo(
		() =>
			dealStage?.metadata?.probability?.toString() === '1.0' ||
            dealStage?.metadata?.ticketState === 'CLOSED',
		[dealStage]
	);

	// const closed = useMemo(() => {
	// 	return closedPipelineStages.includes(deal?.dealstage ?? deal?.hs_pipeline_stage);
	// }, [deal]);

	// const beforeInTerm = useMemo(() => {
	// 	return pipelineStagesBeforeInterm.includes(dealstageId);
	// }, [dealstageId]);

	const isEndorsement =
		!isDeal && deal?.hs_pipeline == process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	const isNewBusiness = deal?.pipeline == process.env.REACT_APP_PIPELINE_NEW_BUSINESS;

	const isClaim = deal?.hs_pipeline == process.env.REACT_APP_PIPELINE_CLAIMS;
    
	const dealCardValues = [
		<ChangeAssignee key={'deal-card-value-1'}/>,
		<DealCardAdviserFee key={'deal-card-value-2'} />,
		// <DealCardStageChange key={'deal-card-value-3'} />,
		<DealCardDateSelect
			key={'deal-card-value-4'}
			title={'Renewal date'}
			dealKey={'renewal_date'}
		/>,
	];

	const ticketCardValues = [
		// <DealCardPrioritySelect key={'ticket-card-value-1'} />,
		// <DealCardStageChange
		// 	key={'ticket-card-value-2'}
		// 	{...(!isEndorsement && { title: 'Claim Status' })}
		// />,
		<ChangeAssignee key={'ticket-card-value-1'}/>,
		...(!isEndorsement
			? [
				<DealCardDateSelect
					key={'ticket-card-value-3'}
					title={'Date of Loss'}
					dealKey={'date_of_loss'}
					disableFuture={true}
				/>,
				<>
					<Typography sx={minimalLabelTheme}>Claim Number</Typography>
					<ClaimNumberField />
				</>,
				<>
					<Typography sx={minimalLabelTheme}>Insurer</Typography>
					<Typography>
						{deal?.insurer ?? policies?.[0]?.InsurerName ?? 'Unknown'}
					</Typography>
				</>,
				<ClaimTypeSelectWrapper key={'ticket-card-value-claim-type'} minimal={true} />,
			  ]
			: []),
		<>
			<Typography sx={minimalLabelTheme}>
				{isEndorsement === true ? 'Created date ' : 'Open date'}
			</Typography>
			<Typography>{formatDate(deal?.createdate, true)}</Typography>
		</>,
	];

	return (
		<Card sx={{ px: 3, py: 2, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
			<Stack direction='column' spacing={2}>
				<Stack spacing={1}>
					<Typography variant='form_label'> Actions </Typography>
					{loading?.deal || loading?.client ? (
						<Skeleton height='3em' />
					) : (
						<NavigateInsightButton
							ledgerName={client?.broker?.ledger}
							insightId={client?.clientRow?.insightId}
						/>
					)}
					{showSOA && isNewBusiness && !isProspect && <>
						{loading?.deal || loading?.client ? (
							<Skeleton height='3em' />
						) : (
							<StatementOfAdviceButton />
						)}</>}
					{!isDisabled && (
						<>
							{loading?.deal || loading?.client ? (
								<Skeleton height='3em' />
							) : (
								<Button
									sx={{ borderRadius: '10px' }}
									variant='contained'
									onClick={() => dispatch(openDialog('completeDeal'))}
								>
									{`Complete ${pipelineName}`}
								</Button>
							)}
						</>
					)}
				</Stack>
				<DealAboutAccordion title='Compliance'>
					<Stack direction='column' spacing={3}>
						{/* Conditionally render DealCardStageChange */}
						<DealCardStageChange
							minimal={true}
							{...(!isEndorsement && {
								title: isDeal ? 'Deal Stage' : 'Claim Status',
							})}
							{...(isClaim && { showReopen: true })}
						/>
						<ComplianceTasksDisplay />
					</Stack>
				</DealAboutAccordion>
				<DealAboutAccordion title='About'>
					<AssociatedClient />
					<Stack direction='column' marginTop={3} spacing={3}>
						{(isDeal ? dealCardValues : ticketCardValues).map((e, i) => (
							<Stack spacing={1} key={`${objectType}-card-about-values-${i}`}>
								{e}
							</Stack>
						))}
					</Stack>
				</DealAboutAccordion>
				<CollapsibleTaskGroup />
			</Stack>
		</Card>
	);
};
