import { DragDropContext } from '@hello-pangea/dnd';
import { Box, List, Stack, Typography } from '@mui/material';
import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import KanbanContext from '@/context/kanbanContext';
import { ticketsApi, useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { dealsApi, useUpdateDealPropertiesMutation } from '@/features/deals/dealsApi';
import { IS_DEV } from '@/utils/environment';
import { firestore } from '@/utils/firebase';
import { KanbanColumn } from '../KanbanColumn';
import { engagementsApi } from '@/features/engagements/engagementsApi';

export const KanbanContent = () => {
	const dispatch = useDispatch();

	const { 
		pipelineId, 
		stages, 
		isClaims, 
		isEndorsement,
		dealStages,
		shiftDeal,
	} = useContext(KanbanContext);

	const isDeal = !(isClaims || (IS_DEV && isEndorsement));

	const [
		updateDealProperties, 
		{ isLoading }
	] = isClaims || (IS_DEV && isEndorsement) 
		? useUpdateTicketPropertiesMutation() 
		: useUpdateDealPropertiesMutation();

	const onDragEnd = async (result) => {
		if (isLoading) {
			return;
		}
		const { destination, source, draggableId } = result;
		if (!destination || 
                (destination.droppableId === source.droppableId 
                    && destination.index === source.index)) {
			return;
		}

		const deal = dealStages[source.droppableId].find(d => d.id == draggableId);
		shiftDeal(
			source.droppableId, 
			destination.droppableId,
			source.index,
			destination.index,
			deal
		);

		const params = {
			[isDeal ? 'dealstage' : 'hs_pipeline_stage']: destination.droppableId,
		};
		await updateDealProperties({
			[isDeal ? 'dealId' : 'ticketId']: draggableId,
			body: params,
		}).unwrap();

		try {
			const docRef = doc(firestore, 'deals', deal.docId);
			await updateDoc(docRef, { dealStage: destination.droppableId }).then(res => console.log(`Successfully updated doc ${deal.docId}`));
		} catch (error) {
			console.log('Error updating Firebase document', deal, error);
		}
        
		const objectType = isDeal ? 'Deal' : 'Ticket';

		console.log('SOURCE', source.droppableId, 'DEST', destination.droppableId);
		setTimeout(async () => {
			try {
				const type = (objectType ?? '').toUpperCase();
				dispatch((isDeal ? dealsApi : ticketsApi).util.invalidateTags([
					{ type: `${type}S`, id: 'LIST' },
					{ type: `${type}S`, id: source.droppableId },
					{ type: `${type}S`, id: destination.droppableId },
					{ type, id: draggableId },
				]));
				dispatch(engagementsApi.util.invalidateTags([
					{ type: 'ENGAGEMENTS', id: draggableId },
					{ type: 'TASKS', id: 'LIST' },
				]));
			} catch (error) {
				console.error('Error invalidating dealsAPI:', error);
			}
		}, 12000);
	};

	return (
		<List
			component="div" sx={{ overflowX: 'auto', height: '60vh' }}
		>
			<DragDropContext onDragEnd={onDragEnd}>
				<Box sx={{ 
					display: 'flex', 
					width: 'max-content', 
					// height: '100%',
					bgcolor: '#f5f8fa',
				}}>
					{stages?.map(stage => (
						<KanbanColumn
							stage={stage}
							key={stage.id}
						/>
					))}
				</Box>
			</DragDropContext>
		</List>);
};