import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { AttachmentsTable } from '@/components/tables/AttachmentsTable';
import { determineContext } from '@/hooks/determineContext';
import { AttachmentsContextProvider } from '@/context/attachmentsContext';

export const AttachmentsTab = () => {
	
	const { deal, client } = determineContext();
	
	return (
		<AttachmentsContextProvider
			deal={deal}
			client={client}
		>
			<AttachmentsTable 
				editableView={true} 
				canDownload={true}
			/>
		</AttachmentsContextProvider>
	);
};
