import { LOGIN_TYPE } from '@/app/authConfig';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';

export const useMSALHelper = () => {
	// Using MSAL hook to get auth instance and accounts
	const { instance } = useMsal();
	const account = useSelector((state) => state.msalAccount.account);

	const getAccessToken = async (scopes) => {
		const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		const request = {
			scopes,
			account: currentAccount,
		};
		return await instance
			.acquireTokenSilent(request)
			.then(async (response) => response.accessToken)
			.catch(async (error) => {
				console.error('🙅 ~ [getMSAccessToken] Error retrieving access token silently', error);
				// Redirect user to login screen if there is an issue getting access token
				if (LOGIN_TYPE === 'popup') {
					return await instance
						.acquireTokenPopup(request)
						.then(response => response.accessToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token popup error', e);
							return Promise.reject(e);
						});
				} else {
					return await instance
						.acquireTokenRedirect(request)
						.then(response => response.accessToken)
						.catch((e) => {
							console.log('🙅 ~ [getMSAccessToken] Token redirect error', e);
							return Promise.reject(e);
						});
				}
			});
	};

	const handleLogout = () => {
		const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		if (LOGIN_TYPE === 'popup') {
			instance
				.logoutPopup({
					postLogoutRedirectUri: '/',
					mainWindowRedirectUri: '/',
					account: currentAccount,
				})
				.then(() => {
					localStorage.clear();
					sessionStorage.clear();
				});
		} else {
			instance
				.logoutRedirect({
					postLogoutRedirectUri: '/',
					account: currentAccount
				})
				.then(() => {
					localStorage.clear();
					sessionStorage.clear();
				});
		}
	};

	return {
		getAccessToken,
		handleLogout,
	};
};
