import { TextField } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isAfter, isBefore, isToday, isValid, parseISO } from 'date-fns';

const today = new Date();

const DataGridYearPicker = (props) => {
	const { id, field, value, colDef, hasFocus } = props;
	const [valueState, setValueState] = useState(() =>
		(value ?? '').length > 0 ? parseISO(value) : null
	);
	const [inputRef, setInputRef] = useState(null);
	const apiRef = useGridApiContext();

	useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleChange = useCallback(
		(newValue) => {
			console.log('VAL', newValue);
			if (isValid(newValue) && (isBefore(newValue, today) || isToday(newValue))) {
				const newYear = newValue.getFullYear();

				setValueState(newValue);
				apiRef.current.setEditCellValue({
					id,
					field,
					value: newYear.toString(),
					debounceMs: 200,
				});
			}
		},
		[apiRef, field, id]
	);

	const handleClear = useCallback(() => {
		setValueState(null);

		apiRef.current.setEditCellValue({ id, field, value: '', debounceMs: 200 });
	}, [apiRef, field, id]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				value={valueState}
				maxDate={today}
				format={'yyyy'}
				openTo='year'
				views={['year']}
				yearsOrder='desc'
				inputRef={(ref) => setInputRef(ref)}
				onChange={handleChange}
				slotProps={{
					field: { clearable: true, onClear: () => handleClear() },
				}}
			/>
		</LocalizationProvider>
	);
};

export default DataGridYearPicker;
