import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Skeleton } from '@mui/material';
import { theme } from '@/app/theme';
import { useGetBrokerOwnersQuery } from '@/features/user/ownerApi';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const OwnerSelect = ({ initialId, disabled, onChange, minimal }) => {

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const hubspotId = userQuery.data?.hubspotId;

	// Set selectedOwner based on initialId, or fall back to hubspotId
	const [selectedOwner, setSelectedOwner] = useState(initialId || hubspotId);

	const ownerQuery = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});

	const owners = (ownerQuery.data || []).reduce((acc, current) => {
		if (!acc.find(item => item.id === current.id)) {
			acc.push(current);
		}
		return acc;
	}, []);

	useEffect(() => {
		if (initialId !== selectedOwner) {
			setSelectedOwner(initialId);
		}
	}, [initialId]);
	useEffect(() => {
		if (onChange && selectedOwner !== initialId) {
			onChange(selectedOwner);
		}
	}, [selectedOwner]);

	const handleSelectionChange = (event) => {
		setSelectedOwner(event.target.value);
	};

	const minimalInputTheme = minimal ? theme.components.MuiInputBase.styleOverrides.minimal : {};

	return ownerQuery.isLoading || ownerQuery.isUninitialized ? (
		<Skeleton height="85%" width="100%" animation="wave" />
	) : (
		<Select
			className={minimal ? 'minimal-input' : ''}
			value={selectedOwner || ''}
			size={minimal ? 'small' : 'medium'}
			disabled={disabled}
			onChange={handleSelectionChange}
			displayEmpty
			fullWidth
			variant={minimal ? 'standard' : 'outlined'}
			sx={{
				...(minimal && {
					...minimalInputTheme,
					'& .Mui-disabled': {
						backgroundColor: 'transparent',
					},
				}),
			}}
		>
			{owners.map((option) => (
				<MenuItem value={option.id} key={`owner-selection-${option.id}`}>
					{option.firstName ? `${option.firstName} ${option.lastName}` : option.email}
				</MenuItem>
			))}
		</Select>
	);
};
