import { ReactComponent as NewBusinessIconSVG } from '@/assets/icons/New-Business.svg';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const NewBusinessIcon = ({ bgColor = '#CFEEDD', width='24px', height='24px', ...props }) => {
	return (
		<SvgIcon
			{...props}
			sx={{
				backgroundColor: bgColor, 
				width: width,
				height: height,
				borderRadius: 2
			}}
		>
			<NewBusinessIconSVG />
		</SvgIcon>
	);
};
