import React, { useEffect, useMemo } from 'react';
import { Box, FormLabel, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import { useRefinementList } from 'react-instantsearch';
import { theme } from '@/app/theme';
import { folioBlue } from '@/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '@/features/table/instantSearchSlice';

export const ClientTypeRefinementListSelect = ({
	title,
	disabled,
	width = '150px',
	prospectFilter,
	setProspectFilter,
	...props
}) => {
	// const dispatch = useDispatch();

	// const { filters } = useSelector(state => state.instantSearch);

	// const currentFilters = filters[props.attribute] ?? [];

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const refactoredItems = useMemo(() => {
		return [
			{
				label: 'Prospect',
				isRefined: prospectFilter.includes('isProspect:= true'),
				value: 'isProspect:= true',
			},
			{
				label: 'Client',
				isRefined: prospectFilter.includes('isProspect:!= true'),
				value: 'isProspect:!= true',
			},
		];
	}, [prospectFilter]);

	const handleChange = (value) => {
		// const values = event.target.value;
		// console.log('NEW SELECTION', values);
		// const value = typeof values === 'string' ? values : values.value;
		if (prospectFilter.includes(value)) {
			setProspectFilter('');
		} else {
			setProspectFilter(value);
		}
	};

	const chosenValue = useMemo(() => refactoredItems.find((i) => i.isRefined)?.value ?? '');

	return (
		<Box>
			<FormLabel sx={{ minimalLabelTheme, color: folioBlue }}>{title}</FormLabel>
			<Box width={width}>
				<Select
					variant='standard'
					fullWidth={true}
					disabled={disabled}
					value={chosenValue}
					renderValue={(selected) =>
						refactoredItems.find((i) => i.value === selected)?.label ?? ''
					}
				>
					{refactoredItems.map((item) => {
						return (
							<MenuItem key={`${title}-select-${item.label}`} value={item.value} onClick={() => handleChange(item.value)}>
								<ListItemText primary={item.label} />
							</MenuItem>
						);
					})}
				</Select>
			</Box>
		</Box>
	);
};
