import React, { useMemo } from 'react';
import { setFilters } from '@/features/table/instantSearchSlice';
import { Chip, Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getComparator, stableSort } from '@/components/table/functions';
import { getPipelineFromSlug } from '@/utils/constants';

const RefinementListChips = ({ attribute, title, index }) => {
	const dispatch = useDispatch();
	const { filters } = useSelector((state) => state.instantSearch);

	const currentFilters = filters[attribute] ?? [];

	const pipeline = useMemo(() => {
		const pathnames = window.location.pathname.split('/');
		const slug = pathnames[pathnames.length - 1];
		return getPipelineFromSlug(`/${slug}`);
	}, [window.location.pathname]);

	const pipelineQuery = useGetPipelinePropertiesQuery(pipeline, {
		skip: !pipeline,
	});

	const stages = pipelineQuery?.data?.pipelines?.stages ?? [];

	const refactoredItems = useMemo(() => {
		// console.log('ITEMS', items);
		const itemsWithStages = currentFilters.map((item) => {
			const stage = stages.find((s) => s.id == item);
			return {
				item,
				label: item,
				...(stage && { label: stage.label, order: stage.displayOrder }),
			};
		});
		return itemsWithStages;
		// return stableSort(itemsWithStages, getComparator('asc', Object.keys(stages).length > 0 ? 'order' : 'label'));
	}, [currentFilters, stages]);

	const handleDelete = (item) => {
		const multi = currentFilters.includes(item)
			? [...currentFilters.slice().filter((v) => v != item)]
			: [...currentFilters.slice(), item];
		dispatch(setFilters({ type: attribute, values: multi.map((i) => i) }));
	};

	const colour = ['primary', 'secondary', 'success', 'warning', 'info', 'error'][index ?? 0];

	return (
		<>
			{refactoredItems.map((i) => (
				<Chip
					size={'small'}
					color={colour}
					// sx={{
					//     backgroundColor: colour,
					//     color: 'rgb(255, 255, 255)'
					// }}
					label={
						<Tooltip title={`"${i.label}" (${title})`}>
							<Stack direction='row' fontSize={'12px'} alignItems='center'>
								<span
									style={{
										flexGrow: 1,
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{i.label}
								</span>
								<span
									style={{
										fontStyle: 'italic',
										fontSize: '11px',
										paddingLeft: '0.25em',
									}}
								>
									({title})
								</span>
							</Stack>
						</Tooltip>
					}
					onDelete={() => handleDelete(i.item)}
					key={`${attribute}-${i.label}-${i.item}`}
				/>
			))}
		</>
	);
};

export default RefinementListChips;
