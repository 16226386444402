import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { BrokerSelect } from '@/components/selects/BrokerSelect';
import { TeamViewToggle } from '@/components/buttons/TeamViewToggle';

export const PageHeader = ({ title, showGroupView, showBrokerSelect, icon, ...props }) => {
	const account = useSelector((state) => state.msalAccount.account);
	
	const email = account?.username;
	const { data: userDetails } = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
    
	return <Grid container justifyContent="space-between" alignItems="center" {...props}>
		<Grid>
			<Stack direction='row' alignItems='center' width='100%' spacing={1}>
				{icon && icon}
				<Typography variant="h4" style={{ fontWeight: 'bold' }}>
					{title}
				</Typography>
			</Stack>
			{/* <Typography variant="h4" style={{ fontWeight: 'bold' }}>
				{title}
			</Typography> */}
		</Grid>
		{(showGroupView || showBrokerSelect) && userDetails?.canViewGroup && <Grid>
			{showGroupView ? <TeamViewToggle /> : <BrokerSelect />}
		</Grid>}
	</Grid>;
};