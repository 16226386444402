import React, { useEffect } from 'react';
import { TextField, FormHelperText, FormLabel, Tooltip, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { determineContext } from '@/hooks/determineContext';
import { DealCardStageChange } from '../cards/DealCardValues/DealCardStageChange';
import { DealCardDateSelect } from '../cards/DealCardValues/DealCardDateSelect';
import { ResetButton } from '../ClientDetailEntry/resetButton';
import { ClaimNumberField } from '../ClaimNumberField';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ClaimTypeSelectWrapper } from '../selects/ClaimTypeSelect';
import { ticketsApi, useUpdateInsightClaimMutation, useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { formatDateDayToYear } from '@/utils/constants';
import { DrawNumeric } from '../ClientDetailEntry/drawNumeric';
import { NumericFormat } from 'react-number-format';

export const ClaimDetailField = ({ header, title, readOnly }) => {
	const { claim, deal } = determineContext();

	const dispatch = useDispatch();

	// const invalidateTags = async () => {
	// 	dispatch(setLoading(true));
	// 	setTimeout(async () => {
	// 		try {
	// 			dispatch(
	// 				ticketsApi.util.invalidateTags([
	// 					{ type: 'TICKET', id: 'LIST' },
	// 					{ type: 'TICKET', id: deal?.hs_pipeline_stage ?? deal?.dealstage },
	// 					{ type: 'TICKET', id: deal.hs_object_id ?? deal.id },
	// 				])
	// 			);
				
	// 		} catch (error) {
	// 			console.error('Error invalidating engagements API:', error);
	// 		} finally {
	// 			dispatch(setLoading(false));
	// 		}
	// 	}, 10000);
	// };

	const [updateInsightClaim] = useUpdateInsightClaimMutation();

	const handleReset = (event) => {
		event.preventDefault();
		event.stopPropagation();
		formik.resetForm();
	};

	const handleBlur = (event) => {
		if (!readOnly) {
			formik.handleSubmit(event);
		}
	};

	const formik = useFormik({
		initialValues: {
			[header]: claim[header],
		},
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			const value = formik.values[header];
			
			await updateInsightClaim({
				claimId: claim.Id,
				properties: {
					[header]: value,
				}
			}).unwrap();

			// invalidateTags();
			setSubmitting(false);
		},
	});

	useEffect(() => {
		formik.setValues({
			[header]: claim?.[header],
		});
	}, [header, claim]);

	const setFloatValue = ({ floatValue }) => {
		formik.setFieldValue(header, floatValue);
	};


	const getFormattedString = (claim) => {
		if (Object.keys(claim ?? {}).length > 0) {
			return `${formatDateDayToYear(claim?.PolicyTransactionFromDate)} to ${formatDateDayToYear(claim?.PolicyTransactionToDate)} (Effective ${formatDateDayToYear(claim?.PolicyTransactionEffectiveDate)})`;
		}
		return '';
	};

	const drawComponent = () => {
		switch (header) {
		case 'hs_pipeline_stage':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<DealCardStageChange minimal={false} showLabel={false} />
				</>
			);

		case 'LossDate':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<DealCardDateSelect dealKey={'date_of_loss'} minimal={false} disableFuture={false} disablePast={false} />
				</>
			);
		case 'ReportedDate':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<DealCardDateSelect dealKey={'ReportedDate'} minimal={false} disableFuture={false} disablePast={false} />
				</>
			);
		case 'claimNumber':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<ClaimNumberField minimal={false} />
				</>
			);

		case 'Description':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<TextField
						{...formik.getFieldProps(header)}
						fullWidth
						multiline
						rows={5}
						InputProps={{
							...(readOnly && { readOnly: true }),
						}}
						value={formik.values[header] ?? ''}
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						onBlur={handleBlur}
						disabled={false}
					/>
				</>
			);

		case 'Excess':
		case 'EstimateOfLossAmount': 
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<NumericFormat
						fullWidth
						customInput={TextField}
						thousandSeparator
						decimalScale={0}
						fixedDecimalScale
						allowNegative={false}
						prefix="$"
						value={formik.values[header]}
						onValueChange={setFloatValue}
						onBlur={handleBlur}
						style={{
							...(readOnly && { readOnly: true })
						}}
					/>
				</>
			);
		case 'Status': 
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<ClaimTypeSelectWrapper key={'ticket-card-value-claim-type'} noLabel={true} minimal={false} />
				</>
			);
		case 'PolicyPeriod': 
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<TextField
						{...formik.getFieldProps(header)}
						fullWidth
						InputProps={{
							...(readOnly && { readOnly: true }),
						}}
						value={getFormattedString(claim)}
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						onBlur={handleBlur}
						disabled={true}
					/>
				</>
			);
		default:
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<TextField
						{...formik.getFieldProps(header)}
						fullWidth
						InputProps={{
							...(readOnly && { readOnly: true }),
						}}
						value={formik.values[header] ?? ''}
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						onBlur={handleBlur}
						disabled={true}
					/>
				</>
			);
		}
	};

	return <>{drawComponent()}</>;
};
