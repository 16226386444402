import React from 'react';
import { folioBlue } from '@/utils/constants';
import { AttachFile } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import { useGetAttachmentQuery } from '@/features/engagements/engagementsApi';

const AttachmentListItem = ({ fileId }) => {
	const { data: file, isLoading, isError } = useGetAttachmentQuery({ fileId });

	return (
		<ListItem disablePadding disableGutters>
			{isLoading ? (
				<Skeleton width='100%' />
			) : isError ? (
				<ListItemText primary={'Error loading attachment'} />
			) : (
				<ListItemButton
					disableGutters
					target='_blank'
					rel='noopener noreferrer'
					href={file.url}
				>
					<ListItemIcon>
						<AttachFile />
					</ListItemIcon>
					<ListItemText sx={{ color: folioBlue }} primary={`${file.fileName}`} />
				</ListItemButton>
			)}
		</ListItem>
	);
};

export default AttachmentListItem;
