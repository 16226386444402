/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useMemo } from 'react';
import {
	Typography,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	FormHelperText,
} from '@mui/material';
import { FolioTableHead } from '@/components/table/FolioTableHead';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { useGetClientPoliciesQuery } from '@/features/clients/clientsApi';
import { stableSort } from '@/components/table/functions';
import { policyHeadCells } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { PolicyRow } from '@/components/tables/PolicyRow';
import { useGetDealPolicyTransactionsQuery } from '@/features/deals/dealsApi';

export const CompleteDealPolicySelection = ({
	setSelectedPolicies,
	setLoading,
	editable = false,
}) => {
	const [policyRows, setPolicyRows] = useState([]);
	const { deal, client, policies } = determineContext();

	const isEndorsement =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_ENDORSEMENTS;
	const isRenewal =
		(deal?.pipeline ?? deal?.hs_pipeline) === process.env.REACT_APP_PIPELINE_RENEWALS;
	const policyId = useMemo(() => policies[0]?.Id, [policies]);

	const {
		data: policyData,
		isLoading,
		isError: isPoliciesError,
		isFetching,
		isUninitialized: isPoliciesUninitialised,
	} = editable || isEndorsement || isRenewal
		? useGetDealPolicyTransactionsQuery(
			{
				policyId,
				dealId: deal?.id ?? deal?.hs_object_id,
				transactionType: isEndorsement ? 'endorsement' : isRenewal ? 'renewal' : 'newBusiness',
			},
			{ skip: !policyId }
		  )
		: useGetClientPoliciesQuery(
			{
				insightId: client?.clientRow?.insightId,
				hubspotId: client?.clientRow?.hubspotId,
				transactionType: 'newBusiness',
			},
			{ skip: !client?.clientRow?.insightId && !client?.clientRow?.hubspotId }
		  );

	const handleCheckboxChange = (event, policyId) => {
		const { checked } = event.target;


		const selectedPolicy = policyRows.find((p) => p.Id === policyId);

		setPolicyRows((prev) => prev.map(p => ({...p, selected: p.Id === policyId && checked })));
		// const updatedPolicyRows = policyRows.map((policy) =>
		// 	policy.Id === policyId ? { ...policy, selected: checked } : policy
		// );
		// setPolicyRows(updatedPolicyRows);

		setSelectedPolicies((prev) => checked ? [selectedPolicy] : []);

		// if (checked) {
		// 	setSelectedPolicies((prevSelected) => [...prevSelected, selectedPolicy]);
		// } else {
		// 	setSelectedPolicies((prevSelected) =>
		// 		prevSelected.filter((policy) => policy.Id !== policyId)
		// 	);
		// }
	};

	const handleSelectAllCheckbox = (event) => {
		const { checked } = event.target;

		const unassociatedPolicies = policyRows.filter(
			(policy) =>
				!policy.associated &&
				(policy.policyTransactions == null || policy.policyTransactions.length > 0)
		);
		const updatedPolicyRows = policyRows.map((policy) => {
			if (unassociatedPolicies.includes(policy)) {
				return { ...policy, selected: checked };
			}
			return policy;
		});

		setPolicyRows(updatedPolicyRows);
		setSelectedPolicies(checked ? unassociatedPolicies : []);
	};

	const error = isPoliciesError;
	const loading = isLoading || isPoliciesUninitialised;
	useEffect(() => setLoading(loading), [loading]);

	useEffect(() => {
		if (policyData) {
			const updatedPolicies = policyData.map((policy) => ({
				...policy,
				selected: false,
				linkedToCurrentDeal: editable && policies.some((p) => p.Id === policy.Id),
			}));
			setPolicyRows(updatedPolicies);
		}
	}, [policyData, editable, policies]);

	const policyString = isEndorsement
		? 'endorsement'
		: isRenewal
			? 'policy transaction'
			: 'policy';

	return (
		<>
			<Typography variant='subtitle2' sx={{ fontWeight: 'bold', marginTop: 3 }}>
				{`Choose the INSIGHT ${policyString} that relates to this deal`}
			</Typography>
			{!editable && !(isEndorsement || isRenewal) && (
				<Typography variant='subtitle2' sx={{ marginBottom: 3 }}>
					{`*The selected ${policyString} will be linked to the renewal deal at the end of the term.`}
				</Typography>
			)}
			<TableContainer component={Paper}>
				<Table
					aria-labelledby='tableTitle'
					size='medium'
					aria-label='enhanced table'
					sx={{ minWidth: 700 }}
				>
					<FolioTableHead
						order={'desc'}
						orderBy={'createDate'}
						headCells={policyHeadCells}
						// onSelectAllCheck={isEndorsement ? null : handleSelectAllCheckbox}
					/>
					<TableBody>
						{loading || isFetching ? (
							<TableProgressIndicator rowCount={policyHeadCells.length} />
						) : error ? (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>
										There was an error. Please try again.
									</Typography>
								</TableCell>
							</TableRow>
						) : policyRows.length > 0 ? (
							stableSort(policyRows, (a, b) => a.Id - b.Id).map((policy, index) => (
								<PolicyRow
									handleCheckboxChange={handleCheckboxChange}
									key={`policy-row-index-${index}-${policy.Id}`}
									policy={policy}
								/>
							))
						) : (
							<TableRow>
								<TableCell colSpan={policyHeadCells.length} align='center'>
									<Typography variant='subtitle1'>{`No unlinked ${policyString} found. Please create in INSIGHT first.`}</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
