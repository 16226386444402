import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { formatCurrency, formatKey } from '@/utils/constants';
import { DataGrid } from '@mui/x-data-grid';

const NoRowsOverlay = () => {
	return (
		<Box alignItems='center' width='100%' height='100%' pl={'0.75em'} pt='0.5em'>
			<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Select classes of risk from Results tables.</Typography>
		</Box>
	);
};

export const RecommendationTable = ({ rows }) => {

	useEffect(() => console.log('ROWS', rows), [rows]);
	const columns = [
		{
			field: 'classOfRisk',
			headerName: 'Class of Risk',
			minWidth: 250,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => formatKey(params.row.classOfRisk ?? ''),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			minWidth: 200,
			flex: 1,
			editable: false,
			sortable: false,
			display: 'flex',
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			minWidth: 150,
			flex: 0.5,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, false);
			},
		},
		{
			field: 'excess',
			headerName: 'Excess ($)',
			minWidth: 150,
			flex: 0.3,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.excess ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, false);
			},
		},
		{
			field: 'premium',
			headerName: 'Premium (incl.GST) ($)',
			minWidth: 150,
			flex: 0.3,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.premium ?? 0);
				value = isNaN(value) ? 0 : value;
				return formatCurrency(value, false);
			},
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
				'--DataGrid-overlayHeight': '3em',
			}}
			width='100%'
			autoHeight
			getRowHeight={() => 'auto'}
			rows={rows}
			getRowId={(row) => `${row.classOfRisk}-${row.insurer}`}
			columns={columns}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
			slots={{
				noRowsOverlay: NoRowsOverlay,
			}}
		/>
	);
};
