import { Avatar, Stack, Typography } from '@mui/material';
import React from 'react';

export const ClientNameAndAvatar = ({title, subtitle, initials, width}) => {
	
	return <div style={{ display: 'flex', alignItems: 'center', width: width ?? 300, }}>
		{/* <Avatar sx={{ width: 42, height: 42, marginRight: 2, backgroundColor: '#34495E', fontSize: '18px' }}>{initials}</Avatar> */}
		<Stack direction="column" spacing={1}>
			<Typography  variant="avatar_title">
				{title}
			</Typography>
			<Typography variant="avatar_subtitle">
				{subtitle}
			</Typography>
		</Stack>
	</div>;
};