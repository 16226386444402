import React, {  } from 'react';
import {
	Box,
	CircularProgress,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import '@/styles/index.scss';

const TaskCheckbox = ({ task, completeTask, isLoading }) => {

	return isLoading ? (
		<Box padding='0.76rem'>
			<CircularProgress size='1rem' />
		</Box>
	) : (
		<Checkbox
			icon={
				<>
					<svg width={0} height={0}>
						<linearGradient id='linearColors' x1={0} y1={1} x2={1} y2={1}>
							<stop offset={0} stopColor='rgba(131,214,255,1)' />
							<stop offset={1} stopColor='rgba(229,132,255,1)' />
						</linearGradient>
					</svg>
					<CheckCircleOutlineRoundedIcon
						sx={{ fill: 'url(#linearColors)' }}
					/>
				</>
			}
			checkedIcon={
				<>
					<svg width={0} height={0}>
						<linearGradient id='linearColors2' x1={0} y1={1} x2={1} y2={1}>
							<stop offset={0} stopColor='rgba(131,214,255,1)' />
							<stop offset={1} stopColor='rgba(229,132,255,1)' />
						</linearGradient>
					</svg>
					<CheckCircleRoundedIcon sx={{ fill: 'url(#linearColors2)' }} />
				</>
			}
			checked={task.completed}
			onChange={completeTask}
		/>
	);
};

export default TaskCheckbox;