import { ReactComponent as TaskIconSVG } from '@/assets/icons/New-Task-Icon.svg';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const TaskIcon = ({ bgColor = '#E5DAFF', width='24px', height='24px', ...props }) => {
	return (
		<SvgIcon
			{...props}
			sx={{
				backgroundColor: bgColor, 
				width: width,
				height: height,
                borderRadius: 2
			}}
		>
			<TaskIconSVG />
		</SvgIcon>
	);
};
