import React, { useMemo, useState, useEffect } from 'react';
import { Typography, Card, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { InstantDealDisplayCard } from '@/components/cards/DealsDIsplayCard';
import ClientTable from '@/components/tables/ClientTable';
import { useSelector } from 'react-redux';
import { IS_DEV } from '@/utils/environment';
import { PageHeader } from '@/components/layouts/PageHeader';

export const ClientsView = () => {
	const account = useSelector((state) => state.msalAccount.account);
	const name = useMemo(() => account?.name.split(' ')[0], [account]);

	return (
		<Box
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
				p: 3,
			}}
		>
			<PageHeader title={`Kia ora ${name}`} showGroupView padding={'1em 0'} />
			<Grid container spacing={2}>
				<Grid size={{ xs: 12, md: 3 }}>
					<InstantDealDisplayCard
						title='New Business Leads'
						pipelineId={process.env.REACT_APP_PIPELINE_NEW_BUSINESS}
					/>
				</Grid>
				<Grid size={{ xs: 12, md: 3 }}>
					<InstantDealDisplayCard
						title='Renewals'
						pipelineId={process.env.REACT_APP_PIPELINE_RENEWALS}
					/>
				</Grid>
				<Grid size={{ xs: 12, md: 3 }}>
					<InstantDealDisplayCard
						title='Endorsements'
						pipelineId={process.env.REACT_APP_PIPELINE_ENDORSEMENTS}
					/>
				</Grid>
				<Grid size={{ xs: 12, md: 3 }}>
					<InstantDealDisplayCard
						title='Claims'
						pipelineId={process.env.REACT_APP_PIPELINE_CLAIMS}
					/>
				</Grid>
			</Grid>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '3em 0 1em',
				}}
			>
				<Typography variant='h5' style={{ fontWeight: 'bold' }}>
					Clients
				</Typography>
			</Box>
			<Card>
				<ClientTable />
			</Card>
		</Box>
	);
};
