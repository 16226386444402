/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { useMSALHelper } from './useMSALHelper';

const GRAPH_URL = 'https://graph.microsoft.com/v1.0/';
const msGraphAxios = axios.create({ baseURL: GRAPH_URL });

// Custom hook to interact with Microsoft graph
export const useMsGraph = () => {
	// Using MSAL hook to get auth instance and accounts
	const { instance } = useMsal();
	const account = useSelector((state) => state.msalAccount.account);

	const { getAccessToken } = useMSALHelper();

	const scopes = [
		'User.Read',
		'Calendars.ReadWrite',
		'OnlineMeetings.ReadWrite',
		'Calendars.Read',
		'User.Read.All',
		'Files.ReadWrite.All', //'Files.Read.All', 'Files.ReadWrite',
	];

	const getGraphAccessToken = async () => {
		return await getAccessToken(scopes);
		// 	const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		// 	const request = {
		// 		scopes,
		// 		account: currentAccount,
		// 	};
		// 	return await instance
		// 		.acquireTokenSilent(request)
		// 		.then((response) => {
		// 			console.log('🔓 ~ Access token retrieved silently');
		// 			return response.accessToken;
		// 		})
		// 		.catch(async (error) => {
		// 			console.error('🙅 ~ Error retrieving access token silently', error);
		// 			// Redirect user to login screen if there is an issue getting access token

		//             return instance
		// 				.acquireTokenRedirect(request)
		// 				.then((response) => {
		// 					console.log('🔓 ~ Access token retrieved via redirect');
		// 					return response.accessToken;
		// 				})
		// 				.catch((e) => {
		// 					console.error('🙅 ~ Token redirect error', e);
		// 					return Promise.reject(e);
		// 				});
		// 		});
	};

	const createOnlineMeetingEvent = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post('/me/events', eventDetails, { headers });
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getGraphEventData = async (taskType, graphEventId) => {
		

		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `/me/calendar/events/${graphEventId}`;
		} else {
			endpoint = `/me/events/${graphEventId}`;
		}
	
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
	
			
			const response = await msGraphAxios.get(endpoint, { headers });		
			return response.data;
		} catch (error) {
			console.error('Error fetching graph event data:', error);
			throw error;
		}
	};

	const updateGraphEventData = async (taskType, graphEventId, eventData) => {
		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `/me/calendar/events/${graphEventId}`;
		} else {
			endpoint = `/me/events/${graphEventId}`;
		}
	
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.patch(endpoint, eventData, { headers });
			return response.data;
		} catch (error) {
			console.error('Error updating graph event data:', error);
			throw error;
		}
	};
	

	const createEventTask = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post('/me/calendar/events', eventDetails, {
				headers,
			});
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getUserCalendars = async (email) => {
		const userId = await getUserIdByEmail(email);
		const accessToken = await getGraphAccessToken();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		};
		try {
			const response = await msGraphAxios.get(`/users/${userId}/calendars`, { headers });
			console.log('Calendars retrieved successfully:', response.data.value);
			return response.data.value;
		} catch (error) {
			console.error('Error retrieving calendars:', error);
			throw error;
		}
	};

	const getUserIdByEmail = async (email) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/users/${email}`, { headers });
			console.log(':mag: ~ User retrieved successfully', response.data);
			return response.data.id;
		} catch (error) {
			console.error(':no_good: ~ Error retrieving user', error);
			throw error;
		}
	};

	const getDriveItem = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${itemId}`, {
				headers,
			});
			console.log(':mag: ~ [getDriveItem] retrieved successfully', response.data);
			return response.data?.value;
		} catch (error) {
			console.error(':no_good: ~ [getDriveItem] Error retrieving item', error);
			throw error;
		}
	};

	const getSharepointListItemChildren = async (siteId, listId, itemId) => {
		try {
			const items = await getSharepointListItems(siteId, listId);
			const parent = items.find((i) => i.id === itemId);
			const filteredList = items.filter((i) => i.webUrl.startsWith(parent.webUrl));
			console.log('ITEMS', filteredList);
			return filteredList;
		} catch (error) {
			console.error('Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	// 	const list = items.find(l => decodeURI(l.fields.FileLeafRef.normalize()) == decodeURI(replacedCompanyName.normalize()));
	const getSharepointListItems = async (siteId, listId, filterFunction) => {
		let items = [];
		let nextPageUrl = `/sites/${siteId}/lists/${listId}/items?expand=fields`;
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			while (nextPageUrl) {
				// Fetch the list items and expand fields to get custom metadata
				const response = await msGraphAxios.get(nextPageUrl, { headers });
				const { value, '@odata.nextLink': nextLink } = response.data;

				items = [...items, ...value];
				if (filterFunction) {
					console.log('ITEMS prior to filtering', items);
					const result = filterFunction(items);
					console.log('FILTER LIST', result);
					if (result) {
						items = [result];
						nextPageUrl = null;
						break;
					}
				}
				nextPageUrl = nextLink;
			}
			console.log('SharePoint list items with metadata:', items);
			return items;
		} catch (error) {
			console.error('Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	const getSharepointLists = async (siteId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			// Fetch the list items and expand fields to get custom metadata
			const response = await msGraphAxios.get(`/sites/${siteId}/lists`, { headers });
			console.log('SharePoint lists:', response.data?.value);
			return response.data?.value;
		} catch (error) {
			console.error('Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	const searchClientMsGraphFolders = async (driveId, searchText) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			// /drives/{drive-id}/root/search(q='{search-text}')
			const response = await msGraphAxios.get(`/drives/${driveId}/root/search(q='${searchText}')`, {
				headers,
			});
			console.log(
				':) ~ Client Folders searched successfully',
				response.data,
				driveId,
				searchText
			);
			return response.data?.value;
		} catch (error) {
			console.error(':no_good: ~ Error searching client folders', error);
			throw error;
		}
	};

	const getClientMsGraphFolders = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${itemId}/children`, {
				headers,
			});
			console.log(
				':) ~ Client Folders retrieved successfully',
				response.data,
				driveId,
				itemId
			);
			return response.data?.value;
		} catch (error) {
			console.error(':no_good: ~ Error retrieving client folders', error);
			throw error;
		}
	};

	const getClientFolderDrive = async (driveId, filterFunction) => {
		let items = [];
		let nextPageUrl = `/drives/${driveId}/root/children`;
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			while (nextPageUrl) {
				// Fetch the list items and expand fields to get custom metadata
				const response = await msGraphAxios.get(nextPageUrl, { headers });
				const { value, '@odata.nextLink': nextLink } = response.data;

				items = [...items, ...value];
				if (filterFunction) {
					const result = filterFunction(items);
					console.log('FILTER LIST', result);
					if (result) {
						items = [result];
						nextPageUrl = null;
						break;
					}
				}
				nextPageUrl = nextLink;
			}
			console.log('SharePoint list items with metadata:', items);
			return items;
		} catch (error) {
			console.error('Error retrieving SharePoint list items', error);
			throw error;
		}
		// try {
		// 	const accessToken = await getGraphAccessToken();
		// 	const headers = {
		// 		Authorization: `Bearer ${accessToken}`,
		// 		'Content-Type': 'application/json',
		// 	};
		// 	const response = await msGraphAxios.get(`/drives/${driveId}/root/children`, {
		// 		headers,
		// 	});
		// 	console.log(':mag: ~ Client Folder Drive retrieved successfully', response.data);
		// 	return response.data?.value;
		// } catch (error) {
		// 	console.error(':no_good: ~ Error retrieving client folder drive', error);
		// 	throw error;
		// }
	};

	const getSharepointSiteDrives = async (siteId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/sites/${siteId}/drives`, { headers });
			console.log(':mag: ~ Drive retrieved successfully', response.data);
			return response.data?.value;
		} catch (error) {
			console.error(':no_good: ~ Error retrieving drive', error);
			throw error;
		}
	};

	const getListItem = async (driveId, fileId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			// Fetch the associated list item
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${fileId}/listItem`, {
				headers,
			});

			console.log('List item:', response.data);
			return response.data;
		} catch (error) {
			console.error('Error retrieving list item', error);
			throw error;
		}
	};

	const getFileMetadata = async (siteId, listId, listItemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const propertiesResponse = await msGraphAxios.get(
				`/sites/${siteId}/lists/${listId}/items/${listItemId}?expand=fields(select='OData__x005f_ExtendedDescription')`,
				{
					headers,
				}
			);

			console.log('File metadata (ListItemAllFields):', propertiesResponse.data);
			return propertiesResponse.data;
		} catch (error) {
			console.error('Error retrieving file metadata', error);
			throw error;
		}
	};

	const querySharepointSites = async (query) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/sites?search=${query}`, { headers });
			console.log(':mag: ~ [querySharepointSites] retrieved successfully', response.data);
			return response.data?.value?.[0]?.id;
		} catch (error) {
			console.error(':no_good: ~ Error [querySharepointSites]', error);
			throw error;
		}
	};

	// Returning the functions to be used elsewhere in the app
	return {
		getSharepointLists,
		getSharepointListItems,
		getSharepointListItemChildren,
		getListItem,
		getFileMetadata,
		getDriveItem,
		getSharepointSiteDrives,
		querySharepointSites,
		getClientFolderDrive,
		getClientMsGraphFolders,
		searchClientMsGraphFolders,
		getGraphAccessToken,
		createOnlineMeetingEvent,
		createEventTask,
		getUserIdByEmail,
		getUserCalendars,
		getGraphEventData,
		updateGraphEventData
	};
};
