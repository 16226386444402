import Grid from '@mui/material/Grid2';
import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useNavigate } from 'react-router-dom';
import { ClientTabView } from './ClientTabView';
import { AdviserAboutCard } from './AdviserAboutCard';
import { paths } from '@/app/routes';

const ClientViewWrapper = () => {
	const navigate = useNavigate();

	return (
		<div className='client-view-wrapper'>
			<Grid container>
				<Grid size={{ xs: 8, sm: 9 }} sx={{ p: 1 }}>
					<Stack>
						<Box display='flex' px='1em' sx={{ alignItems: 'center' }}>
							<Button
								variant='text'
								onClick={() => navigate(paths.home)}
								sx={{ color: 'black', fontWeight: 'bold' }}
								startIcon={<KeyboardBackspaceOutlinedIcon />}
							>
								{'Clients'}
							</Button>
						</Box>
					</Stack>
					<ClientTabView />
				</Grid>
				<Grid
					size={{ xs: 4, sm: 3 }}
					sx={{
						position: 'sticky',
						top: 0,
						alignSelf: 'flex-start',
					}}
				>
					<AdviserAboutCard />
				</Grid>
			</Grid>
		</div>
	);
};

export default ClientViewWrapper;
