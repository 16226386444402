import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { FIELDS } from '../validationSchema';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';

export const WhatHasBeenSentToMarket = ({ rows, updateRows, removeRow, disabled }) => {
	const columns = [
		// {
		// 	...GRID_CHECKBOX_SELECTION_COL_DEF,
		// 	renderHeader: (params) => <></>,
		// 	renderCell: (params) =>
		// 		Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id) || params.row.classOfRisk === '' ? (
		// 			<></>
		// 		) : (
		// 			<IconButton
		// 				sx={{ fontSize: '10px', padding: 0 }}
		// 				color='rgba(0,0,0,0)'
		// 				onClick={() => removeRow(params.row.id)}
		// 			>
		// 				<DeleteIcon />
		// 			</IconButton>
		// 		),
		// },
		{
			field: 'classOfRisk',
			headerName: 'Class of Risk',
			minWidth: 300,
			flex: 2,
			editable: !disabled,
			sortable: false,
            display: 'flex',
			renderCell: (params) =>
				params.row.classOfRisk === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter name of product</Typography>
				) : (
					params.row.insurer
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			minWidth: 300,
			flex: 1,
			editable: !disabled,
			sortable: false,
            display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.classOfRisk === '') {
					return '';
				}
				return formatCurrency(value, false);
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
		{
			// ...GRID_CHECKBOX_SELECTION_COL_DEF,
			field: 'delete',
			headerName: 'Delete',
			width: 48,
			sortable: false,
			renderHeader: (params) => <></>,
            display: 'flex',
			renderCell: (params) =>
				Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id) ||
				params.row.classOfRisk === '' ? (
					// params.row.classOfRisk === '' ? (
						<></>
					) : (
						<IconButton
							sx={{ fontSize: '10px', padding: 0 }}
							color='rgba(0,0,0,0)'
							onClick={() => removeRow(params.row.id)}
						>
							<Delete />
						</IconButton>
					),
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled) {
					return false;
				}
				if (params.field === 'classOfRisk') {
					return !Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id);
				}
				return params.row.classOfRisk != ''; // true
			}}
			// isRowSelectable={(params) => {
			// 	return !Object.keys(FIELDS.whatHasBeenSentToMarket).includes(params.row.id);
			// }}
			// checkboxSelection
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
