import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridExcessSelect from '@/components/table/DataGridExcessSelect';
import useCellModes from '@/hooks/useCellModes';

export const ResultsTable = ({
	rows,
	updateRows,
	removeRow,
	setRowSelectionModel,
	rowSelectionModel,
	disabled,
}) => {
	const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellModes();

	const columns = [
		{
			...GRID_CHECKBOX_SELECTION_COL_DEF,
			renderHeader: () => <></>,
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<></>
				) : (
					GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(params)
				),
		},
		{
			field: 'insurer',
			headerName: 'Insurer',
			flex: 1,
			minWidth: 250,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.insurer === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter name of insurer</Typography>
				) : (
					params.row.insurer
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				if (value > 0) {
					return formatCurrency(value, false);
				}
			},
		},
		{
			field: 'excess',
			headerName: 'Excess ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			singleClickCell: true,
			renderCell: (params) => {
				let value = parseInt(params.row.excess ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '' || value === 0) {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
							{params.row.insurer === '' ? '' : 'Enter excess'}
						</Typography>
					);
				}
				return formatCurrency(value, false);
			},
			renderEditCell: (params) => (
				<DataGridExcessSelect {...params} />
			),
		},
		{
			field: 'premium',
			headerName: 'Premium (incl.GST) ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.premium ?? 0);
				value = isNaN(value) ? 0 : value;
				if (params.row.insurer === '' || value === 0) {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>
							{params.row.insurer === '' ? '' : 'Enter premium'}
						</Typography>
					);
				}
				return formatCurrency(value, false);
			},
			renderEditCell: (params) => (
				<DataGridNumericFormat {...params} isCurrency={true} />
			),
		},
		{
			// ...GRID_CHECKBOX_SELECTION_COL_DEF,
			field: 'delete',
			headerName: 'Delete',
			width: 48,
			sortable: false,
			resizable: false,
			display: 'flex',
			renderHeader: (params) => <></>,
			renderCell: (params) =>
				params.row.insurer === '' || rows.length <= 1 ? (
					<></>
				) : (
					<IconButton
						sx={{ fontSize: '10px', padding: 0 }}
						color='rgba(0,0,0,0)'
						onClick={() => removeRow(params.row.id)}
					>
						<Delete />
					</IconButton>
				),
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => {
				if (disabled) {
					return false;
				}
				if (params.field === 'insurer') {
					return true;
				}
				return (params.row.insurer ?? '').length > 0;
			}}
			isRowSelectable={(params) => {
				const sumInsured = parseInt((params.row?.sumInsured ?? '').toString());
				const excess = parseInt((params.row?.excess ?? '').toString());
				const premium = parseInt((params.row?.premium ?? '').toString());
				return (
					!isNaN(sumInsured) &&
					sumInsured > 0 &&
					!isNaN(excess) &&
					excess > 0 &&
					!isNaN(premium) &&
					premium > 0 &&
					(params.row?.insurer ?? '').toString().length > 0
				);
			}}
			getRowId={(row) => `${row.insurer}`}
			checkboxSelection
			onRowSelectionModelChange={setRowSelectionModel}
			rowSelectionModel={rowSelectionModel}
			cellModesModel={cellModesModel}
			onCellModesModelChange={handleCellModesModelChange}
			onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};
