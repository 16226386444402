import React, { useEffect, useState } from 'react';
import { Typography, TextField, FormGroup } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import { ADDITIONAL_SPECIALIST_PRODUCTS } from '@/components/forms/CreateNewBusinessForm/validationSchema';

export const AdditionalProducts = () => {
	const { dealRow, loading } = determineContext();
	const loadingDeal = loading?.deal;

	const [additionalValue, setAdditionalValue] = useState(
		dealRow?.additionalSpecialistProducts ?? ''
	);

	useEffect(() => {
		setAdditionalValue(dealRow?.additionalSpecialistProducts ?? '');
	}, [loadingDeal, dealRow]);

	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const onChange = async () => {
		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			properties: {
				[ADDITIONAL_SPECIALIST_PRODUCTS]: additionalValue
			}
		}).unwrap();
	};

	return (
		<FormGroup row>
			<Typography variant='form_label'>
				Additional specialist insurance products discussed and the outcomes
			</Typography>
			<TextField
				fullWidth
				disabled={isLoading}
				multiline
				rows={4}
				value={additionalValue}
				onChange={(e) => setAdditionalValue(e.target.value)}
				onBlur={onChange}
			/>
		</FormGroup>
	);
};
