import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';

export const ConfirmDialog = ({
	openDialog,
	handleClose,
	handleConfirm,
	actionName,
	canBeUndone,
	additionalContent = null
}) => {
	return (
		<Dialog
			open={openDialog}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '25%', padding: '1em' }}
		>
			<DialogTitle>{`Confirm ${actionName}`}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '2em' }}>
				{`Are you sure that you want to ${actionName}?${additionalContent && additionalContent?.length > 0 ? additionalContent : 
					canBeUndone ? '' : ' This cannot be undone.'
				}`}
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
				<Button
					onClick={() => {
						handleClose();
					}}
					color='primary'
				>
					Cancel
				</Button>
				<Button
					color='primary'
					type='submit'
					variant='contained'
					onClick={() => {
						handleConfirm();
					}}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};
