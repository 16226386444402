import React, { useEffect, useMemo } from 'react';
import { Box, Stack, Typography, Grid } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { ComplianceTaskCheckbox } from '../cards/DealCardValues/ComplianceTaskCheckbox';
import { theme } from '@/app/theme';
import { closedPipelineStages } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import ComplianceAccordion from '../cards/ComplianceAccordion';

export const ComplianceTasksDisplay = () => {
	const { complianceTasks, deal, pipelines } = determineContext();

	const pipelineId = deal?.pipeline ?? deal?.hs_pipeline;
	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId, {
		skip: !pipelineId,
	});

	const stages = pipelineQuery?.data?.stages ?? {};

	const dealStage = useMemo(() => {
		const stageId = deal?.dealstage ?? deal?.hs_pipeline_stage;
		const stage = pipelines?.stages?.find((stage) => stage.id === stageId);
		return stage;
	}, [pipelines, deal?.hs_pipeline_stage, deal?.dealstage, pipelines?.stages]);

	const dealStageId = useMemo(() => dealStage?.id, [dealStage]);

	const isDisabled = useMemo(
		() =>
			dealStage?.metadata?.probability?.toString() === '1.0' ||
			dealStage?.metadata?.ticketState === 'CLOSED',
		[dealStage]
	);

	const taskList = useMemo(() => {
		const stageList = Object.entries(stages).reduce((acc, [stageId, stage]) => {
			const tasks = (complianceTasks ?? []).filter((t) =>
				(t.questionData?.stages ?? []).includes(stageId)
			);
			acc.push({
				stageId,
				order: stage.order,
				label: stage.label,
				tasks: (tasks ?? []).sort((a, b) => a.questionData.order - b.questionData.order),
			});
			return acc;
		}, []);
		if (!isDisabled) {
			return stageList.filter((s) => s.stageId == dealStageId);
		}
		return stageList.sort((a, b) => a.order - b.order).filter((t) => t.tasks.length > 0);
	}, [stages, complianceTasks, dealStageId, isDisabled]);

	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	return (
		<Box width={'100%'}>
			<Typography sx={minimalLabelTheme}>Steps to complete</Typography>
			<Stack direction='column' spacing={1} sx={{ width: '100%' }}>
				{taskList.length > 1
					? taskList.map(({ stageId, label, tasks }) => (
						<ComplianceAccordion
							title={label}
							tasks={tasks ?? []}
							truncateTotal={true}
							key={`deal-compliance-accordion-${stageId}`}
						>
							<Stack>
								{tasks.map((task) => (
									<ComplianceTaskCheckbox key={task.documentId} task={task} />
								))}
							</Stack>
						</ComplianceAccordion>
					))
					: (taskList?.[0]?.tasks ?? []).map((task) => (
						<ComplianceTaskCheckbox key={task.documentId} task={task} />
					))}
			</Stack>
		</Box>
	);
};
