import React from 'react';
import { Box, Card, CardActions, CardContent, Skeleton, Stack, Typography } from '@mui/material';

export const LoadingCard = () => (
	<Box mb={1}>
		<Card px='12px'>
			<CardContent>
				<Stack spacing={1}>
					<Typography variant="avatar_title">
						<Skeleton variant='text' />
					</Typography>
					<Typography variant="avatar_subtitle">
						<Skeleton variant='text' />
					</Typography>
					<Skeleton variant='circular' height={30} width={30} />
            
					<Typography variant="task_updated">
						<Skeleton variant='text' />
					</Typography>
				</Stack>
			</CardContent>
			<CardActions sx={{ justifyContent: 'end'}}>
				<Skeleton variant='text' />
			</CardActions>
		</Card>
	</Box>);