import { ReactComponent as ClientIconSVG } from '@/assets/icons/New-Client.svg';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const ClientIcon = ({ bgColor = '#34495E', width='24px', height='24px', ...props }) => {
	return (
		<SvgIcon
			{...props}
			sx={{
				backgroundColor: bgColor, 
				width: width,
				height: height,
				borderRadius: 2
			}}
		>
			<ClientIconSVG />
		</SvgIcon>
	);
};
