import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import { formatCurrency } from '@/utils/constants';
import DataGridNumericFormat from '@/components/table/DataGridNumericFormat';
import DataGridYearPicker from '@/components/table/DataGridYearPicker';
import useCellModes from '@/hooks/useCellModes';

export const MaterialDamageLocationTable = ({ rows, updateRows, disabled }) => {
	
	const columns = [
		{
			field: 'coverage',
			headerName: 'Coverage',
			flex: 0.3,
			minWidth: 150,
			editable: false,
			sortable: false,
			display: 'flex',
			// renderCell: (params) =>
			// 	params.row.year === '' ? (
			// 		<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>{'Enter year'}</Typography>
			// 	) : (
			// 		params.row.year
			// 	),
			// renderEditCell: (params) => <DataGridYearPicker {...params} />,
		},
		{
			field: 'basisOfSettlement',
			headerName: 'Basis of Settlement',
			flex: 0.5,
			minWidth: 200,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) =>
				params.row.basisOfSettlement === '' ? (
					<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter basis of settlement</Typography>
				) : (
					params.row.basisOfSettlement
				),
		},
		{
			field: 'sumInsured',
			headerName: 'Sum Insured ($)',
			flex: 0.3,
			minWidth: 150,
			editable: !disabled,
			sortable: false,
			display: 'flex',
			renderCell: (params) => {
				let value = parseInt(params.row.sumInsured ?? 0);
				value = isNaN(value) ? 0 : value;
				if (value > 0) {
					return formatCurrency(value, false);
				} else {
					return (
						<Typography sx={{ color: 'rgba(0,0,0,0.5)' }}>Enter sum insured</Typography>
					);
				}
			},
			renderEditCell: (params) => <DataGridNumericFormat {...params} isCurrency={true} />,
		},
	];

	return (
		<DataGrid
			sx={{
				'& .MuiDataGrid-editInputCell': {
					backgroundColor: 'transparent',
				},
			}}
			width='100%'
			processRowUpdate={updateRows}
			rows={rows}
			columns={columns}
			isCellEditable={(params) => !disabled}
			// getRowId={(row) => `${row.year}-${row.make}-${row.model}`}
			// checkboxSelection
			// onRowSelectionModelChange={setRowSelectionModel}
			// rowSelectionModel={rowSelectionModel}
			// cellModesModel={cellModesModel}
			// onCellModesModelChange={handleCellModesModelChange}
			// onCellClick={handleCellClick}
			disableRowSelectionOnClick
			disableSelectionOnClick
			disableColumnFilter
			disableColumnSelector
			disableColumnMenu
			hideFooter
		/>
	);
};