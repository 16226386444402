import React, { useLayoutEffect, useCallback, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

const DataGridMultilinePopper = (props) => {
	const { id, field, value, colDef, hasFocus } = props;
	const [valueState, setValueState] = useState(value);
	const [anchorEl, setAnchorEl] = useState();
	const [inputRef, setInputRef] = useState(null);
	const apiRef = useGridApiContext();

	useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleRef = useCallback((el) => {
		setAnchorEl(el);
	}, []);

	const handleChange = useCallback(
		(event) => {
			const newValue = event.target.value;
			setValueState(newValue);
			apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
		},
		[apiRef, field, id]
	);

	return (
		<div style={{ position: 'relative', alignSelf: 'flex-start' }}>
			<div
				ref={handleRef}
				style={{
					height: 1,
					width: colDef.computedWidth,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
			/>
			{anchorEl && (
				<Popper open anchorEl={anchorEl} placement='bottom-start'>
					<Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
						<InputBase
							multiline
							rows={4}
							value={valueState}
							sx={{ textarea: { resize: 'both' }, width: '100%' }}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
							onChange={handleChange}
							inputRef={(ref) => setInputRef(ref)}
						/>
					</Paper>
				</Popper>
			)}
		</div>
	);
};

export default DataGridMultilinePopper;
