import React, { useEffect, useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	Stack,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useSendScopeOfAdviceEmailMutation } from '@/features/deals/dealsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { determineContext } from '@/hooks/determineContext';
import { showToast } from '@/features/toast/toastSlice';

export const SendScopeOfAdviceButton = () => {
	const dispatch = useDispatch();

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });

	const { deal } = determineContext();

	const [sendScopeOfAdviceEmail, { isLoading }] = useSendScopeOfAdviceEmailMutation();

	const handleSendScopeEmail = async () => {
		await sendScopeOfAdviceEmail({
			dealId: deal?.hs_object_id,
			hubspotId: userQuery?.data?.hubspotId,
		})
			.unwrap()
			.then((res) => {
				console.log('Result of sending email', res);
				dispatch(
					showToast({
						message: 'Email sent!',
						success: true,
					})
				);
			})
			.catch((err) => {
				console.log('Error sending email', err);
				dispatch(
					showToast({
						message: 'Error sending Scope of Advice email; please try again.',
						error: true,
					})
				);
			});
	};

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
				<Button
					disabled={isLoading || !deal || !userQuery.data}
					variant='tab_header'
					startIcon={<EmailIcon />}
					onClick={() => setConfirmationOpen(true)}
				>
					{'Email Scope of Advice'}
				</Button>
			</div>
			{confirmationOpen && (
				<Dialog
					open={confirmationOpen}
					onClose={() => setConfirmationOpen(false)}
					maxWidth='xs'
					fullWidth={true}
				>
					<DialogTitle>Confirm Send Scope of Advice Email</DialogTitle>
					<DialogContent>
						<Stack spacing={2}>
							<Typography>
								{
									`An email will be sent to your email address (${email}) outlining the New Business information captured.`
								}
							</Typography>
							<Typography>
								{
									'We recommend reviewing the email and amending any details prior to sending to your client.'
								}
							</Typography>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setConfirmationOpen(false)} color='primary'>
							Cancel
						</Button>
						<Button
							onClick={() => {
								setConfirmationOpen(false);
								handleSendScopeEmail();
							}}
							color='primary'
							variant='contained'
						>
							Send Scope of Advice Email
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};
