// features/table/attachmentsTableSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const attachmentsTableSlice = createSlice({
	name: 'attachmentsTable',
	initialState: {
		pageAfter: null,
		rowsPerPage: 10,
		orderBy: 'TimeCreated',
		order: 'desc',
		page: 0,
		rows: [],
		searchValue: '',
		loading: false,
		error: false,
		refreshIndicator: 0,
	},
	reducers: {
		setPageAfter: (state, action) => {
			state.pageAfter = action.payload;
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		setRowsPerPage: (state, action) => {
			state.rowsPerPage = action.payload;
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload;
		},
		setOrder: (state, action) => {
			state.order = action.payload;
		},
		requestSort: (state, action) => {
			console.log('ACTION', action, state.orderBy);
			const property = action.payload;
			const isAsc = state.orderBy === property && state.order === 'asc';
			state.order = isAsc ? 'desc' : 'asc';
			state.orderBy = property;
		},
		setRows: (state, action) => {
			state.rows = action.payload ?? [];
		},
		changeRowsPerPage: (state, action) => {
			state.rowsPerPage = parseInt(action.payload, 10);
			state.page = 0;
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		triggerRefresh: (state) => {
			state.refreshIndicator += 1;
		},
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		}
	},
});

export const { 
	setPageAfter,
	setPage,
	setRowsPerPage,
	setOrderBy,
	setOrder,
	requestSort,
	setRows,
	changeRowsPerPage,
	setError,
	setLoading,
	triggerRefresh,
	setSearchValue,
} = attachmentsTableSlice.actions;

export default attachmentsTableSlice.reducer;
