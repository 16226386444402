import React, { useEffect } from 'react';
import { Typography, Card, CardMedia, Stack, Link, Container, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ConstructionIcon from '@mui/icons-material/Construction';
import { theme } from '@/app/theme';
import { useDispatch } from 'react-redux';
import { featureFlagsApi, useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import UnderMaintenance from '@/assets/images/under-maintenance.png';

const UnderMaintenancePage = () => {
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;
	const dispatch = useDispatch();

	const { data: siteActive } = useGetFeatureFlagQuery({ feature: 'siteActive' });

	useEffect(() => {
		let intervalId;

		const checkRunStatus = async () => {
			dispatch(featureFlagsApi.util.invalidateTags([{ type: 'FEATURE', id: 'siteActive' }]));
		};

		if (!siteActive) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}

		return () => clearInterval(intervalId);
	}, [siteActive]);

	return (
		<Container>
			<Grid
				container
				spacing={2}
				style={{ height: '100vh', overflow: 'hidden', display: 'flex' }}
			>
				<Grid size={6} style={{ height: '100%' }}>
					<Typography sx={{ fontWeight: 'bold', marginBottom: 2 }} variant='h5'>
						Folio
					</Typography>
					<Card sx={{ width: '100%', height: '100%', borderRadius: 0 }}>
						<CardMedia
							component='img'
							height='100%'
							width='100%'
							src={UnderMaintenance}
							alt='Under maintenance'
							sx={{ objectFit: 'cover' }}
						/>
					</Card>
				</Grid>
				<Grid size={6} container justifyContent='center' alignItems='center'>
					<Stack direction='column'>
						<Card sx={{ padding: 8 }}>
							<Typography sx={{ fontWeight: 'bold', marginBottom: 2 }} variant='h4'>
								Folio Broker Portal
							</Typography>
							<Stack direction='row' alignContent={'center'} pb={2} spacing={1}>
								<ConstructionIcon />
								<Typography variant='subtitle1'>Under Maintenance</Typography>
							</Stack>
							<Typography sx={{ marginBottom: 4 }}>
								The Folio Broker Portal is currently undergoing maintenance. Please
								check back soon.
							</Typography>
						</Card>
						<Typography sx={{ ...minimalLabelTheme, marginTop: 1 }}>
							Need help? Email{' '}
							<span>
								<Link
									href={'mailto:support@folio.insure'}
									target='_blank'
									rel='noopener noreferrer'
								>
									support@folio.insure
								</Link>
							</span>{' '}
							for assistance.
						</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
	);
};

export default UnderMaintenancePage;
