import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { engagementsApi, useCreateNoteMutation } from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	ref,
	uploadBytes,
} from 'firebase/storage';
import { useSharepoint } from '@/hooks/useSharepoint';
import { storage } from '@/utils/firebase';
import {
	formatDate,
	attachmentsFolderPath,
	attachmentsClientFolderPath,
	folderStructure,
	replaceFolderName,
} from '@/utils/constants';
import { jsPDF } from 'jspdf';
import FileUpload from '@/components/FileUpload';
import { dealsApi } from '@/features/deals/dealsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { ticketsApi } from '@/features/claims/ticketsApi';

const validationSchema = Yup.object().shape({
	body: Yup.string().required('Note is required.'),
	files: Yup.array(),
});

export const AddNoteDialog = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);

	const open = useSelector((state) => state.dialog.open['note']);

	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const [createNote, { isLoading }] = useCreateNoteMutation();
	const { deal, client, contact, objectType, dealRow, sharepoint } = determineContext();

	const validSharepoint = (!deal && !client) ? true : sharepoint?.isValid;

	const sharepointSite = (!deal && !client) ? userDetails?.sharepointSite : sharepoint?.sharepointSite;
	const clientSiteSuffix = (!deal && !client) ? userDetails?.clientSiteSuffix : sharepoint?.clientSiteSuffix;
	const clientFolder = (!deal && !client) ? userDetails?.clientFolder : sharepoint?.clientFolder;
	const clientSite = (!deal && !client) ? userDetails?.clientSite : sharepoint?.clientSite;
    
	const { addFileToSharepoint } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const handleClose = () => {
		dispatch(closeDialog('note'));
	};
	
	const handleCreateNote = async () => {
		const id = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)?.hs_object_id;
		const body = {
			dealId: id,
			noteBody: formik.values.body,
			hubspotId: userDetails?.hubspotId,
			objectType: objectType,
		};
		console.log('📋 ~ Create note object', body);
		const response = await createNote(body).unwrap();
		await uploadFilesToStorage(response.id);
		return response;
	};

	const formik = useFormik({
		initialValues: {
			body: '',
			files: [],
			errorMessage: '',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			try {
				const res = await handleCreateNote();
				if (res) {
					console.log('🚀 ~ Successfully created note', res);
					resetForm();
					handleClose();
				} else {
					console.log('🙅 ~ Error creating note');
					setFieldValue(
						'errorMessage',
						'Error creating note. Please try again'
					);
				}
			} catch (err) {
				console.log('🙅 ~ Error creating note', err);
				setFieldValue('errorMessage', 'Error creating note. Please try again');
			}
			setSubmitting(false);
		},
	});

	const generatePDFAndUpload = async (noteBody) => {
		const doc = new jsPDF({
			unit: 'mm',
			format: 'a4',
		});

		const pdfWidth = doc.internal.pageSize.width;
		const lineHeight = 6;
		const lines = noteBody.split('\n');
		let yPosition = 15;
		lines.forEach((line, index) => {
			const textLines = doc.splitTextToSize(line, pdfWidth - 30);
			if (index === 0) {
				doc.text(textLines, 15, yPosition);
			} else {
				yPosition += lineHeight;
				doc.text(textLines, 15, yPosition);
			}
			yPosition += (textLines.length - 1) * lineHeight;
		});

		const fileContent = doc.output('arraybuffer');
		const fileBlob = new Blob([fileContent], { type: 'application/pdf' });
		console.log('about to create PDF');
		const date = new Date();
		const file = {
			name: `created Note - ${formatDate(date, true).replace(':', '.')}.pdf`,
			content: fileBlob,
		};

		if (validSharepoint) {
			try {
				// If client add to client folder in sharepoint
				if (objectType === 'client') {
					await addFileToSharepoint(`${replaceFolderName(client.name)}/${folderStructure.clientNotes}`, file);
				} else {
					await addFileToSharepoint(`${dealRow.dealFolderPath}/Notes`, file);
				}
			} catch (error) {
				console.log('🙅 ~ Error creating file', error);
			}
		}
	};

	const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

	const uploadFilesToStorage = async (noteId) => {
		const type = objectType.charAt(0).toUpperCase() + objectType.slice(1);
		const id = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)?.hs_object_id;
		for (const file of formik.values.files) {
			let filePath;
			if (objectType === 'client' || objectType === 'contact') {
				// Adjust the file path for a client
				filePath = `/${attachmentsClientFolderPath}/${(client ?? contact).hs_object_id}/${file.name}`;
			} else {
				filePath = `/${attachmentsFolderPath}/${deal.hs_object_id}/${file.name}`;
			}
			const storageRef = ref(storage, filePath);
			
			const metadata = {
				customMetadata: {
					[`${type} ID`]: id.toString(),
					'Note ID': noteId.toString(),
				},
			};
			
			await uploadBytes(storageRef, file, metadata);
			//added a little delay to allow for attachments to upload not all at the same time
			await delay(5000); 
			if (objectType != 'contact' && validSharepoint) {
				await addFileToSharepoint(objectType === 'client' 
					? `${replaceFolderName(client.name)}/${folderStructure.clientAttachments}` 
					: dealRow.dealFolderPath, file);
			}
		}
		if (objectType != 'contact') {
			await generatePDFAndUpload(formik.values.body);
		}
		setTimeout(async () => {
			try {
				const uppercaseType = (objectType ?? '').toUpperCase();
				dispatch((objectType == 'deal' ? dealsApi : ticketsApi).util.invalidateTags([
					{ type: `${uppercaseType}S`, id: 'LIST' },
					{ type: `${uppercaseType}S`, id: deal?.hs_pipeline_stage ?? deal?.dealstage },
					{ type: uppercaseType, id },
				]));
				dispatch(dealsApi.util.invalidateTags([
					{ type: 'DEAL_ROW', id: id },
				]));
				dispatch(engagementsApi.util.invalidateTags([
					{ type: 'ENGAGEMENTS', id: id },
					{ type: 'TASKS', id: 'LIST' },
				]));
				dispatch(triggerRefresh());
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 2000);
	};
	
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			PaperProps={{
				sx: {
					minWidth: { xs: '100%', sm: '70%', md: '50%' },
					padding: '1em'
				}
			}}
		>
			<form onSubmit={formik.handleSubmit} >
				<DialogTitle>Create Note</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						{objectType != 'contact' && <Grid size={12}>
							<FileUpload 
								files={formik.values.files} 
								setFiles={(files) => formik.handleChange({
									target: {
										name: 'files',
										value: files,
									},
								})}
								loading={formik.isSubmitting}
							/>
						</Grid>}
						<Grid size={12}>
							<TextField
								fullWidth={true}
								name="body"
								placeholder="Type here ..."
								multiline
								rows={4}
								{...formik.getFieldProps('body')}
								error={formik.touched.body && Boolean(formik.errors.body)}
								helperText={formik.touched.body && formik.errors.body}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <pre>{JSON.stringify(formik.values, undefined, 2)}</pre> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={isLoading || formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
