/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSharepoint } from '@/hooks/useSharepoint';
import { attachmentsClientFolderPath, attachmentsFolderPath, folderStructure, replaceFolderName, specialChars } from '@/utils/constants';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '@/utils/firebase';
import { dealsApi } from '@/features/deals/dealsApi';
import { triggerRefresh, setLoading as setAttachmentsLoading } from '@/features/table/attachmentsTableSlice';
import { Box, CircularProgress, Typography } from '@mui/material';
import { clientsApi } from '@/features/clients/clientsApi';
import { determineContext } from '@/hooks/determineContext';
import { folioBlue } from '@/utils/constants';
import FileUpload from '../FileUpload';
import { engagementsApi } from '@/features/engagements/engagementsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';

const ObjectViewFileUpload = () => {
	const dispatch = useDispatch();
	
	const [uploadLoading, setFileUploadLoading] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const { deal, client, objectType, sharepoint, dealRow } = determineContext();

	const isClient = useMemo(() => objectType == 'client', [objectType]);

	const validSharepoint = (!deal && !client) ? true : sharepoint?.isValid;

	const sharepointSite = (!deal && !client) ? userDetails?.sharepointSite : sharepoint?.sharepointSite;
	const clientSiteSuffix = (!deal && !client) ? userDetails?.clientSiteSuffix : sharepoint?.clientSiteSuffix;
	const clientFolder = (!deal && !client) ? userDetails?.clientFolder : sharepoint?.clientFolder;
	const clientSite = (!deal && !client) ? userDetails?.clientSite : sharepoint?.clientSite;
    
	const { addFileToSharepoint } = useSharepoint(
		sharepointSite,
		clientSiteSuffix,
		clientFolder,
		clientSite
	);

	const triggerLoading = (bool) => {
		dispatch(setAttachmentsLoading(bool));
		dispatch(setAttachmentsLoading(bool));
		setFileUploadLoading(bool);
	};

	const uploadFilesToStorage = async (files, objectType, client, deal) => {
		triggerLoading(true);
		const objectId = isClient ? client.hs_object_id : deal.hs_object_id;
		
		const objectName = isClient ? 
			`${replaceFolderName(client.name)}/${folderStructure.clientAttachments}` 
			: 
			dealRow.dealFolderPath;
	
		const attachmentsPath = isClient ? attachmentsClientFolderPath : attachmentsFolderPath;
		
		for (const file of files) {
			const filePath = `/${attachmentsPath}/${objectId}/${file.name}`;
			const storageRef = ref(storage, filePath);
			const metadata = {
				customMetadata: {
					[`${isClient ? 'Client' : 'Deal'} ID`]: objectId.toString(),
				},
			};
			await uploadBytes(storageRef, file, metadata);
			if (validSharepoint) {
				await addFileToSharepoint(objectName, file);
			}
		}
	
		setTimeout(async () => {
			try {
				const type = (objectType ?? '').toUpperCase();
                
				dispatch(
					isClient ?
						clientsApi.util.invalidateTags([
							{ type: `${type}S`, id: 'LIST' },
							{ type, id: objectId },
							{ type: 'CLIENT_ROW', id: objectId },
						]) :
						dealsApi.util.invalidateTags([
							{ type: `${type}S`, id: 'LIST' },
							{ type, id: objectId },
							{ type: 'DEAL_ROW', id: objectId },
						])
				);
				dispatch(engagementsApi.util.invalidateTags([
					{ type: 'ENGAGEMENTS', id: objectId },
					{ type: 'TASKS', id: 'LIST' },
				]));
				dispatch(triggerRefresh());
			} catch (error) {
				console.error('Error invalidating API:', error);
			} finally {
				triggerLoading(false);
			}
		}, 2000);
		
	};

	return <Box sx={{ paddingBottom: '1em', zIndex: 2 }}>
		{uploadLoading ? (
			<Box 
				width={'100%'} 
				sx={{
					border: `2px dashed ${folioBlue}`,
					borderRadius: '5px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box sx={{ padding: '0.5em'}}>
					<CircularProgress />
				</Box>
			</Box>
		) : (
			Object.keys(client ?? {}).length > 0 && (
				<FileUpload 
					files={[]} 
					setFiles={(files) => {
						uploadFilesToStorage(files, objectType, client, deal);
					}}
					loading={uploadLoading}
				/>
			)
		)}

	</Box>;
};

export default ObjectViewFileUpload;