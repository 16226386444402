/* eslint-disable no-empty */
import React, { useContext, useMemo, useEffect } from 'react';
import { TextField, FormHelperText, FormLabel, Tooltip } from '@mui/material';
import ClientContext from '@/context/clientContext';
import {
	useUpdateClientMutation,
	useUpdateInsightClientMutation,
} from '@/features/clients/clientsApi';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { useUpdateContactMutation } from '@/features/contacts/contactsApi';
import { inputProps, createValidationSchema } from './clientDetailData';
import { IndustrySelect } from './industrySelect';
import { DrawNumeric } from './drawNumeric';
import { ResetButton } from './resetButton';
import { determineContext } from '@/hooks/determineContext';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const ClientDetailField = ({ type, header, title, readOnly }) => {
	const { client, contact } = determineContext();
	const [updateClient, clientState] = useUpdateClientMutation();
	const [updateInsightClient, clientInsightState] = useUpdateInsightClientMutation();
	const [updateContact, contactState] = useUpdateContactMutation();

	// console.log('here is the header: ', header);

	const loading = clientState.isLoading || contactState.isLoading || clientInsightState.isLoading;

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
		isError,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;

	const handleReset = (event) => {
		event.preventDefault();
		event.stopPropagation();
		formik.resetForm();
	};

	const handleBlur = (event) => {
		if (!readOnly) {
			formik.handleSubmit(event);
		}
	};

	const handleUpdateClient = async (value) => {
		const properties = {
			[header]: value,
		};
		console.log(`Updating ${type}`, properties);
		try {
			if (type === 'contact') {
				const updatedKey =
						header === 'phone'
							? 'Telephone'
							: header.charAt(0).toUpperCase() + header.slice(1);

				const properties = {
					[header]: value,
				};

				const contactRes = await updateContact({ contactId: contact.hs_object_id, properties }).unwrap();
				console.log('here is the contact response: ', contactRes);
				// const updatedProperties = {
				// 	[updatedKey]: value,
				// };

				// if (header === 'email' || header === 'phone') {
				// 	await updateInsightClient({
				// 		properties: updatedProperties,
				// 		clientId: client?.id ?? client?.hs_object_id,
				// 		ownerId: hubspotId,
				// 		insightId: client?.insight?.Id,
				// 	});
				// }
			} else {
				await updateClient({ clientId: client.hs_object_id, properties }).unwrap();
				//only update insight if city or address
				if (header === 'city' || header === 'address') {
					//if header is address set it to insight key AddressLine1
					//also set the key value to be a capital at the start to match insight api
					const updatedKey =
						header === 'address'
							? 'AddressLine1'
							: header.charAt(0).toUpperCase() + header.slice(1);
					const properties = {
						[updatedKey]: value,
					};
					await updateInsightClient({
						properties,
						ownerId: hubspotId,
						clientId: client?.id ?? client?.hs_object_id,
						insightId: client?.insight?.Id,
					}).unwrap();
				}
			}
		} catch (error) {
			console.log(`Error updating ${type}`, error);
		}
	};

	const validationSchema = useMemo(() => createValidationSchema([header]), [header]);

	const formik = useFormik({
		initialValues: {
			[header]: type === 'contact' ? contact[header] : client[header],
		},
		validationSchema: validationSchema,
		validateOnBlur: true,
		validateOnChange: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			let value = formik.values[header];
			if (typeof value === 'string') {
				value = value.trim().length === 0 ? null : value.trim();
			} else if (value === 0) {
				value = null;
			}
			const initialValue = type === 'contact' ? contact[header] : client[header];
			if (
				formik.touched[header] &&
				!formik.errors[header] &&
				value &&
				value !== initialValue
			) {
				await handleUpdateClient(value);
			}
			setSubmitting(false);
		},
	});

	useEffect(() => {
		formik.setValues({
			[header]: type === 'contact' ? contact?.[header] : client?.[header],
		});
	}, [client, contact, header, type]);

	const setFloatValue = ({ floatValue }) => {
		formik.setFieldValue(header, floatValue);
	};

	const drawComponent = () => {
		switch (header) {
		case 'industry':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<IndustrySelect
						initialValue={client.industry}
						disabled={loading || formik.isSubmitting}
						onChange={handleUpdateClient}
					/>
				</>
			);

		case 'numberofemployees':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<DrawNumeric
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						isLoading={formik.isSubmitting || loading}
						clickHandler={handleReset}
						onBlur={handleBlur}
						onValueChange={setFloatValue}
						value={formik.values[header] ?? 0}
					/>
				</>
			);
		case 'annualrevenue':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<DrawNumeric
						prefix='$'
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						isLoading={formik.isSubmitting || loading}
						clickHandler={handleReset}
						onBlur={handleBlur}
						onValueChange={setFloatValue}
						value={formik.values[header] ?? 0}
					/>
				</>
			);
		case 'phone':
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<NumericFormat
						customInput={TextField}
						fullWidth
						decimalScale={0}
						fixedDecimalScale
						allowNegative={false}
						allowLeadingZeros
						onValueChange={({ value }) => formik.setFieldValue(header, value)}
						{...formik.getFieldProps(header)}
						required
						disabled={loading}
						name='phone'
						error={formik.touched[header] && Boolean(formik.errors[header])}
						helperText={formik.touched[header] && formik.errors[header]}
						onBlur={handleBlur}
						// sx={inputProps}
						InputProps={{
							endAdornment: (
								<ResetButton
									isLoading={loading || formik.isSubmitting}
									onMouseDown={handleReset}
								/>
							),
						}}
					/>
				</>
			);
		default:
			return (
				<>
					<FormLabel>{title}</FormLabel>
					<Tooltip
						title={['email', 'name'].includes(header) ? 'Currently uneditable' : ''}
						placement='top'
					>
						<TextField
							{...formik.getFieldProps(header)}
							fullWidth
							InputProps={{
								...(readOnly && { readOnly: true }),
								...(!readOnly && {
									endAdornment: (
										<ResetButton
											isLoading={loading || formik.isSubmitting}
											onMouseDown={handleReset}
										/>
									),
								}),
							}}
							value={formik.values[header] ?? ''}
							error={formik.touched[header] && Boolean(formik.errors[header])}
							helperText={formik.touched[header] && formik.errors[header]}
							onBlur={handleBlur}
							disabled={['name', 'email'].includes(header)}
						/>
					</Tooltip>
				</>
			);
		}
	};

	return <>{drawComponent()}</>;
};
