import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import { determineContext } from '@/hooks/determineContext';
import { closedPipelineStages } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const EditPolicyTransactionButton = () => {
	const dispatch = useDispatch();
	const { dealstageId} = determineContext();
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'addPolicyTransactions'});
	const showButton = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
    
	const handleOpenDialog = () => {
		dispatch(openDialog('editPolicyTransaction'));
	};
    
	return <div style={{display: 'flex', justifyContent: 'right', paddingBottom: '1em' }}>
		{(closedPipelineStages.includes(dealstageId) && showButton) && (
			<Button variant="tab_header" startIcon={<Add />}
				onClick={handleOpenDialog}>
				{'Add policy transaction'}
			</Button>
		)}
	</div>;
};