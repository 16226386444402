import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Tooltip, Skeleton } from '@mui/material';
import { useUpdateComplianceTaskMutation } from '@/features/engagements/engagementsApi';
import { determineContext } from '@/hooks/determineContext';

export const ComplianceTaskCheckbox = ({ task }) => {
	const [completed, setIsCompleted] = useState(task?.completed);

	const [updateCompliance] = useUpdateComplianceTaskMutation();
	const { deal, loading } = determineContext();

	const handleChange = async (event) => {
		const isChecked = event.target.checked;
		setIsCompleted(isChecked);
		await updateCompliance({
			dealId: deal?.hs_object_id,
			isCompleted: isChecked,
			docId: task.documentId,
		});
	};

	return (
		loading?.deal ? (
			<Skeleton animation="wave" width="55%" height="2em" />
		) : (
			<Tooltip title={task?.questionData?.description}>
				<FormControlLabel
					control={
						<Checkbox
							checked={completed}
							onChange={handleChange}
							size="small"
						/>
					}
					label={task?.questionData?.name}
				/>
			</Tooltip>
			
		)
	);
};
