import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Skeleton, Select, MenuItem, Stack, Button } from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import { theme } from '@/app/theme';
import {
	useGetClientPoliciesQuery,
	useUpdateClientRowMutation,
	useUpdateInsightClientMutation,
} from '@/features/clients/clientsApi';
import { useUpdateDealRowMutation } from '@/features/deals/dealsApi';

const TYPE_VALUES = [
	{ value: 0, label: 'Client' },
	{ value: 1, label: 'Prospect' },
];

export const ClientTypeSelect = ({ title, minimal = true }) => {
	const { client, deals, claims, loading, isProspect } = determineContext();
	const insightId = client?.clientRow?.insightId;
	const hubspotId = client?.clientRow?.hubspotId;

	const { data: policies, isLoading: isLoadingPolicies } = useGetClientPoliciesQuery(
		{ insightId, hubspotId },
		{ skip: !insightId && !hubspotId }
	);

	const loadingClient = loading?.client || isLoadingPolicies;

	const ownerId = client?.hubspot_owner_id;

	const [updateInsightClient, { isLoading }] = useUpdateInsightClientMutation();
	const [updateClientRow, { isLoading: isUpdatingClient }] = useUpdateClientRowMutation();
	const [updateDealRow] = useUpdateDealRowMutation();

	const selectedValue = useMemo(() => (isProspect ? 1 : 0), [isProspect]);

	const updateClientType = async (event) => {
		const value = event.target.value;

		const clientType = value === TYPE_VALUES[1].value;
		await updateInsightClient({
			properties: {
				ClientTypeId: value,
			},
			ownerId,
			clientId: client?.id ?? client?.hs_object_id,
			insightId: client?.insight?.Id,
		}).unwrap();

		await updateClientRow({
			clientId: client?.clientRow?.hubspotId,
			docId: client?.clientRow?.docId,
			properties: { isProspect: clientType },
		}).unwrap();

		// await Promise.all(
		// 	deals.map(async (deal) => {
		// 		await updateDealRow({
		// 			dealId: deal?.id ?? deal?.properties?.hs_object_id,
		// 			properties: {
		// 				isProspect: clientType,
		// 			},
		// 		})
		// 			.unwrap()
		// 			.catch((e) =>
		// 				console.log(
		// 					`Error updating deal row for deal ID ${
		// 						deal?.id ?? deal?.properties?.hs_object_id
		// 					}`,
		// 					e
		// 				)
		// 			);
		// 	})
		// );
	};

	const minimalInputTheme = theme.components.MuiInputBase.styleOverrides.minimal;
	const minimalLabelTheme = theme.components.MuiFormLabel.styleOverrides.minimal;

	const isDisabled = useMemo(
		() =>
			(policies ?? []).length > 0 ||
			claims.length > 0 ||
			deals.some(
				(d) => d.properties?.pipeline !== process.env.REACT_APP_PIPELINE_NEW_BUSINESS
			) ||
			deals.length > 1,
		[deals, claims, policies]
	);

	return (
		<Stack width={'100%'}>
			{title && (
				<Typography sx={minimalLabelTheme}>{title}</Typography>
			)}
			{loadingClient || isUpdatingClient || isLoading ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : (
				<Select
					fullWidth
					value={selectedValue}
					onChange={updateClientType}
					disabled={isLoading || isDisabled || isUpdatingClient}
					variant={minimal ? 'standard' : 'outlined'}
					sx={{
						...(minimal && {
							...minimalInputTheme,
							'& .Mui-disabled': {
								backgroundColor: 'transparent',
							},
						}),
					}}
				>
					{TYPE_VALUES.map(({ value, label }, index) => (
						<MenuItem key={index} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
			)}
		</Stack>
	);
};
