import React, { useMemo } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@/app/routes';
import { determineContext } from '@/hooks/determineContext';

export const StatementOfAdviceButton = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { dealRow } = determineContext();

	const disabled = useMemo(() => {
		const needs = (dealRow?.needsAnalysis ?? []).map((r) => r?.key);
		const risks = dealRow?.riskClasses ?? [];
		return needs.length === 0 || risks.some((r) => !needs.includes(r));
	}, [dealRow?.needsAnalysis, dealRow?.riskClasses]);

	return (
		<Tooltip title={disabled ? 'Please update needs analysis in Scope of Advice tab' : ''} placement='bottom'>
			<div style={{width: '100%'}}>
				<Button
					variant='tab_header'
					startIcon={<SendIcon />}
					disabled={disabled}
					sx={{width: '100%'}}
					onClick={() => navigate(`${location.pathname}${paths.generateSOA}`)}
				>
					{'Generate Statement of Advice'}
				</Button>
			</div>
		</Tooltip>
	);
};
